import React, { FC } from 'react';
import { ToastContainerProps } from 'react-toastify';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-toast.svg';
import './styles.scss';

// add more toast types here and style in the module
export type ToastType = 'success' | 'error' | 'warn';

export const DEFAULT_TOAST_OPTIONS: ToastContainerProps = {
  autoClose: 5000,
  closeButton: false,
  icon: false,
  hideProgressBar: true,
  toastClassName: 'toastify__wrapper',
  bodyClassName: 'toastify__body'
};

export type ToastMessageProps = {
  type: ToastType;
  message?: string | JSX.Element;
  onClose?: () => void;
  isNetworkError?: boolean;
};

const ToastMessage: FC<ToastMessageProps> = ({ onClose, message, type, isNetworkError }) => {
  const bgColor = `toastify__${type}`;
  return (
    <div className={`toastify__content ${bgColor}`}>
      <span className="toastify__alert">{type === 'success' ? 'ALERT 🎉' : 'OOPS 😓'}</span>
      <span className="toastify__text">
        {message}
      </span>
      <button onClick={onClose} className="toastify__close">
        <CloseIcon />
      </button>
    </div>
  );
};

export { ToastMessage };

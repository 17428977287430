import React, { useEffect, useState } from 'react';
import ActiveTag from '../../active-tag';
import CreateScreenModal, { FormDataType } from './create-screen-modal';
import { ScreenType } from '.';
import moment from 'moment';
import { STORAGE_KEYS } from '../../../helpers/constants';
import homeStore from '../../../stores/homeStore';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

const ScreenCard = ({
  data,
  onEditSuccess,
  onDeleteSuccess
}: {
  data: ScreenType;
  onEditSuccess?: (arg0: FormDataType) => void;
  onDeleteSuccess?: (arg0: string) => void;
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const formattedDate = moment(data.created_at).format('MMM D, YYYY, h:mma');
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
  const [activeScreen, setActiveScreen] = useState(false);
  const { isBrandSplitScreen } = homeStore;

  useEffect(() => {
    setActiveScreen(
      isBrandSplitScreen &&
        JSON.parse(localStorage.getItem(STORAGE_KEYS.SCREEN_DETAILS) ?? '')?.id === data.id
    );
  }, []);

  return (
    <div>
      <div
        onClick={() => {
          localStorage.setItem(STORAGE_KEYS.SCREEN_DETAILS, JSON.stringify(data));
          localStorage.setItem(STORAGE_KEYS.IS_BRAND_SPLIT_SCREEN, JSON.stringify(true));
          localStorage.setItem(STORAGE_KEYS.IS_BRAND_PACKAGING_SCREEN, JSON.stringify(false));
          window.location.reload();
        }}
        className={`border flex flex-col gap-3 p-6 rounded-[8px] ${
          activeScreen ? ' border-red-3' : 'border-[#EDEDEE]'
        }`}>
        <ActiveTag active={data.active} />
        <div className="">
          <p className="font-medium mb-1">Screen {data.name} </p>
          <p className="text-[#858585]">Created {formattedDate}</p>
        </div>

        <div className="flex gap-1">
          {data.brands.map((item, index) => (
            <img
              key={index}
              src={item.logo}
              alt="logo"
              className="w-8 h-8 object-cover rounded-full "
            />
          ))}
        </div>
        {(role as string).includes('ADMIN') && (
          <p
            onClick={(e) => {
              e.stopPropagation();
              setShowEditModal(true);
            }}
            className="text-[#E60A2B] cursor-pointer  underline font-medium">
            Edit Screen
          </p>
        )}
      </div>
      <CreateScreenModal
        data={data}
        type="edit"
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        onEditSuccess={onEditSuccess}
        onDeleteSuccess={onDeleteSuccess}
      />
    </div>
  );
};

export default ScreenCard;

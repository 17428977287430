import React from 'react';
import ReactToPrint from 'react-to-print';

import { STORAGE_KEYS, splitScreenColors } from '../../helpers/constants';
import { ENUM_STATUS } from '../../helpers/enums';
import { DocketIcon } from '../../assets/icons';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

// TODO :: Refactor card button to render different buttons instead of action manipulation
const DocketButton = ({ status, receiptRef }) => {
  const staffNumber = localStorage.getItem(STORAGE_KEYS.STAFF_NUMBER);

  const firstName = localStorage.getItem(STORAGE_KEYS.FIRST_NAME);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const isPendingColor = () => {
    const adminPackaging = role.includes('ADMIN') && status === ENUM_STATUS.PACKAGING;
    const started = status === ENUM_STATUS.STARTED;
    const isPackaging = status === ENUM_STATUS.PACKAGING && firstName === 'Packaging';

    return adminPackaging || started || isPackaging;
  };
  return (
    <div>
      <ReactToPrint
        trigger={() => (
          <button
            className={`flex justify-center w-40 mt-2 py-[8px] rounded-full bg-white d-justify-center d-align-center border-transparent text-sm ${
              status === 'ready'
                ? 'text-[#D50000]'
                : status === 'started'
                ? 'text-[#D58300]'
                : status === 'is_packaging' && (role === 'SUPER_ADMIN' || role === 'KITCHEN_ADMIN')
                ? 'text-[#D58300]'
                : status === 'is_packaging' && firstName !== 'Packaging'
                ? 'text-style'
                : status === 'is_packaging' && firstName === 'Packaging'
                ? 'text-[#D58300]'
                : status === 'completed' || status === 'dispatch' || status === 'search'
                ? 'text-[#b9b9b9]'
                : status === 'rejected'
                ? 'text-white'
                : 'text-[#46AE31]'
            }`}
            style={{
              color:
                role === ENUM_KITCHEN_ROLE.PACKAGING &&
                status === ENUM_STATUS.PACKAGING &&
                staffNumber &&
                splitScreenColors[staffNumber - 1]
            }}>
            <DocketIcon
              color={
                status === ENUM_STATUS.READY
                  ? 'text-[#D50000]'
                  : isPendingColor()
                  ? 'text-[#D58300]'
                  : status === ENUM_STATUS.PACKAGING && firstName !== 'Packaging'
                  ? 'text-style'
                  : status === ENUM_STATUS.COMPLETED ||
                    status === ENUM_STATUS.DISPATCH ||
                    status === ENUM_STATUS.SEARCH
                  ? 'text-[#707160]'
                  : status === ENUM_STATUS.REJECTED
                  ? 'text-white'
                  : 'text-[#46AE31]'
              }
            />

            <h6
              className={`mb-0  ${
                status === ENUM_STATUS.READY
                  ? 'text-[#D50000]'
                  : isPendingColor()
                  ? 'text-[#D58300]'
                  : status === ENUM_STATUS.PACKAGING && firstName !== 'Packaging'
                  ? 'text-style'
                  : status === ENUM_STATUS.COMPLETED ||
                    status === ENUM_STATUS.DISPATCH ||
                    status === ENUM_STATUS.SEARCH
                  ? 'text-[#707160]'
                  : status === ENUM_STATUS.REJECTED
                  ? 'text-white'
                  : 'text-[#46AE31]'
              } ml-1`}
              style={{
                color:
                  role === ENUM_KITCHEN_ROLE.PACKAGING &&
                  status === ENUM_STATUS.PACKAGING &&
                  staffNumber &&
                  splitScreenColors[staffNumber - 1]
              }}>
              Print docket
            </h6>
          </button>
        )}
        content={() => receiptRef.current}
      />
    </div>
  );
};

export default DocketButton;

import React, { FC } from 'react';
import Brand from './brand';
import CardAccordion from '../orderCard/cardAccordion';
import { OrderType } from '../../helpers/types';
import { ENUM_STATUS } from '../../helpers/enums';

type SubOrdersType = {
  status: ENUM_STATUS;
  subOrders: OrderType[];
  selectedMeals: string[];
  selectedAddons: string[];
  activeBrands: string[];
  onAddonSelect: (_: string) => void;
  onBulkAddonSelect: (_: string[], checkAll: boolean) => void;
  onMealSelect: (_: string) => void;
  printStickerMeals: any[];
  setPrintStickerMeals: (arg: string) => void;
  mainOrder: OrderType;
};

const SubOrders: FC<SubOrdersType> = ({
  status,
  subOrders,
  selectedMeals,
  onMealSelect,
  selectedAddons,
  onAddonSelect,
  onBulkAddonSelect,
  activeBrands,
  printStickerMeals,
  setPrintStickerMeals,
  mainOrder
}) => {
  return (
    <>
      {subOrders?.map((itm, i) => (
        <CardAccordion
          item={itm}
          key={i}
          index={i}
          accClass
          isReceipt={false}
          isLastItem={i === subOrders.length - 1}>
          {itm?.meals?.map((itms, index) => (
            <Brand
              onAddonSelect={onAddonSelect}
              onBulkAddonSelect={onBulkAddonSelect}
              onMealSelect={onMealSelect}
              selectedMeals={selectedMeals}
              selectedAddons={selectedAddons}
              status={status}
              order={itms}
              key={index}
              isLastBrand={index === itm.meals.length - 1}
              subIndex={index}
              isSubOrder
              isGroupOrder
              brandIsReady={activeBrands.includes(itms.brand.id)}
              mainOrder={mainOrder}
              printStickerMeals={printStickerMeals}
              setPrintStickerMeals={setPrintStickerMeals}
            />
          ))}
        </CardAccordion>
      ))}
    </>
  );
};

export default SubOrders;

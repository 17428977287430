import React from 'react';
import { ReactComponent as NoOrderIcon } from './icons/no-order.svg';

const NoOrder = () => {
  return (
    <div className="flex grow flex-col items-center justify-center h-full gap-3 py-[10vh] w-[90%] mx-auto text-center">
      <NoOrderIcon className=" w-[100%] md:w-[500px] lg:w-auto" />
      <div className="mt-[-3rem]">
        <h5 className=" text-gray-2 text-2xl font-semibold">Waiting for Order</h5>
        <p className="text-gray-5 w-full md:w-[450px]">
          Hang around, take a pause, get that last gulp of water, it gets busy when the orders start
          rolling in.
        </p>
      </div>
    </div>
  );
};

export default NoOrder;

import React, { useEffect, useRef } from 'react';
import Header from '../header';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ENUM_LAYOUT_STATE } from '../../helpers/enums';
import LayoutState from './layoutState';
import BreadCrumbs from './breadcrumbs';
import { STORAGE_KEYS } from '../../helpers/constants';
import './style.scss';
import useLayoutContext from '../../contexts/layout.context';
import homeStore from '../../stores/homeStore';
import { ReactComponent as ResetIcon } from '../../assets/icons/reset.svg';
import { useReactToPrint } from 'react-to-print';
import OrderReceipt from '../order-receipt';

const Layout = observer(function Layout({
  children,
  orders,
  pendingOrders,
  trackingOrders,
  searchPlaceholder,
  searchValue,
  searchChange,
  layoutState,
  filters,
  onFilterClick
}) {
  const screenDetails = JSON.parse(localStorage.getItem(STORAGE_KEYS.SCREEN_DETAILS));
  const { testMode, receiptToPrint, setReceiptToPrint } = useLayoutContext();
  const packagingScreenDetails = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.PACKAGING_SCREEN_DETAILS)
  );
  const { isBrandSplitScreen, isBrandPackagingScreen } = homeStore;

  const handleReset = () => {
    localStorage.setItem(STORAGE_KEYS.IS_BRAND_SPLIT_SCREEN, 'false');
    localStorage.setItem(STORAGE_KEYS.IS_BRAND_PACKAGING_SCREEN, 'false');
    localStorage.removeItem(STORAGE_KEYS.SCREEN_DETAILS);
    localStorage.removeItem(STORAGE_KEYS.PACKAGING_SCREEN_DETAILS);
    window.location.reload();
  };

  useEffect(() => {
    if (receiptToPrint.shouldPrint) {
      handlePrint();
    }
  }, [receiptToPrint.shouldPrint]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setReceiptToPrint({ order: null, shouldPrint: false })
  });

  return (
    <main className={`flex flex-col min-h-screen ${testMode ? 'border-4 border-red-5' : ''}`}>
      {receiptToPrint.shouldPrint && <OrderReceipt ref={componentRef} {...receiptToPrint.order} />}
      {testMode && (
        <span className="inline-block p-2 text-sm text-white bg-red-5 fixed top-0 left-1/2 rounded-b-xl">
          TEST MODE ACTIVE
        </span>
      )}
      <Header
        orders={orders}
        pendingOrders={pendingOrders}
        trackingOrders={trackingOrders}
        searchPlaceholder={searchPlaceholder}
        searchValue={searchValue}
        searchChange={searchChange}
        onFilterClick={onFilterClick}
      />
      {isBrandSplitScreen && screenDetails?.brands?.length > 0 ? (
        <div className="flex px-[20px] my-4 items-center">
          <div className="  text-[16px]">
            <span className="text-white">Screen {screenDetails?.name}</span>
            <span className=" text-[#A3A3A3] mr-2"> showing:</span>
          </div>

          {screenDetails?.brands?.map((item) => (
            <img
              key={item.id}
              src={item.logo}
              className=" cursor-pointer ml-[2px] w-[30px] h-[30px] rounded-[20px]"
            />
          ))}

          <div className="inline-block relative info_dropdown">
            <div className="border border-white rounded-full w-4 h-4 text-white flex text-xs ml-3 items-center justify-center p-1 cursor-pointer">
              i
            </div>
            <div className="text-[#6F7376] text-sm p-2 bg-white shadow-sm z-10 hidden absolute w-[250px] rounded-md mt-1 info_content">
              Brands on the spilt screen can only be edited by the supervisor.
            </div>
          </div>
          <button
            className="ms-auto flex items-center gap-2 border border-[#DEDEDE] rounded-[10px] py-1 px-4"
            onClick={handleReset}>
            <ResetIcon />
            <span className="text-[#A3A3A3] font-medium font-manrope">Reset</span>
          </button>
        </div>
      ) : isBrandPackagingScreen && packagingScreenDetails ? (
        <div className="flex items-center justify-between px-4">
          <BreadCrumbs filters={filters} onFilterClick={onFilterClick} searchValue={searchValue} />
          <div className="text-[16px]">
            <span className="text-white">{packagingScreenDetails?.name}</span>
            <span className=" text-[#A3A3A3] mr-2"> showing</span>
          </div>
          <button
            className="flex items-center gap-2 border border-[#DEDEDE] rounded-[10px] py-1 px-4"
            onClick={handleReset}>
            <ResetIcon />
            <span className="text-[#A3A3A3] font-medium font-manrope">Reset</span>
          </button>
        </div>
      ) : (
        <BreadCrumbs filters={filters} onFilterClick={onFilterClick} searchValue={searchValue} />
      )}
      {layoutState !== ENUM_LAYOUT_STATE.PAGE ? (
        <LayoutState state={layoutState} goBack={() => searchChange({ target: { value: '' } })} />
      ) : (
        <div className="bg-gray-7 grow flex overflow-hidden pb-[20px]">
          <div className="overflow-x-scroll items-start flex px-2 w-full">{children}</div>
        </div>
      )}
    </main>
  );
});
export default withRouter(Layout);

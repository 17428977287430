import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_STATUS } from '../../helpers/enums';

const role = localStorage.getItem(STORAGE_KEYS.ROLE) ?? '';
const firstName = localStorage.getItem(STORAGE_KEYS.FIRST_NAME);

type TextClassType = {
  status: ENUM_STATUS;
  packagingComplete: boolean;
  actionDisabled: boolean;
};
export const getTextClass = ({
  status,
  packagingComplete,
  actionDisabled
}: TextClassType): string => {
  const disabled = isButtonDisabled({ actionDisabled, packagingComplete, status });
  const textStyle =
    status === ENUM_STATUS.READY
      ? 'text-[#D50000]'
      : status === ENUM_STATUS.STARTED
      ? 'text-[#D58300]'
      : status === ENUM_STATUS.PACKAGING && role.includes('ADMIN')
      ? 'text-[#D58300]'
      : status === ENUM_STATUS.PACKAGING && firstName !== 'Packaging'
      ? 'bg-style'
      : status === ENUM_STATUS.PACKAGING && firstName === 'Packaging'
      ? 'text-[#D58300]'
      : (status === ENUM_STATUS.COMPLETED ||
          status === ENUM_STATUS.DISPATCH ||
          status === ENUM_STATUS.SEARCH) &&
        !disabled
      ? 'text-[#4f4f4f]'
      : (status === ENUM_STATUS.COMPLETED ||
          status === ENUM_STATUS.DISPATCH ||
          status === ENUM_STATUS.SEARCH) &&
        disabled
      ? 'text-[#a1a1a1]'
      : status === ENUM_STATUS.REJECTED
      ? 'text-white'
      : 'text-[#46AE31]';

  const cursorStyle =
    disabled || (status === ENUM_STATUS.PACKAGING && !packagingComplete)
      ? 'not-allowed-el'
      : 'pointer';

  return `uppercase mb-0 font-medium text-[16px] ${textStyle} ${cursorStyle}`;
};

type ButtonTextType = {
  status: ENUM_STATUS;
  actionDisabled: boolean;
  packagingComplete: boolean;
  paid: boolean;
  isPickup: boolean;
};
export const getButtonText = ({
  status,
  actionDisabled,
  packagingComplete,
  paid,
  isPickup
}: ButtonTextType): string => {
  return actionDisabled
    ? ''
    : (status === ENUM_STATUS.PACKAGING && packagingComplete) ||
      (status === ENUM_STATUS.STARTED && role === ENUM_KITCHEN_ROLE.SHOP_STAFF && packagingComplete)
    ? 'Finish'
    : status === ENUM_STATUS.PACKAGING && !packagingComplete
    ? 'In progress'
    : status === ENUM_STATUS.READY
    ? 'recall'
    : role === ENUM_KITCHEN_ROLE.FRONT_DESK && !paid
    ? 'CONFIRM ORDER'
    : status === ENUM_STATUS.DISPATCH && isPickup
    ? 'Complete Order'
    : status === ENUM_STATUS.DISPATCH && !isPickup
    ? 'dispatch'
    : status === ENUM_STATUS.PENDING && role.includes('ADMIN')
    ? paid
      ? 'START'
      : 'Confirm Order'
    : status === ENUM_STATUS.PENDING &&
      role !== ENUM_KITCHEN_ROLE.FRONT_DESK &&
      role !== ENUM_KITCHEN_ROLE.PACKAGING
    ? 'start'
    : 'Print reciept';
};

type IsButtonDisabledType = {
  status: ENUM_STATUS;
  packagingComplete: boolean;
  actionDisabled: boolean;
};
export const isButtonDisabled = ({
  packagingComplete,
  status,
  actionDisabled
}: IsButtonDisabledType): boolean => {
  return [ENUM_STATUS.STARTED, ENUM_STATUS.PENDING, ENUM_STATUS.SEARCH].includes(status)
    ? false
    : (status === ENUM_STATUS.PACKAGING && !packagingComplete) || actionDisabled;
};

import React, { FC } from 'react';
import { LayoutProvider } from '../../contexts/layout.context';
import Preorder from '../../pages/preorder';

const PreOrdersLayout: FC = () => (
  <LayoutProvider>
    <Preorder />
  </LayoutProvider>
);

export default PreOrdersLayout;

import { ENUM_ITEM_TYPES, ENUM_LAYOUT_STATE } from '../../helpers/enums';
import { filterMealsByItemType } from '../../helpers/functions';
import { FilterType } from './filters';
import { BaseCalculatedOrderType, BrandMealType, OrderType } from '../../helpers/types';
import { FILTER_KEYS } from '../../helpers/constants';

type reduceReturnType = OrderType & {
  orderKey: string;
};

export const getLiveLayoutState = (
  orders: OrderType[],
  search: string,
  loading: boolean,
  summaryCount: { tracking: number; pending: number }
): ENUM_LAYOUT_STATE | undefined => {
  if (orders.length === 0 && search !== '') return ENUM_LAYOUT_STATE.EMPTY_SEARCH;
  if (
    (orders?.length === 0 || (summaryCount?.tracking === 0 && summaryCount?.pending === 0)) &&
    !loading
  ) {
    return ENUM_LAYOUT_STATE.WAITING;
  }
  if (orders.length === 0 && loading) return ENUM_LAYOUT_STATE.LOADING;
  return ENUM_LAYOUT_STATE.PAGE;
};

const filterMainMeals = (meals: BrandMealType[], activeHeaderBrands: string[]): BrandMealType[] => {
  return activeHeaderBrands[0] === 'ALL'
    ? meals
    : meals.filter(
        ({ brand }) =>
          activeHeaderBrands.includes(brand.id) && brand.item_type === ENUM_ITEM_TYPES.FOOD
      );
};

const filterSubOrders = (
  subOrders: BaseCalculatedOrderType[],
  activeHeaderBrands: string[]
): BaseCalculatedOrderType[] => {
  return subOrders.reduce((acc: BaseCalculatedOrderType[], { meals, ...others }) => {
    const foodMeals = filterMealsByItemType(meals, ENUM_ITEM_TYPES.FOOD);
    const filtered = filterMainMeals(meals, activeHeaderBrands);
    acc.push({ ...others, meals: filtered, originalMeals: foodMeals });
    // eslint-disable-next-line
    return acc;
  }, []);
};

export const getFilteredBrandOrders = (
  orders: OrderType[],
  activeHeaderBrands: string[]
): reduceReturnType[] => {
  return orders.reduce((acc: reduceReturnType[], { calculated_order, ...others }) => {
    const {
      meals: mealBrands,
      sub_calculated_order_ids: subOrders,
      ...otherCalculatedOrder
    } = calculated_order;
    const foodItems = filterMealsByItemType(mealBrands, ENUM_ITEM_TYPES.FOOD);
    const filteredMeals = filterMainMeals(mealBrands, activeHeaderBrands);
    const filteredSubOrders = filterSubOrders(subOrders, activeHeaderBrands);
    if (filteredMeals.length > 0 || filteredSubOrders.length > 0) {
      acc.push({
        ...others,
        orderKey: `${activeHeaderBrands.join('')}${others.id}${others.updated_at}`,
        calculated_order: {
          ...otherCalculatedOrder,
          sub_calculated_order_ids: filteredSubOrders,
          meals: filteredMeals,
          originalMeals: foodItems
        }
      });
    }
    return acc;
  }, []);
};

export const getPrefilteredFilters = (
  filters: FilterType[],
  orders: OrderType[],
  pinnedOrders?: OrderType[]
): FilterType[] => {
  return filters.map((filter) => {
    const count = orders.filter((order) => {
      if (filter.key === FILTER_KEYS.PINNED && pinnedOrders !== undefined) {
        return pinnedOrders.some((p) => p.id === order.id);
      }
      return filter.condition?.(order, filter.key);
    }).length;
    return { ...filter, number: count };
  });
};

export const filterSearchValue = (orders: OrderType[], search: string): OrderType[] => {
  return orders.filter((order) => {
    const searchFilter: boolean =
      order?.order_code?.toUpperCase().includes(search) ||
      (order?.calculated_order?.user?.first_name + ' ' + order?.calculated_order?.user?.last_name)
        ?.toUpperCase()
        .includes(search) ||
      order?.calculated_order?.user?.phone_number?.toUpperCase().includes(search) ||
      order?.calculated_order?.user?.email?.toUpperCase().includes(search);

    const groupOrderFilter: boolean = order.calculated_order?.group_sub_order;
    return searchFilter && !groupOrderFilter;
  });
};

import { createContext, useContext } from 'react';

type NetworkContextType = {
  networkSpeed: number;
  setNetworkSpeed: (val: number) => void;
  disableNetworkActions: boolean;
  networkSpeedInMbps: number;
};
export const NetworkContext = createContext<NetworkContextType>({
  networkSpeed: 0,
  setNetworkSpeed: () => {},
  disableNetworkActions: false,
  networkSpeedInMbps: 0
});
const useNetworkContext = (): NetworkContextType => {
  const context = useContext(NetworkContext);
  return context;
};

export default useNetworkContext;

import React from 'react';
import styles from './styles.module.css';

type Props = {
  active: boolean;
  title: string;
  number?: number;
  onButtonClick: () => void;
};

const FilterListItem: React.FC<Props> = ({ active, title, number, onButtonClick }: Props) => {
  return (
    <button className={`${styles.main} ${active ? styles.active : ''}`} onClick={onButtonClick}>
      <div className={`${styles.text} capitalize`}>{title}</div>
      {number !== undefined && <div className={`${styles.number}`}>{number}</div>}
    </button>
  );
};

export default FilterListItem;

import firebase from 'firebase';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASECONFIG_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASECONFIG_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASECONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASECONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASECONFIG_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASECONFIG_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASECONFIG_MEASUREMENT_ID
};

// eslint-disable-next-line
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;

import React, { ReactNode } from 'react';
import { ENUM_ORDER_PROVIDER } from './enums';
import ChowdeckLogo from '../assets/images/chowdeck.png';
import { ReactComponent as GlovoLogo } from '../assets/icons/glovo-icon.svg';
import { OrderType } from './types';

const OrderTypeConfig: Record<ENUM_ORDER_PROVIDER, ReactNode> = {
  [ENUM_ORDER_PROVIDER.GLOVO]: (
    <div className="w-full">
      <GlovoLogo className="object-cover w-[64px] h-8" />
    </div>
  ),
  [ENUM_ORDER_PROVIDER.CHOWDECK]: (
    <img src={ChowdeckLogo} alt="glovo-logo" className="object-cover mb-1 w-fit" />
  ),
  [ENUM_ORDER_PROVIDER.FOODCOURT]: null
};

export const getOrderDeliveryType = (order: OrderType): ReactNode => {
  return OrderTypeConfig[order.delivery];
};

import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import OrderCard from '../../components/orderCard';
import { withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { STORAGE_KEYS } from '../../helpers/constants';
import { checkOrderValidityForRole } from './roleMapping.service';
import { ENUM_LAYOUT_STATE, ENUM_PAGES, ENUM_STATUS } from '../../helpers/enums';
import useLayoutContext from '../../contexts/layout.context';

const Recall = ({ alert }) => {
  const {
    setSocketConfig,
    orders,
    filters,
    activeFilter,
    onFilterClick,
    prefilteredOrders: filteredOrders
  } = useLayoutContext();

  const [orderSummary, setOrderSummary] = useState({
    pendingOrdersCount: 0,
    trackingOrdersCount: 0
  });
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const cokitchenId = localStorage.getItem(STORAGE_KEYS.COKITCHEN);

  const initPage = async () => {
    setSocketConfig({ page: ENUM_PAGES.RECALLED, roomName: getRoomName() });
    setLoading(false);
    return () => {
      setSocketConfig({ roomName: '', page: null });
    };
  };

  const getRoomName = () => {
    if (cokitchenId) {
      return `order-update-cokitchen:${cokitchenId}`;
    }
    return 'order-update';
  };

  useEffect(async () => {
    setLoading(true);
    setOrderSummary({
      pendingOrdersCount: 0,
      trackingOrdersCount: 0
    });

    initPage();
    return () => {
      setSocketConfig({ roomName: '', page: null });
    };
  }, []);

  useEffect(() => {
    setOrderSummary({
      pendingOrdersCount: orders.filter((order) => !order.kitchen_accepted).length,
      trackingOrdersCount: orders.filter((order) => order.kitchen_accepted).length
    });
  }, [orders]);

  const getLayoutState = () => {
    if (filteredOrders.length === 0 && searchValue) return ENUM_LAYOUT_STATE.EMPTY_SEARCH;
    if (
      ((orderSummary?.trackingOrdersCount === 0 && orderSummary?.pendingOrdersCount === 0) ||
        filteredOrders?.length === 0) &&
      !loading
    ) {
      return ENUM_LAYOUT_STATE.WAITING;
    }
    if (orders.length === 0 && loading) return ENUM_LAYOUT_STATE.LOADING;
    return ENUM_LAYOUT_STATE.PAGE;
  };

  return (
    <Layout
      pendingOrders={orderSummary.pendingOrdersCount}
      trackingOrders={orderSummary.trackingOrdersCount}
      searchValue={searchValue}
      searchChange={(e) => setSearchValue(e.target.value?.toUpperCase())}
      layoutState={getLayoutState()}
      filters={filters}
      onFilterClick={onFilterClick}>
      {filteredOrders.map((item, i) => (
        <OrderCard
          key={item.id + item.updated_at}
          actionDisabled={item.status === ENUM_STATUS.STARTED}
          order={item}
          currentFilter={activeFilter}
          checkOrderValidityForRole={checkOrderValidityForRole}
        />
      ))}
    </Layout>
  );
};
export default withRouter(withAlert()(Recall));

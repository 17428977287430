import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';

type Props = {
  active: boolean;
  onChange: (arg: boolean) => void;
  disabled?: boolean;
};

const Switch: FC<Props> = ({ active, onChange, disabled }: Props) => {
  const [value, setValue] = useState(false);
  useEffect(() => {
    setValue(active);
  }, [active]);
  const changeValue = () => {
    onChange(!value);
    setValue(!value);
  };
  return (
    <button
      onClick={changeValue}
      disabled={disabled}
      className={`${styles.main} ${value ? '' : styles.off}`}>
      <div className={styles.innerCircle}></div>
    </button>
  );
};

export default Switch;

/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { FC, useState, useEffect, Fragment } from 'react';
import { BaseModal, Button, Input } from '@cokitchen/cokitchen-components';
import { ReactComponent as EditIcon } from '../../../assets/images/edit-icon.svg';
import { ReactComponent as WarnInfoIcon } from '../../../assets/images/warn-info-icon.svg';
import { SOLD_OUT_MINUTES, STORAGE_KEYS } from '../../../helpers/constants';
import Switch from '../../switch';
import { TimeSelectorType } from '../../time-selector/types';
import moment from 'moment';
import { getOpeningTime } from '../../../helpers/functions';
import TimeSelectorInput from './timeSelector';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
type UpdateModalProps = {
  show: boolean;
  loading: boolean;
  shopForm: { amount: any; available_no: any; active: boolean };
  item: { name: string; available_no: number; amount: number; active: boolean };
  onClose: () => void;
  onConfirm: (arg0: {
    amount: any;
    quantity?: string;
    isInfinity?: boolean;
    soldOutMinutes: number;
    isActive: boolean;
    reactivation_time?: string;
    soldOut: boolean;
  }) => void;
  brandName: string;
  workingHours: Array<{
    day: string;
    opening_time: string;
  }>;
};

type ConfirmUpdateFooterProps = {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const ConfirmUpdateFooter: FC<ConfirmUpdateFooterProps> = ({ onCancel, onConfirm, loading }) => (
  <div className="grid grid-cols-2 gap-x-4">
    <Button small color="primary" variant="outline" className="mx-auto" onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color="primary"
      variant="block"
      className="mx-auto"
      loading={loading}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const WarnInfo = ({ text }: { text: string }) => {
  return (
    <div className="flex items-start rounded-[6px] py-[10px] px-[16px] bg-[#FEFAEB] mt-[1.75rem]">
      <span className="min-w-[1rem] mr-[10px] mt-[3px]">
        <WarnInfoIcon />
      </span>
      <span className="grow text-yellow-6 leading-[19px]">{text}</span>
    </div>
  );
};

const UpdateItemModal: FC<UpdateModalProps> = ({
  show,
  loading,
  item,
  onClose,
  onConfirm,
  shopForm,
  brandName,
  workingHours
}) => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const [checkSoldOut, setCheckSoldOut] = useState(false);
  const [soldOutMinutes, setSoldOutMinutes] = useState<number>(0);
  const [quantity, setQuantity] = useState<any>(shopForm?.available_no);
  const [amount, setAmount] = useState<any>(shopForm?.amount);
  const [isInfinity, setIsInfinity] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  const [date, setDate] = useState<string>('');
  const [startTime, setStartTime] = useState({} as TimeSelectorType);
  const [isChecked, setIsChecked] = useState(false);

  const isShopRole = role === ENUM_KITCHEN_ROLE.SHOP_STAFF;

  useEffect(() => {
    if (show) {
      const qty = shopForm.available_no === 'INFINITE' ? '' : +shopForm.available_no;
      setAmount(+shopForm?.amount);
      setQuantity(qty);
      setIsInfinity(qty === '');
      setCheckSoldOut(qty === 0);
      setSoldOutMinutes(0);
      setIsActive(shopForm?.active);
    }
  }, [show]);

  useEffect(() => {
    if (checkSoldOut) {
      setQuantity(0);
      setSoldOutMinutes(0);
    }
  }, [checkSoldOut]);

  const sendValues = (): void => {
    const time = `${startTime.hours}:${startTime.minutes} ${startTime.amPm}`;
    const formattedTime = moment(time, 'hh:mm A').format('HH:mm:ss');
    let selectedReactivatedDate = '';

    if (date !== '') {
      const activationDate = moment(date).format();
      selectedReactivatedDate = activationDate.replace('00:00:00', formattedTime);
    }

    const deducedReactivatedDate = getOpeningTime(workingHours);
    const reactivation_time = isChecked ? deducedReactivatedDate : selectedReactivatedDate;
    onConfirm({
      amount,
      quantity,
      isInfinity,
      soldOutMinutes,
      isActive,
      reactivation_time,
      soldOut: checkSoldOut
    });
  };

  const toggleMinute = (minutes: number) => {
    if (minutes === soldOutMinutes) setSoldOutMinutes(0);
    else setSoldOutMinutes(minutes);
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
  };

  return (
    <BaseModal
      isOpen={show}
      title={`Update ${item?.name}`}
      titleIcon={<EditIcon />}
      titleIconColor="info"
      onRequestClose={onClose}
      footer={<ConfirmUpdateFooter loading={loading} onCancel={onClose} onConfirm={sendValues} />}>
      <div className="mb-[1.5rem]">
        {isShopRole ? (
          <div className="">
            <div className="flex mt-2">
              <p className="mr-3 mt-1 text-[#858585] ">Turn off </p>
              <Switch active={isActive} onChange={() => setIsActive(!isActive)} />
            </div>
            <Input
              label="Price"
              className="mt-4"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              type="number"
              currency
              small
            />
          </div>
        ) : (
          <Fragment>
            <div className="flex justify-between">
              <Input
                checked={isInfinity}
                label="Select if infinte"
                onChange={() => setIsInfinity(!isInfinity)}
                disabled={checkSoldOut}
                className="mt-3"
                type="checkbox"
                small
              />

              <div className="flex mt-2">
                <Input
                  checked={isActive}
                  label="Active"
                  onChange={() => setIsActive(!isActive)}
                  type="checkbox"
                  small
                />
              </div>
            </div>

            {!isActive && (
              <div className='mt-4'>
                <TimeSelectorInput
                  date={date}
                  setDate={setDate}
                  isChecked={isChecked}
                  setIsChecked={handleChecked}
                  startTime={startTime}
                  setStartTime={setStartTime}
                  brandName={brandName}
                  item={item?.name}
                />
              </div>
            )}
            {isInfinity && (
              <WarnInfo text="Unselect infinite option to add sold out option instead" />
            )}
            <hr className="border-[#EBEBEB] mt-6 mb-4" />
            <p className="text-gray-5">
              select sold out options to add when item will be available
            </p>
          </Fragment>
        )}
        <Input
          checked={checkSoldOut}
          label="Sold Out?"
          onChange={() => setCheckSoldOut(!checkSoldOut)}
          disabled={isInfinity}
          className="mt-5"
          type="checkbox"
          small
        />
        {checkSoldOut && (
          <div className="grid grid-cols-3 gap-8 max-w-[420px] mt-5">
            {SOLD_OUT_MINUTES.map((minute) => (
              <span
                key={minute}
                className={`border rounded-[8px] p-2 text-center ${
                  soldOutMinutes === minute
                    ? 'bg-red-1 border-red-2 text-red-5'
                    : ' border-gray-2 text-gray-5'
                }`}
                role="button"
                onClick={() => toggleMinute(minute)}>
                {minute === 60 ? '+ 1 hr' : `+ ${minute} mins`}
              </span>
            ))}
          </div>
        )}
        {!isShopRole && checkSoldOut && (
          <WarnInfo text="Unselect sold out to input quantity instead" />
        )}
      </div>
    </BaseModal>
  );
};

export default UpdateItemModal;

import React from 'react';
import Logo from '../../assets/images/Logo_Dark_New.png';

import { observer } from 'mobx-react-lite';

const FoodCourtLogo = observer(function FoodCourtLogo() {
  return <img src={Logo} width={172} height={32} alt="logo" />;
});

const DocketIcon = observer(function DocketIcon() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.66683 1.83334H4.00016C2.00016 1.83334 1.3335 3.02668 1.3335 4.50001V5.16668V14.5C1.3335 15.0533 1.96016 15.3667 2.40016 15.0333L3.54016 14.18C3.80683 13.98 4.18016 14.0067 4.42016 14.2467L5.52683 15.36C5.78683 15.62 6.2135 15.62 6.4735 15.36L7.5935 14.24C7.82683 14.0067 8.20016 13.98 8.46016 14.18L9.60016 15.0333C10.0402 15.36 10.6668 15.0467 10.6668 14.5V3.16668C10.6668 2.43334 11.2668 1.83334 12.0002 1.83334H4.66683ZM7.50016 9.66668H4.50016C4.22683 9.66668 4.00016 9.44001 4.00016 9.16668C4.00016 8.89334 4.22683 8.66668 4.50016 8.66668H7.50016C7.7735 8.66668 8.00016 8.89334 8.00016 9.16668C8.00016 9.44001 7.7735 9.66668 7.50016 9.66668ZM8.00016 7.00001H4.00016C3.72683 7.00001 3.50016 6.77334 3.50016 6.50001C3.50016 6.22668 3.72683 6.00001 4.00016 6.00001H8.00016C8.2735 6.00001 8.50016 6.22668 8.50016 6.50001C8.50016 6.77334 8.2735 7.00001 8.00016 7.00001Z" fill="currentColor"/>
<path d="M12.0065 1.83334V2.83334C12.4465 2.83334 12.8665 3.01334 13.1732 3.31334C13.4932 3.64001 13.6665 4.06001 13.6665 4.50001V6.11334C13.6665 6.60668 13.4465 6.83334 12.9465 6.83334H11.6665V3.17334C11.6665 2.98668 11.8198 2.83334 12.0065 2.83334V1.83334ZM12.0065 1.83334C11.2665 1.83334 10.6665 2.43334 10.6665 3.17334V7.83334H12.9465C13.9998 7.83334 14.6665 7.16668 14.6665 6.11334V4.50001C14.6665 3.76668 14.3665 3.10001 13.8865 2.61334C13.3998 2.13334 12.7398 1.84001 12.0065 1.83334C12.0065 1.83334 12.0132 1.83334 12.0065 1.83334Z" fill="currentColor"/>
</svg>
  );
});

const ShopLogo = observer(function ShopLogo() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="200px"
      height="32px"
      viewBox="0 0 200 32"
      // enable-background="new 0 0 200 32"
      // xml:space="preserve"
    >
      <image
        id="image0"
        width="200"
        height="32"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA04AAAEsCAMAAADD3RPLAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAOVBMVEX///8AAAAOpP4OpP4O
pP4OpP4OpP4OpP4OpP4OpP4OpP4OpP4OpP4OpP4OpP4OpP4OpP4OpP7////jf2tPAAAAEXRSTlMA
ABCfvyDP7zCAr0BwUI/fYAjVmt0AAAABYktHRACIBR1IAAAACXBIWXMAAEzlAABM5QF1zvCVAAAA
B3RJTUUH5gcSCgsfJ5JA0wAABSF6VFh0UmF3IHByb2ZpbGUgdHlwZSB4bXAAAFiFxVhbEqM4DPzX
KeYIYNkyHCcJ8DdV+7nHn245EIIdks1MzSZVhIctt1pSy0H+/fmP/MAnZFXRmy55yJ31pna1lGPo
LFiybKPNOoUwL9frdQkB90eLvJOypjhpF6fcRcXYwUaJQ75kTEyaL3FO0fALg6qYFIIuOodOb3nQ
Sx4ME23iYtaHjtd2sxlw8Ey4AtBEW4hDL+XBNtyRPMzg3pUz4jYjdGmIU+okENyS/ZamMKuFCXh6
jTrgTtYR93o1XHeqeHrD3YDzHk8W/A449uAoTH7zgqPhCA9Dd/iGu3sBKDAmhRijHVwL4g/p3pAj
vp1e4M6S/RPmjFFhdsTZVx75dSQBx4DjVBYAoqwZ8SEjeYBbWIHPn1EAAkKFQAQbnakRDGHE+tx6
AWFLBrFEVYjdx4IE13h9sfkRIpyr4HSCKwPwdAQPars1Vg+TmKINAjpACHaj81K850AcEyYmhp6u
YtViuK8NF6xxQlrezcs54DZeGJyOxgXWNceYshWe3rPRNi4sm4xIYNCSgsdmcc9RQzRf3GQcD85h
eY5fk0e+W79eXh7rF/uNtTtU94LcwXQ/ayaEvMRQTa+Tg2YikzQhIX1oSDS02K2q7NocS7YY7ALq
3nVsJKL7oDC21sHTFGP55SJe915h+I04Bk0pUh0QfogDdAgXXbhuStA9mb2rkxWTfcu01Lbd9FAW
ODHNahjN8ljMyjvIZ2a9Ai9mZFkQ5v6e0yPPIfrJYzajBjcMXlWXSLlRd3L2hAUazuQYQRxmmGB/
gKogT9kVkkICwTe4UScdGCMwQW5xj+gzz/w3YmagHiVeKAWqrxAwDTRverAieGoWPgbtiEgauQse
gA5YYALoDOxZ7ww5j9Zv6BKQArFQg2EMieOKzMHkoEZH3R5L28Tz2xGt7AlzvuKdL5CFtTLXUyNL
pt42zflixNi4QAnGJCLKJM87WfZw4jaO0RE1XGYXRDkD3z2XSvqgr72aMNLfxD6xIFNGirAjx/Io
e8zCCKQaYJISlAihBlfmtQXtmcH2wDMHCPJ4aJRIlNyv9+Tel242AtlDdJlTuvYvD/mujK04vh+N
8L/M1adUfUu9PHN/Sv0OQc7gMhWspU7lZaG+kpa9OUYQZzDbs0Gm6KXKtPpTCrk8x+V9CDCz95YV
peb/Q/p7p5uFBQ80FYWEHNylIrPeWKDmsoEzY4maNyw4NTq1UfsjAKn5P9XJHlu9Jv1S8/8d/VLz
f2hQe/44ldxxkbI1W9YQyXcx+rpEQO/oOe+S7DWg6tceosjqZ7RGZrIlJ9lcZFkmiZmN8wETf6NE
XrXIv1siH8eoDpGcSRnPGCWoY1PO+Ocss8uhkxARB5ZdtLlCT4EDqORYzdRVsez0F5hFn3nemhXF
l2f/eeV/8OpdwBtlkIZLjhGi30y9vTt7b6TBRPeNi7L6+LsunkTtrLLqwpIX6vef90mybpSqfdJp
ZdWFJd9VVl1Y8l1l1YUln1VWWFpbB/+zc99wyIsdx/NOcpegccux3R+t/1EhNwTVflf+zA4SeXS+
jdkQvNnT/hVEa3E2NqqJccRf0XhDao5FSuTwDoTFkpv13dr5bobiLEWivHuw6F5tlrcpK5rdjBct
+83Kj4W3MvLF5dPVd9Oa0iafIzh3Xd75/qnr8s73T12X2veHZOyTofVuYp9R8kipykzzvVqlT/4O
UGdZBer45rNMaLx+TexoTkEob07lFwaP8TdILzQ3AAAZFUlEQVR42u2d6YKqMAxGr4Agm+j7v+x1
xlFBtn7pkrTk/J5RTHO60eXfP0VRFEVRFEVRFEVRFEVRFEVRFEVRFEVRFEVRFEVRFEVRFEVRFEVR
FEVRFEVRFEVRFEVRFEVRFEVRlNg57YD+/ZwsLzDyM/4lCgR31iULGni45LLyjlJduNMtdbizLlnQ
wMMlV8M23e8Nd7qlDnfWJQsaeLjkCoJOd+50Sx3urEsWNPBwyalOAuHOumRBAw+XnOokEO6sSxY0
8HDJqU4C4c66ZEEDD5ec6iQQ7qxLFjTwcMmpTgLhzrpkQQMPl5zqJBDurEsWNPBwyalOAuHOumRB
Aw+XnOokEO6sSxY08HDJqU4C4c66ZEEDD5ec6iQQ7qxLFjTwcMmpTgLhzrpkQQMPl5zqJBDurEsW
NPBwyalOAuHOumRBAw+XnOokEO6sSxY08HDJqU4C4c66ZEEDD5ec6iQQ7qxLFjTwcMmpTgLhzrpk
QQMPl5zqJBDurEsWNPBwyalOAuHOumRBAw+XnOokEO6sSxY08HDJqU4C4c66ZEEDD5ec6iQQ7qxL
FjTwcMmpTgLhzrpkQQMPl5zqJBDurEsWNPBwyalOAuHOumRBAw+XnOokEO6sSxY08HDJqU4C4c66
ZEEDD5ec6iQQ7qxLFjTwcMmpTgLhzrpkQQMPl5zqJBDurEsWNPBwyalOAuHOumRBAw+XnOokEO6s
SxY08HDJqU4C4c66ZEEDD5ec6iQQ7qxLFjTwcMmpTgLhzrpkQQMPl5zqJBAfmSKNtu3quin+aOq6
btvMd7zQD4KfRHUSCEWnSxsOu1/XdnlRrWTWw6v27C9e6AfBT6I6CYSiE6kgiZB/WNZfy/2PH5ru
4ide6AfBP1B1EkiaOl1MVHpR5Tfzrp/qpGyQoE5tPsBf05gapTopG6Sm0/mKu/Qyymm80A+CS051
EkhaOt2sHq267k9NqE7KBgnplNXUhulDsTeVqDopGySjU1ZX9t/2oNzu86lOygaJ6ORKph+G1kW8
0MDDJac6CSQNnTp3Mv2w0eVTnZQNUtCptR8zfZNntvFCAw+XnOokkPh1yhof31t1lvFCAw+XnOok
kOh16t328z4UZ6t4oYGHS051EkjkOvlpmp4sNlCqk7JB3Dp5a5qeNBk9Xmjg4ZJTnQQStU5X398+
XMjxQgMPl5zqJJCIdcqAVeNkbtR4oYGHS051Eki8Ol38dvRe5MR4IYE/P7dKYjuvqDpd2ravf3ck
227OVGZEq9MtjE3fAyiXOp3b+los7RYufzbhX+u621KMVAqzbyuL+qZWOSNWnbpwj1BmlHjtxD3r
jXdlDUVR1/1sI77LUihqVcoJkeqUB3yEiU9udLqR5verYjySc10KxjsoLWnbW/2hB7u4Hrg8O79/
tIQDRD7EqVNQmx55fMHjtR7y85XeTx2N5DyUQu63jWq76+JBOFVx7Vhax/OtLpb6CI8ecE+TKkqd
Ats09slap9buxfNHbC+lMHSemqi23nvewB3Oc9ds12pDfvN40JUgnYLb9PDpjMZrJatsY1e/P8pT
KVS1c6GyW2PWHkOH4Hzo32coGtOYDVzLGuyKRqgTg02f8ZOVTmf7yHnXyblQPVReFd7hvPkKxC9l
h7RR8enkfSnEMoW9Ti52ZfX+dXr0c4xOoTGBcuAA2uH0/sYEMDw6nfzWRau8Rk90ndys1v2UrNdS
KGymt96ciR2JKge+vvUZhz9K0/olNp36gN89jqf12OniZo/j5wP9lsLafq8AMv1iLlQInYwb7Mh0
CrSy6JvPmyeqTq4ePJROjwbKbgSV2Q5xr4bfH0YnQ6Hi0ilzv43dhJwQLz82FeF0ulc2E9cOjsIx
bCBD6WRwalxsOnncLLjBlRIvLzbdm4A6jacRQVo3i/1Lk2nqcDptHCESpU4BF+qNuJHi5cWmcYKH
KIXd9Fkkczf5atDjC6nTveq3HyYmnS4hA7cSQIpODruoo/5PkFIoCT45mnT5e4DdBiqoTos7tOPU
iWXgVF2I8Rr9j8N4tV4+dQPcp9rxE+yNoALrtD2kjEgnjve35ZkaLz/PPXqcQKVQYm+gMvePtd0e
BNdpc0gZj07hw7ZUN+M6OX3u0ZOEKoUKaZ+cdvTepbDZ4WPIi/V3CPHoxNDVyy3i9a6uXT73wKAT
0t/zdLLUZv+KpZpdEzwanVx3yQ242sTr9Q9Ou6ij107hdDL3yd8CsI1XqBw6zYbUsel0Dr8c4mYV
r7+/dzsbORY8YCkUJyN8vsdY94lFp7UZ81h0Cr9j0DJeXpJ+PAgOWQr5yQC/RbTqE49OtrUtr07B
XzlZt+ZeynqseMhSuBusV/Nd4a09ApdOiw/kW6cB3ig5hSN37hvDBVAnx889HpKHDcnu21T/K1ZW
fGLT6b7Qf/GtE3nZF2vIHMyE/v712fFzjZ8krE7DznREiG1oyz7x6bTQg4lDJ+vUqX6Pqvuhrpvd
9ZkbQwVMJ9cdID6dFqc5P4TZhrbYRPLptOBTFDrZRayaH0nTXreU2npmTCfH05GTGbbQ/d+tdz+B
tqEtDmgZdZqPCaLQyWpb58oa4HO39n51c9gN6eS6zm7GTxJap2E9KMFWUy4NaTl1mpZIJDrRRyBV
vbXibPHIu51Nc5BOrvt6k2CG1mmjKMNtQ2vmX86q03dQYtCJvCBi94Sr+Uld1c4UFqST6y7QZHF1
cJ2qtaop5Da0+fpyXp2++sAx6ETMSqPTeL5ufd9dT4Po5LygJ0UXXKe1GZqg7wTn1R2zTgPtghU+
nWhzsKZH8Uy2ju4fN4Lo5HyZ4aR+CK/TfbF+CnJr3YdSmE7T/mcEOpF65sA2nc8yaIO1NIhOzhN+
8iQMOi3GJ/Q2tO+M4tZp0meQr1NG+YlGi8xeXErzZ0V0cl1u07k1Bp3uC413+BMHvrp77DoNlPuK
2HSiDHTBQ4Gf/RWjfwJ0cp5o04XdHDotlGbYrt48DPw6jaMiXydC2sBHbD98MjxVDtDJ+bqbaZPL
odP83RPH4VLT4uXXaTSmFK8Toa9H+M6sMbx5BNDJ+aBi+rs4dJotjcg4TuWdTqYJ0KnB04NLJ7yO
h8ZNKIBOzvN9ur6DRafv2DLskb5/JZUAnT61jHid4Hm90vYbXenkfOXNtGlg0amaRsP1innTpxg3
TxJ0KuD04NIJ7U1UTq5ScaGT80Kbzqux6PSlNMvNdfdpVknQ6R0W3zoVNcxkpAlPj9lfpOJIJ9IE
/71sHhFoisX5sumT8Og02afB1DhNmycROhVoehB1sni0X9CZI8NTQgLoRCjmYXQ17Lmvv645//pp
PDpNutI8I6cfRs2TCJ1ek3vSdUKHTr5v//ap07xjfOlGsf6aBuDR6T56ApZpvSejMZwMnfI4dALH
815n9TCd4M1OK8PMPn9m7fegkEmnUX3FdK/qL58RgQyd/rqfwnVCByB+5yEgndCeULX+pX1eFLO7
YeilUOV1296utJnHkfQ8d9c9+XQ6Zej057dwncBY+R45+dQJfXZqKVTvXWAtxYdP+89yP9Cbd8Up
RKcyBp3AmYjeJA+F6jSAT0IshWG0/INyf+2ndLhmyZ+8pxiF6PRcmStcJ2yhTrWfhHJ1Qlca0krh
6z4MXIlPjPkmIn541z5SdLpGoBP2VdeTdzzqdG+gkR+tFL5mPgmHprz+NcxZYOu8WlkpOg0R6ISV
tu9Zcs86PTrgneFKXKpOswEaPjv3ekLevt6n7pSi029ghOsE/WOAvp5nnR4MuaFSpFKYDy7hLtur
zrKc1ysKy51Sr96eI52qorD8RXViOjUn/3jX6ZdyftKmG50y+4/508liXq/sXkq2tYVTZ2c6Na8a
7NwTXx/8/izxOmGhcnOAsyudbDfWld22UaRSmH8M3Gf7U4H88/LpzzqTO403NzpVX8fH9eT0ztLS
KcDQye+avW+arYl/RzrBjejfMxGPqlw4re1MzKfciU75vMHuiS1Un5ZO3pdEhNbpZ1GsOJ3++gC0
afLl9f60lm5woNPypXgZra64pqVTAJsAnRztXhjWWihenUi/bvU8DtoV1Zm1Tqtn/pIGvoV0nbBf
JUunk7N4LLe6vDpR3jptHFhNmthobXXaeCDS8t6kdPK7qx3Xyd2RWYtzLLw6UarvrZEgJX1rS502
z6OnzJBcUtLJ//pXTCeH7znLhYLn1YkwutieeCWc+5Rb6rS9wpN0IJ3q5E0nl0fQVfMpCV6d8KZ3
r3ion0jWaec1JWFzZJ2STiEWRfCdAjtbj8irE/7Ve2s9cC0qK52qvesd8OqwEa4TNuAVppPjJdfN
V/FHptP+Pmn8B1nptP/SH379VAjXKeaJcueLREsHB4O50gnP4f137Phk4dlGp/23lHjzlJROslZF
uD9LYdrZj0snk82RtNW4RJ0MFnji79aS0gk+6d+zTs432Nnfjcumk8lWNNryQaJOJucxwrMjmWyd
sJNXAuwe5L1qetL+supEXem3Cdya9xY6meyCgX9lK1unE/kfRejk/JTUcZcpLp1MshcWgziMW4mE
A7+l64R1l3Zvtg2sk/vmadTdi0sno+CSHoWmk9FBN/BHS9cJ+yr/BxmBOjlvnkYvS1QnG52MejLJ
6YRV797PgEV18tk8qU6qE/pLsWITdTDYL86P8f40T6qT6oT+UvAH+Z8qB3Vyf3fs+yempxN8hY+O
nQwY6wQOPvzv0UB1om4C3/+JcemkM3sSdELfhHpfGAHrlLnb9vTklZhx6eTlvZPNa1yTTIE3jYjX
Cazcvb96gnVyPnx6vazWVRHyVkVcpOuElrbvuXJcp9PZbfv06vXHpZO4NXsG44Lk1uzhwRo8v8ol
6EQ9GGeNS4w6xbiiHN/AL16nE/rfnt89UXQ6nTqXHb5OgE5wsRh0wwPvd9rtfuK99EG+TnDNTp0s
Nzulj6YT+WjGJZooddptnkLvxt1tnvDGyf/2waKGmfoAv7ipzC+hGJMNpck/EnU6nW7Orr6sJOiE
f/vOYIVwNY7fsyLOeI8i966T9bnh+Hhw9xCARR6tYGUw20PW6ZTVrnp8ZwE6EYaD270rhpOMtvsx
hAkk/0ev2B/Dj/+skuDTsxEsdnt8dJ0eQnVuWqhegE6Uc/a20jeJc/baCHQiLNPBfXqVZrU38rLR
6UHvYpKP2t26M58Ce3d86KrPU2BJa5ezCHSibHJAfRqVZrP9r5Y6PX5OZz0r0QjQiXZG+VplRTtV
w+MZ5SSbBv+HKju4c4nyFhTzadJvrzbfBFvr9CDrr1ZKFQJ0Il4+uJwOhHHT3dENGouCZ7TiaaLQ
ibQoG5jfm71lvW646EKnX85tlxOXS1QSdKLe7zQvl5YYBjf3Oy3c730jzhl1UegEr9t//zgjLvPS
HNbfkTjT6fePsj6nFJ0Enei3D06FarlvH/y+DpH+RuMShU7ULa3703Tr6bT62G51+qHHK2cJOlns
2x+u/bNorK6idXg3blm3zw7J5Uaq3p5USHow6kQ+hbreHUGtdjTW3um61+nfP7jf1ArQyfbmdmte
r4Ud3dxuTx6JTrTM+WHoNoVqtz54ubPoQyf4bgAROtHmD9zxeissRqdbLDpZnE5cXdfmJLLbTi9r
sbOI6tTmzW2x0zn+W/RHidDJ7QUhOK9yFaNTFotOdh2Lsp4bdekMelhLs6igTs9kLa/t1gfF2Tox
9/beG6ik6NRA6cGqk/Xh+UV9a59SndtbXZiON+fvdDGdPkVdNd1l7YNgMWToxNvbez+GFJ1u8ejE
VhPO3uliOn1lXHHtL7MPIrx2kaETb2/v3YMWolOFpQevTq7vdjHn650uptNSpMqm7tr2/PtHWVtT
3mHK0MnhXdo4n01xQnTKY9KJseim73QxnVwfWvmHEJ346rjxmSBCdLpEpRNn0MYbdSCdiOs5dhGi
k6/awoBBRmZ8KMD0YNbJ+fGPCKN3upBOvkpaik6UTU9uGM25ytCpjUwnwl5jh7x/BqST8xOV/zgL
0cn5CeymjDdci9BpQNODWyfGqvCH1ztdSCdfU8knITqxlcn4GUTo9Got49GJdSLpfZAEpJOnI59E
bNDgbJ4mp4FI0Ok9zxiRTtyrWhpoFcnPE3tKNhHbBzmbp8lqFQk6vSd/I9KJubv3fKeL6ORrYi+X
oxPL+/XpAc0CdPq8BItJJ1+dJ3Ny85vuPRa0hKNXXpCOYLFkugBSgE6fNc5R6cQ7u/dDh+jka2Kv
FaQTQxX3ddwkv06jiESlE0tdOKaEOnteJ/ak6BS8ivs+lZRdp/HxtnHpxDx8qoCD1PxV3KUonby1
wWt875ph12m8UyAynVgXR0Bna5y8TezVsnQK3N2bnSzOrdMkHrHp5PxuTABo97K/iT0J9zuNCdrd
mx9Az6zT9CaD2HRinI6ooXh5K2cRtw9OCDminW9r5tWpmp5cEJ1OpwuTT+CGFm+DildE5egUcF/u
wpfz6vSld3w6MfmUg/HylmSv6lCQTsGGT0tXMrHq9H3cVYQ6sfj0uiMU0MlPjr0zSpJOhLvOKCye
PM+p0+zm2Bh1YvDpHTdAJz8P+e5dSNIpTIlUiyesMeo0v+83Sp2C+/Sphcx18jOx9ylBUTqFKJGV
Wxz4dFpoLOPU6XQOOl8+atPNdfJTzJ+hryyd/E/vrd2JwqbTUtczUp2oV/CQGP8Gc528TOyNxuLC
dPJ+EMvarSZcOi0O5GLViXyvBsz0LFhznXxM7I1HD9J08utTtXpHEJNOyxfyxatToHOphtXDW3d0
8tF8js0Wp9Op9Td+2rj9jkenlestI9bpdAkwPVt8hc1cJw+5NZmXlaeTv/mIrbskWXRauyw2Zp3m
13A6Z/b4xkHyMLE3LUOBOvmaIdq86ZhDp3ztYaLWiX6NqRnDvFI0DpL7Uv5KKok6+ang8u1Lujx8
IzkEket0Onuc4bvazIQ6n9j7rqJF6uRjR9rOJcfBdZpdA5GQTv4aqOX7po2D5Hpib9bhEarTqXU7
oh0ue9/nONB7lFtXLsev0ynzsta0tpy6cdxqzm+akqqT2w5fvn+9sdtA73HdfJgEdDqdLs57fM1a
FWQcJLdt5kIhitXpdOpd/fih3/+yoDoN7fbDJKGT6x5GsR400xg5ndhb7K0L1slVh+G62zQF1mn3
gRLR6TGEcibUcNv4GtMYuSzkxTuv2XTais4HBx2GYm/U9CScTgYPlIxOD6GcdPmK7XQxjZG7ib1q
ZWaL0gAsbL+D15aYJfmjx2dXv+11q96E0mkwqUcS0ukRWetBcLNXiKYxcrYCanUsTjk0Y0kF8M1r
fjLGosNglLt/he4q0i4eKCmdHjlWW9SJQ33e/QLTGDk6IWarf3FpwO9oFj8suwIhK7HyvNFWSRTm
MoXRydjuxHR60NOWmle5wSwSEC8XzVNu2uGRSwuXRpWbdif/vsFBoLfZ7bJ8SE+nR317Qzt9VX4z
mURC4vXoedo1UCZtZQxkUBPVmBbEG886lR3yQCnq9ENv3oUpr0AjYK7Tb1H3dV5Qup9FjVXRsjl3
ZvUb7tKJqFNupnjRgVVaqjr9FOLtuhu0ou6xEsR0+uPSduZaDU0Xfx9vTltvpk1V1MRfTdKpfTSa
O0oVVzA1fqDodGnN4e6wtLe6WApbVTR1T2gBSDp9nubxOHVTFMvdwKq4di2hfo6GS//48ZWjknhB
1On5v911oZZ7PNCN+EAUnbhLhRLz9tFA/NHbSO4sSONK6VZ3bZtS726bbFTd2tceNjp9nqf/yYyb
df1P0UkxAA287xxOFnudHMKddcmCBp47K6NFdToCaOC5szJaVKcjgAaeOyujRXU6AmjgubMyWlSn
I4AGnjsro0V1OgJo4LmzMlpUpyOABp47K6NFdToCaOC5szJaVKcjgAaeOyujRXU6AmjgubMyWlSn
I4AGnjsro0V1OgJo4LmzMlpUpyOABp47K6NFdToCaOC5szJaVKcjgAaeOyujRXU6AmjgubMyWlSn
I4AGnjsro0V1OgJo4LmzMlpUpyOABp47K6NFdToCaOC5szJaVKcjgAaeOyujRXU6AmjgubMyWlSn
I4AGnjsro0V1OgJo4LmzMlpUpyOABp47K6NFdToCaOC5szJaVKcjgAaeOyujRXU6AmjgubMyWlSn
I4AGnjsro0V1OgJo4LmzMlpUpyOABp47K6NFdToCaOC5szJaVKcjgAaeOyujRXU6AmjgubMyWlSn
I4AGnjsro0V1OgJo4LmzMlpUpyOABp47K6NFdToCaOC5szJaVKcjgAaeOyujRXU6AmjgubMyWlSn
I4AGnjsro0V1OgJo4LmzMlpUpyOABp47K6NFdToCaOC5szJaVKcjgAaeOyujRXU6AmjgubMyWlSn
I4AGnjsro0V1OgJo4LmzMlpUpyOABp47K6NFdToCaOC5szJaVKcjgAaeOyujRXU6AmjgubMyWlSn
I4AGnjsro0V1OgJo4LmzMlpUpyOABp47K6Mlo+iUeXoY7qxLFjTw3FkZLzfcptrXs3BnXbKggedO
yojpmwKj9/Yo3FmnKIqiKIqiKIqiKIqiKIqiKIqiKIqiKIqiKIqiKIqiKIqiKIqiKIqiKIqiKIqi
KIqiKIqiKIqiKMoG/wEUnHkiQUTgYQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wNy0xOFQxMDox
MTozMSswMDowMAf8KeoAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMDctMThUMTA6MTE6MzErMDA6
MDB2oZFWAAAAAElFTkSuQmCC"
      />
    </svg>
  );
});

const Loader = observer(function Loader() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      // style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;"
      width="24px"
      height="24px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <g transform="translate(80,50)">
        <g transform="rotate(0)">
          <circle cx="0" cy="0" r="6" fill="#fff" fillOpacity="1">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.9210526315789473s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.9210526315789473s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(71.21320343559643,71.21320343559643)">
        <g transform="rotate(45)">
          <circle cx="0" cy="0" r="6" fill="#fff" fillOpacity="0.875">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.7894736842105262s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.7894736842105262s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(50,80)">
        <g transform="rotate(90)">
          <circle cx="0" cy="0" r="6" fill="#fff" fillOpacity="0.75">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.6578947368421052s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.6578947368421052s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(28.786796564403577,71.21320343559643)">
        <g transform="rotate(135)">
          <circle cx="0" cy="0" r="6" fill="#fff" fillOpacity="0.625">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.5263157894736842s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.5263157894736842s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(20,50.00000000000001)">
        <g transform="rotate(180)">
          <circle cx="0" cy="0" r="6" fill="#fff" fillOpacity="0.5">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.3947368421052631s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.3947368421052631s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(28.78679656440357,28.786796564403577)">
        <g transform="rotate(225)">
          <circle cx="0" cy="0" r="6" fill="#fff" fillOpacity="0.375">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.2631578947368421s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.2631578947368421s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(49.99999999999999,20)">
        <g transform="rotate(270)">
          <circle cx="0" cy="0" r="6" fill="#fff" fillOpacity="0.25">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.13157894736842105s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"
              values="1;0"
              begin="-0.13157894736842105s"></animate>
          </circle>
        </g>
      </g>
      <g transform="translate(71.21320343559643,28.78679656440357)">
        <g transform="rotate(315)">
          <circle cx="0" cy="0" r="6" fill="#fff" fillOpacity="0.125">
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="0s"
              values="1.5 1.5;1 1"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"></animateTransform>
            <animate
              attributeName="fill-opacity"
              keyTimes="0;1"
              dur="1.0526315789473684s"
              repeatCount="indefinite"
              values="1;0"
              begin="0s"></animate>
          </circle>
        </g>
      </g>
    </svg>
  );
});

export { FoodCourtLogo, DocketIcon, ShopLogo, Loader };

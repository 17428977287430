import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import { OrderType } from '../../helpers/types';
import { OrderService } from '../../services/order.service';

export const apiLiveMapping: Partial<Record<ENUM_KITCHEN_ROLE, () => Promise<any>>> = {
  KITCHEN_STAFF: OrderService.getFrontdeskCurrentUnpaidOrders,
  PACKAGING: OrderService.getFrontdeskCurrentUnpaidOrders,
  SHOP_STAFF: OrderService.getFrontdeskCurrentUnpaidOrders,
  KITCHEN_ADMIN: OrderService.getFrontdeskCurrentUnpaidOrders,
  SUPER_ADMIN: OrderService.getFrontdeskCurrentUnpaidOrders,
  FRONT_DESK: OrderService.getFrontdeskCurrentUnpaidOrders
};

/* eslint-disable */
export const checkOrderValidityForRole = (order: OrderType) => {
  const isUnpaid = !order.paid && !order.cancelled;
  return isUnpaid;
};

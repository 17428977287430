import React from 'react';
import { RiWifiOffLine } from 'react-icons/ri';
import { IoIosWarning } from 'react-icons/io';
import useNetworkContext from '../../contexts/network.context';

const OfflineStatus = () => {
  if (!window.navigator.onLine) {
    return (
      <div className="flex items-center justify-center bg-red-5 p-3 gap-3 text-white">
        <RiWifiOffLine fill="#fff" size="25px" /> No Network!
        <span>Kindly check your internet connection and refresh.</span>
      </div>
    );
  }

  const { networkSpeedInMbps } = useNetworkContext();

  if (networkSpeedInMbps < 1) {
    const text = networkSpeedInMbps <= 0.5 ? 'Very bad network!' : 'Bad network!';
    const bgColor = networkSpeedInMbps <= 0.5 ? 'bg-red-3' : 'bg-yellow-5';

    return (
      <div className={`flex items-center justify-center p-3 gap-2 text-white ${bgColor}`}>
        <IoIosWarning fill="#fff" size="25px" />
        {text}
        <span>Reach out to IT administrator to check your internet</span>
      </div>
    );
  }

  return <span />;
};

export default OfflineStatus;

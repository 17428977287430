/**
 * Advanced example demonstrating all core MobX constructs.
 */
import { makeAutoObservable } from 'mobx';

class CommonStore {
  // ====================================================
  // State
  // ====================================================
  searchResults = [];
  selectedMeals = [];
  allBrandsArray = [];
  error = null;
  searchLoading = false;
  showGlobal = false;
  searchValue = '';
  mealFilterValue = null;
  constructor () {
    makeAutoObservable(this);
  }

  // ====================================================
  // Computed views
  // ====================================================
  // While MobX promotes OOP, we can still benefit from using FP where it's appropriate

  // ====================================================
  // Actions
  // ====================================================

  setSearchResults = (data) => {
    this.searchResults = data;
  };

  setShowGlobal = (data) => {
    this.showGlobal = data;
  };

  setSearchValue = (data) => {
    this.searchValue = data;
  };

  setSelectedMeals = (data) => {
    this.selectedMeals = data;
  };

  setAllBrandsArray = (data) => {
    this.allBrandsArray = data;
  };

  setMealFilterValue = (data) => {
    this.mealFilterValue = data;
  };
}

export default new CommonStore();

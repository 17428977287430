import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_STATUS, ENUM_ITEM_TYPES } from '../../helpers/enums';
import { OrderType } from '../../helpers/types';
import { OrderService } from '../../services/order.service';

type ReturnType = ((order_id: string, itemType?: ENUM_ITEM_TYPES) => Promise<any>) | undefined;

const getKitchenFn = ({ status }: Partial<OrderType>): ReturnType => {
  if (status === ENUM_STATUS.PENDING) return OrderService.startKitchenOrder;
  if (status === ENUM_STATUS.PACKAGING) return OrderService.endKitchenOrder;
  // eslint-disable-next-line
  if (status === ENUM_STATUS.READY) return OrderService.recallKitchenOrder;
  if (status === ENUM_STATUS.DISPATCH) return OrderService.dispatchOrder;
};

const getShopFn = ({ status }: Partial<OrderType>): ReturnType => {
  if (status === undefined) return;
  if (status === ENUM_STATUS.PENDING) return OrderService.startShopOrder;
  if ([ENUM_STATUS.PACKAGING, ENUM_STATUS.STARTED, ENUM_STATUS.SEARCH].includes(status)) {
    return OrderService.endShopOrder;
  }
  if (status === ENUM_STATUS.READY) return OrderService.recallShopOrder;
  if (status === ENUM_STATUS.DISPATCH) return OrderService.dispatchOrder;
};

const getPackagingFn = ({ status }: Partial<OrderType>): ReturnType => {
  if (status === ENUM_STATUS.PACKAGING) return OrderService.endKitchenOrder;
  if (status === ENUM_STATUS.READY) return OrderService.recallKitchenOrder;
  if (status === ENUM_STATUS.DISPATCH) return OrderService.dispatchOrder;
};

const getFrontDeskFn = ({ paid, status, calculated_order }: Partial<OrderType>): ReturnType => {
  if (paid === undefined || status === undefined) return;
  if (!paid) return OrderService.confirmUnpaidOrder;
  if (status === ENUM_STATUS.DISPATCH) {
    const isPickup = calculated_order?.pickup ?? false;
    // eslint-disable-next-line
    return isPickup ? OrderService.frontDeskCompleteOrder : OrderService.dispatchOrder;
  }
};

const getAdminFn = (
  { status, calculated_order }: Partial<OrderType>,
  actionType?: ENUM_ITEM_TYPES
): ReturnType => {
  const item_type = calculated_order?.item_type;
  if (item_type === ENUM_ITEM_TYPES.SHOP) return getShopFn({ status });
  if (item_type === ENUM_ITEM_TYPES.FOOD) return getKitchenFn({ status });
  if (actionType === ENUM_ITEM_TYPES.SHOP) return getShopFn({ status });
  if (actionType === ENUM_ITEM_TYPES.FOOD) return getKitchenFn({ status });
};

const CustomButtonConfig: Record<ENUM_KITCHEN_ROLE, (order: Partial<OrderType>) => ReturnType> = {
  KITCHEN_STAFF: getKitchenFn,
  FCB_KITCHEN_STAFF: getKitchenFn,
  SHOP_STAFF: getShopFn,
  FRONT_DESK: getFrontDeskFn,
  PACKAGING: getPackagingFn,
  FCB_PACKAGING_STAFF: getPackagingFn,
  KITCHEN_ADMIN: getAdminFn,
  FCB_KITCHEN_SUPERVISOR: getAdminFn,
  SUPER_ADMIN: getAdminFn,
  ADMIN: getAdminFn,
  DEVELOPER: getAdminFn
};

export const getCustomButtonConfig = (): ((order: OrderType) => ReturnType) => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
  return CustomButtonConfig[role];
};

import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { NetworkContext } from './context';
import { BYTES_IN_KILOBYTE, NetworkClass } from '../../services/network.service';

const networkObj = new NetworkClass();

const NetworkProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [networkSpeed, setNetworkSpeed] = useState(0);

  const networkSpeedInMbps = useMemo(() => {
    return networkSpeed / BYTES_IN_KILOBYTE / BYTES_IN_KILOBYTE;
  }, [networkSpeed]);

  const disableNetworkActions = useMemo(() => {
    return networkSpeedInMbps < 0.5;
  }, [networkSpeedInMbps]);

  useEffect(() => {
    let timer = 3000;

    const myInterval = function () {
      networkObj.startTest().then((speed) => {
        setNetworkSpeed(speed);
        if (speed <= 500000 || !navigator.onLine) {
          timer = 100;
        } else timer = 3000;

        setTimeout(myInterval, timer);
      });
    };

    myInterval();
  }, []);

  return (
    <NetworkContext.Provider
      value={{
        networkSpeed,
        setNetworkSpeed,
        disableNetworkActions,
        networkSpeedInMbps
      }}>
      {children}
    </NetworkContext.Provider>
  );
};

export default NetworkProvider;

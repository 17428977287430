import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { PackagingScreenType } from '../header/packaging-screen';
import ActiveTag from '../active-tag';
import { STORAGE_KEYS } from '../../helpers/constants';
import homeStore from '../../stores/homeStore';

type Props = {
  screen: PackagingScreenType;
};

const PackagingScreenCard = (props: Props) => {
  const { screen } = props;
  const [activeScreen, setActiveScreen] = useState(false);
  const { isBrandPackagingScreen } = homeStore;

  const handleScreenSelect = () => {
    if (screen.active) {
      localStorage.setItem(STORAGE_KEYS.PACKAGING_SCREEN_DETAILS, JSON.stringify(screen));
      localStorage.setItem(STORAGE_KEYS.IS_BRAND_PACKAGING_SCREEN, JSON.stringify(true));
      localStorage.setItem(STORAGE_KEYS.IS_BRAND_SPLIT_SCREEN, JSON.stringify(false));
      window.location.reload();
    }
  };

  useEffect(() => {
    const screenDetails = localStorage.getItem(STORAGE_KEYS.PACKAGING_SCREEN_DETAILS) ?? '';
    if (screenDetails !== '' && Boolean(isBrandPackagingScreen)) {
      setActiveScreen(JSON.parse(screenDetails)?.id === screen.id);
    }
  }, []);

  return (
    <div
      onClick={handleScreenSelect}
      className={`${
        screen.active ? 'cursor-pointer' : 'cursor-not-allowed'
      } flex flex-col items-start gap-6 p-4 border ${
        activeScreen ? ' border-red-3' : 'border-[#EDEDEE]'
      } rounded-lg ${screen.active ? 'hover:border-red-3' : ''}`}>
      <div className="flex flex-col gap-4">
        <ActiveTag active={screen.active} />
        <article>
          <p className="text-sm text-[#1A1A1A] font-medium">{screen.name}</p>
          <p className="text-xs text-[#858585]">
            Created {moment(screen.created_at).format('MMM d, YYYY, HH:mma')}
          </p>
        </article>
      </div>
    </div>
  );
};

export default PackagingScreenCard;

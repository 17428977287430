import moment from 'moment';
import { ENUM_ITEM_TYPES, ENUM_ORDER_PROVIDER } from './enums';
import { BrandMealType, MealType, OrderType } from './types';
import { ROUTE_KEYS, STORAGE_KEYS } from './constants';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

export const groupArrayByKey = (arr: any[], fieldOne: string): any => {
  return arr.reduce((r: Record<any, any>, a: any) => {
    // eslint-disable-next-line
    r[a[fieldOne]] = r[a[fieldOne]] || [];
    r[a[fieldOne]].push(a);
    return r;
  }, {});
};

// To calculate total price of each meal

export const mealTotal = (meals: OrderType): number => {
  const total = +meals?.amount * +meals?.quantity;
  const addonTotal: number = meals?.addons?.reduce((acc: number, curr) => {
    return acc + +curr.amount;
  }, 0);
  return total + addonTotal;
};

// Reducer function to compute total
export const getTotal = (items: MealType[]): number => {
  return items.reduce((acc: number, curr) => {
    return acc + +(curr.quantity ?? 0);
  }, 0);
};

// To calculate the total number of items bought
export const getItemsTotal = (ords: BrandMealType[]): number => {
  const tot: number[] = [];
  for (let i = 0; i < ords?.length; i++) {
    tot.push(getTotal(ords[i].meals));
  }
  return tot.reduce((acc: number, curr) => {
    return acc + curr;
  }, 0);
};

export const showPinnedFilter = (pathname: string): boolean => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  return pathname === ROUTE_KEYS.KITCHEN.HOME && role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF;
};

export const orderIsPinned = (pinnedOrders: OrderType[], order: OrderType): boolean => {
  const orderExists = pinnedOrders?.find((pinnedOrder) => pinnedOrder?.id === order?.id);
  return orderExists !== undefined;
};

export const formatPlural = (number: number, text: string, suffix = 's'): string => {
  return `${number} ${text}${number !== 1 ? suffix : ''}`;
};
export const getPackagingStation = (screenText: string): string => {
  if (screenText !== null) {
    const screenNo = screenText?.split('_')[2];
    return screenNo;
  }
  return '-';
};
export const getNameFromOrder = (order: OrderType): string => {
  const calculatedName = Boolean(order?.calculated_order?.user?.first_name);
  const user = calculatedName ? order?.calculated_order?.user : order?.user;
  // eslint-disable-next-line
  return `${user?.first_name} ${user?.last_name}`;
};

export const numberWithCommas = (x: string | number): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getOrderLatestTime = (order: OrderType): Date => {
  const times = [
    order.created_at,
    order.kitchen_completed_time,
    order.kitchen_dispatched_time,
    order.kitchen_verified_time,
    order.shop_completed_time,
    order.completed_time
  ];
  const maxTime: Date = times.reduce((maxTime: Date, timeString: string | null): Date => {
    if (timeString === null) return maxTime;
    const time = new Date(timeString);
    return time > maxTime ? time : maxTime;
  }, new Date(0));
  return maxTime;
};

export const filterMealsByItemType = (
  meals: BrandMealType[],
  itemType: ENUM_ITEM_TYPES
): BrandMealType[] => {
  return meals.filter((item) => item.brand.item_type === itemType);
};

export const getOpeningTime = (workHours: Array<{ day: string; opening_time: string }>): string => {
  const dayAfterToday = moment().add(1, 'days').format('YYYY-MM-DD');
  const tomorrowDay = moment(dayAfterToday).format('dddd');
  // get opening time for tomorrow
  const tomorrowDayWorkingHours = workHours?.filter((itm: { day: string }) => {
    return itm?.day?.includes(tomorrowDay.toLocaleLowerCase());
  });
  const opening_time = tomorrowDayWorkingHours[0]?.opening_time;
  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
  const tomorrowDate = moment(tomorrow).format();
  // get the hour before openingTime
  const openingTime = moment(opening_time, 'HH:mm:ss').subtract(1, 'hours').format('HH:mm:ss');
  const openingTimeTomorrow = tomorrowDate.replace('00:00:00', openingTime);
  return openingTimeTomorrow;
};

export const isOrderReady = (order: OrderType): boolean => {
  if (order.kitchen_ready_brand_ids !== undefined) {
    const meals = order.calculated_order?.meals ?? [];
    const allActiveBrands = meals.map(({ brand }) => brand.id);
    const allBrandsAreActive = allActiveBrands.every((brand) =>
      order.kitchen_ready_brand_ids?.includes(brand)
    );
    return allBrandsAreActive;
  }
  return false;
};
export const convertTo12hrs = (time24hr: string) => {
  const [hours, minutes] = time24hr.split(':');
  let period = 'AM';
  let hours12 = parseInt(hours, 10);

  if (hours12 >= 12) {
    period = 'PM';
    if (hours12 > 12) {
      hours12 -= 12;
    }
  }
  if (hours12 === 0) {
    hours12 = 12;
  }

  const time12hr = `${hours12}:${minutes} ${period}`;
  return time12hr;
};

export const isGlovo = (order: OrderType): boolean => {
  return order.delivery === ENUM_ORDER_PROVIDER.GLOVO;
};
export const isChowdeck = (order: OrderType): boolean => {
  return order.delivery === ENUM_ORDER_PROVIDER.GLOVO;
};

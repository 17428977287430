/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { BaseModal, Button } from '@cokitchen/cokitchen-components';
import { ReactComponent as ReasonIcon } from '../../assets/images/reason.svg';
import React, { FC, useState } from 'react';
import { SOLD_OUT_MINUTES } from '../../helpers/constants';
type Props = {
  show: boolean;
  onClose: () => void;
  name: string;
  brandName: string;
  workingHours: Array<{
    day: string;
    opening_time: string;
  }>;
  onConfirm: (soldOutMinutes: number) => void;
  loading: boolean;
};

const TurnOffMeal: FC<Props> = ({ show, onClose, name, onConfirm, loading }: Props) => {
  const [soldOutMinutes, setSoldOutMinutes] = useState<number>(0);

  const submitData = () => {
    onConfirm(soldOutMinutes);

    onClose();
  };
  const toggleMinute = (minutes: number) => {
    if (minutes === soldOutMinutes) setSoldOutMinutes(0);
    else setSoldOutMinutes(minutes);
  };
  return (
    <BaseModal
      isOpen={show}
      title={`Set ${name} to 'sold out'`}
      onRequestClose={onClose}
      titleIcon={<ReasonIcon />}
      titleIconColor="danger"
      footer={
        <div className="flex justify-end gap-x-4">
          <Button
            color="gray"
            variant="block"
            onClick={onClose}
            className="!bg-[#F2F2F2] !text-[#595959] !border-0 !w-[100px]">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="block"
            onClick={submitData}
            className="!w-[100px]"
            loading={loading}>
            Turn off
          </Button>
        </div>
      }>
      <div className="pt-5">
        <p className="text-[#595959] text-sm font-normal mb-4">When will {name} be back?</p>
        <div className="grid grid-cols-3 gap-8 max-w-[420px] mt-5">
          {SOLD_OUT_MINUTES.map((minute) => (
            <span
              key={minute}
              className={`border rounded-[8px] p-2 text-center ${
                soldOutMinutes === minute
                  ? 'bg-red-1 border-red-2 text-red-5'
                  : ' border-gray-2 text-gray-5'
              }`}
              role="button"
              onClick={() => toggleMinute(minute)}>
              {minute === 60 ? '+ 1 hr' : `+ ${minute} mins`}
            </span>
          ))}
        </div>
      </div>
    </BaseModal>
  );
};

export default TurnOffMeal;

import React, { FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as XIcon } from '../../assets/images/x-icon.svg';
import { Input } from '@cokitchen/cokitchen-components';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_STATUS } from '../../helpers/enums';
import BrandMealAddon from './brandMealAddon';
import OrderNote from './orderNote';
import { OrderType } from '../../helpers/types';
import { isOrderReady } from '../../helpers/functions';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

type BrandMealType = {
  mainOrder: OrderType;
  meal: any;
  mealIndex: string;
  isChecked: boolean;
  status: string;
  onAddonSelect: (_: string) => void;
  onMealSelect: () => void;
  selectedAddons: any;
  printStickerMeals: any;
  setPrintStickerMeals: (arg: any) => void;
};

const BrandMeal: FC<BrandMealType> = ({
  mainOrder,
  meal,
  mealIndex,
  isChecked,
  status,
  selectedAddons,
  onAddonSelect,
  onMealSelect,
  printStickerMeals,
  setPrintStickerMeals
}) => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
  const [isPrintStickerChecked, setIsPrintStickerChecked] = useState(true);
  const hasMounted = useRef(false);
  const hasPackaging =
    (role === ENUM_KITCHEN_ROLE.PACKAGING && status === ENUM_STATUS.PACKAGING) ||
    (role === ENUM_KITCHEN_ROLE.SHOP_STAFF && status === ENUM_STATUS.STARTED);

  const addons = meal?.addons ?? [];
  const canPrintSticker = () => role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF && isOrderReady(mainOrder);

  const onPrintCheck = () => {
    if (isPrintStickerChecked) {
      setPrintStickerMeals([...printStickerMeals, { ...meal, uniqueId: mealIndex }]);
    } else {
      setPrintStickerMeals((prv: Array<Record<string, any>>) =>
        prv.filter((item) => item.uniqueId !== mealIndex)
      );
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
    setPrintStickerMeals((prev: Array<Record<string, any>>) => [
      ...prev,
      { ...meal, uniqueId: mealIndex }
    ]);
  }, []);

  useEffect(() => {
    if (hasMounted.current) onPrintCheck();
    hasMounted.current = true;
  }, [isPrintStickerChecked]);
  return (
    <div>
      <div className="flex justify-start order_card_item mb-[8px]">
        <div
          className="food_count font-light text-center"
          onClick={() => {
            if (hasPackaging) {
              onMealSelect();
            }
          }}>
          <div className="text-[#E60A2B] font-extrabold m-0 flex items-center">
            <span className="mt-[2px]">
              <XIcon />
            </span>
            <span className="ml-[1px]">{meal?.quantity}</span>
          </div>
        </div>
        <div
          className={`flex justify-between w-full ${hasPackaging ? 'pointer' : ''}`}
          onClick={() => {
            if (hasPackaging) {
              onMealSelect();
            }
          }}>
          <div className="flex justify-start ">
            <p className="font-medium text-[16px] leading-[25px] m-0">{meal?.name}</p>
            {hasPackaging && (
              <div className="ml-3 mt-[2px]">
                <Input
                  type="checkbox"
                  checkColor="green"
                  checked={isChecked}
                  onChange={onMealSelect}
                />
              </div>
            )}
            {canPrintSticker() && (
              <div className="ml-3 mt-[2px]">
                <Input
                  type="checkbox"
                  checkColor="green"
                  checked={printStickerMeals?.find((item: any) => item.uniqueId === mealIndex)}
                  onChange={(e) => setIsPrintStickerChecked(!isPrintStickerChecked)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line */}
      {meal?.order_note && <OrderNote note={meal.order_note} />}
      {addons.length > 0 && (
        <div className="pl-[45px] mb-4">
          {addons.map((addon: any) => {
            /* eslint-disable-next-line */
            const addonIndex = `${mealIndex}-${addon?.id}`;
            const isAddonChecked = selectedAddons.includes(addonIndex);

            return (
              <BrandMealAddon
                addon={addon}
                showCheckbox={hasPackaging}
                isChecked={isAddonChecked}
                key={addonIndex}
                onAddonChange={() => onAddonSelect(addonIndex)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BrandMeal;

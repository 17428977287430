import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_ITEM_TYPES } from '../../helpers/enums';
import { OrderType } from '../../helpers/types';
import { OrderService } from '../../services/order.service';

export const apiLiveMapping: Partial<Record<ENUM_KITCHEN_ROLE, () => Promise<any>>> = {
  FRONT_DESK: OrderService.getFrontdeskDispatchOrders,
  KITCHEN_STAFF: OrderService.getFrontdeskDispatchOrders,
  FCB_KITCHEN_STAFF: OrderService.getFrontdeskDispatchOrders,
  KITCHEN_ADMIN: OrderService.getFrontdeskDispatchOrders,
  SUPER_ADMIN: OrderService.getFrontdeskDispatchOrders,
  FCB_KITCHEN_SUPERVISOR: OrderService.getFrontdeskDispatchOrders,
  ADMIN: OrderService.getFrontdeskDispatchOrders,
  DEVELOPER: OrderService.getFrontdeskDispatchOrders
};

/* eslint-disable */
export const checkOrderValidityForRole = (
  order: OrderType,
  cokitchenId: string,
  test: boolean
): boolean | undefined => {
  if (!order) return false;
  if (test && !order.order_code.startsWith('test')) return false;
  if (!test && order.order_code.startsWith('test')) return false;
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
  const conditionMap: Partial<Record<ENUM_KITCHEN_ROLE, (order: OrderType) => boolean>> = {
    KITCHEN_STAFF: getCondition,
    FRONT_DESK: getCondition,
    SUPER_ADMIN: getCondition,
    KITCHEN_ADMIN: getCondition
  };
  const fn = conditionMap[role];
  if (fn) {
    return fn(order) && (!cokitchenId || order?.calculated_order?.cokitchen_id === cokitchenId);
  }
  return false;
};

const getCondition = (order: OrderType): boolean => {
  const kitchen_ready =
    order.calculated_order.item_type === ENUM_ITEM_TYPES.FOOD && order.kitchen_prepared;
  const shop_ready =
    order.calculated_order.item_type === ENUM_ITEM_TYPES.SHOP && order.shop_prepared;
  const all_ready =
    order.calculated_order.item_type === ENUM_ITEM_TYPES.ALL &&
    order.shop_prepared &&
    order.kitchen_prepared;
  return (kitchen_ready || shop_ready || all_ready) && !order.kitchen_dispatched;
};
/* eslint-enable */

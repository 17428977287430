import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import OrderCard from '../../components/orderCard/index';
import { withRouter } from 'react-router-dom';
import homeStore from '../../stores/homeStore';
import added from '../../assets/audios/bell.wav';
import { withAlert } from 'react-alert';
import { observer } from 'mobx-react-lite';
import { ENUM_LAYOUT_STATE, ENUM_PAGES, ENUM_STATUS } from '../../helpers/enums';
import { getOrderLatestTime } from '../../helpers/functions';
import { FILTERS_BY_ROUTE } from '../live/filters';
import { STORAGE_KEYS } from '../../helpers/constants';
import useLayoutContext from '../../contexts/layout.context';

//  Copied from front desk unpaid orders
//  add compatibility for admins
const UnpaidOrders = observer(function UnpaidOrders({ alert }) {
  const { deliveryType, setSocketConfig, loading, orders, setOrders } = useLayoutContext();
  const [prefilteredOrders, setPrefilteredOrders] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [orderSummary, setOrderSummary] = useState({
    pendingOrdersCount: 0,
    trackingOrdersCount: 0
  });
  const [searchValue, setSearchValue] = useState('');
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const cokitchenId = localStorage.getItem(STORAGE_KEYS.COKITCHEN);

  useEffect(() => {
    setOrderSummary({
      pendingOrdersCount: orders.filter((order) => !order.kitchen_accepted).length,
      trackingOrdersCount: orders.filter((order) => order.kitchen_accepted).length
    });

    const foundOrder = orders.filter(
      (o1) => !homeStore.allPaidOrders.some((o2) => o1.order_code === o2.order_code)
    );
    if (foundOrder.length > 0) {
      playSound(new Audio(added));
    }
    homeStore.updatePaidOrders(orders);
  }, [orders]);

  const onFilterClick = (filter) => {
    setSelectedFilters([filter]);
    const newFilters = filters.reduce((acc, f) => {
      if (f.title === filter.title) f.active = !f.active;
      else f.active = false;
      acc.push(f);
      return acc;
    }, []);
    setFilters(newFilters);
  };

  useEffect(() => {
    initFilters();
  }, []);
  const initFilters = () => {
    const routeFilters = FILTERS_BY_ROUTE[location.pathname]?.map((filter) => {
      const count = prefilteredOrders.filter((order) => filter.condition(order, filter.key)).length;
      return { ...filter, number: count };
    });
    if (routeFilters) {
      const [firstItem, ...others] = routeFilters;
      setFilters([{ ...firstItem, active: true }, ...others]);
      setSelectedFilters(routeFilters[0]);
      setFiltersInitialized(true);
    }
  };

  useEffect(() => {
    if (!filtersInitialized) return;
    setFilters(
      filters.map((filter) => {
        const count = prefilteredOrders.filter((order) =>
          filter.condition(order, filter.key)
        ).length;
        return { ...filter, number: count };
      })
    );
  }, [prefilteredOrders, filtersInitialized]);

  useEffect(() => {
    const activeFilters = selectedFilters;
    if (!activeFilters.length) {
      setFilteredOrders([...prefilteredOrders]);
    } else {
      setFilteredOrders(
        prefilteredOrders.filter((order) =>
          activeFilters.find((filter) => filter.condition(order, filter.key))
        )
      );
    }
  }, [prefilteredOrders, selectedFilters]);
  const getRoomName = () => {
    if (cokitchenId) {
      return `order-update-cokitchen:${cokitchenId}`;
    }
    return 'order-update';
  };

  const initPage = () => {
    setSocketConfig({ roomName: getRoomName(), page: ENUM_PAGES.UNPAID });
  };

  useEffect(() => {
    initPage();
  }, []);

  const playSound = (audioFile) => {
    audioFile.muted = true;
    audioFile.play();
  };

  useEffect(() => {
    const search = searchValue?.toUpperCase();
    const filtered = orders.filter((order) => {
      const searchFilter =
        order?.order_code?.toUpperCase().includes(search) ||
        (order?.calculated_order?.user?.first_name + ' ' + order?.calculated_order?.user?.last_name)
          ?.toUpperCase()
          .includes(search) ||
        order?.calculated_order?.user?.phone_number?.toUpperCase().includes(search) ||
        order?.calculated_order?.user?.email?.toUpperCase().includes(search);

      const groupOrderFilter = order.calculated_order?.group_sub_order;
      return searchFilter && !groupOrderFilter;
    });
    // TODO better optimization sort orders by status
    setPrefilteredOrders(
      filtered
        .filter((order) => {
          if (deliveryType) {
            return deliveryType === order.delivery;
          } else {
            return order;
          }
        })
        .sort((a, b) => {
          return getOrderLatestTime(b) - getOrderLatestTime(a);
        })
    );
  }, [orders, searchValue, deliveryType]);

  const setPaidOrder = async (id, i) => {
    const { setPaidOrder } = homeStore;
    const pendings = [...orders];
    pendings[i].loading = true;
    setOrders(pendings);
    await setPaidOrder(alert, id, true);
    const pends = [...orders];
    pends[i].loading = false;
    setOrders(pends);
  };

  const getLayoutState = () => {
    if (filteredOrders.length === 0 && searchValue) return ENUM_LAYOUT_STATE.EMPTY_SEARCH;
    if (
      ((orderSummary.trackingOrdersCount === 0 && orderSummary.pendingOrdersCount === 0) ||
        filteredOrders.length === 0) &&
      !loading
    ) {
      return ENUM_LAYOUT_STATE.WAITING;
    }
    if (orders.length === 0 && loading) return ENUM_LAYOUT_STATE.LOADING;
    return ENUM_LAYOUT_STATE.PAGE;
  };

  return (
    <Layout
      pendingOrders={orderSummary.trackingOrdersCount}
      trackingOrders={orderSummary.pendingOrdersCount}
      searchValue={searchValue}
      searchChange={(e) => setSearchValue(e.target.value?.toUpperCase())}
      filters={filters}
      onFilterClick={onFilterClick}
      layoutState={getLayoutState()}>
      {filteredOrders.map((item, i) => (
        <OrderCard
          key={item.order_code}
          defaultStatus={ENUM_STATUS.PENDING}
          actionClick={() => {
            setPaidOrder(item && item.order_code, i);
          }}
          order={item}
        />
      ))}
    </Layout>
  );
});

export default withRouter(withAlert()(UnpaidOrders));

/* eslint-disable */
import React, { useEffect, useState } from 'react';
import homeStore from '../../stores/homeStore';
import '../mealsCard/styles.scss';
import { withAlert } from 'react-alert';
import { observer } from 'mobx-react';
import commonStore from '../../stores/commonStore';
import InventoryCategory from '../inventory-category';
import moment from 'moment';
import UpdateItemModal from '../inventory-category/modals/updateItemModal';
import { InventoryService } from '../../services/inventory.service';
import { formatTime } from '../../helpers/functions';
import { STORAGE_KEYS } from '../../helpers/constants';

const InventoryBrand = observer(function InventoryBrand({
  mealCategories,
  onReloadData,
  brandId,
  alert,
  brandName,
  workingHours
}) {
  const { updateMeal, mealUpdateLoading } = homeStore;
  const [allMeals, setAllMeals] = useState([]);
  const [shopForm, setShopForm] = useState('');
  const [currentShopItem, setCurrentShopItem] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [addons, setAddons] = useState([]);
  const [addonsObj, setAddonsObj] = useState({
    name: 'Addons',
    meals: []
  });

  let { selectedMeals } = commonStore;

  const formatMeals = (meals) => {
    return meals?.map((itm) => {
      return { ...itm, altered: false };
    });
  };
  const COKITCHEN_ID = localStorage.getItem(STORAGE_KEYS.COKITCHEN);
  const fetchAddons = async () => {
    const resp = await InventoryService.getBrandAddons(brandId);
   const formattedAddons = formatMeals(resp.data);
    setAddons(formattedAddons);
  };

  useEffect(async () => {
    fetchAddons();
  }, []);

  useEffect(() => {
    const formattedMeals = handleSortMealData(mealCategories);
    setAllMeals(formattedMeals.mealsArr);
  }, [mealCategories]);

  useEffect(() => {
    setAddonsObj({
      name: 'Addons',
      meals: addons?.map((addon)=> {return {...addon, is_addon:true}})
    });
  }, [addons]);

  selectedMeals = selectedMeals.map((itm) => {
    return { ...itm };
  });

  const handleSortMealData = (arr) => {
    let mealsArr = [];
    let allMealsArr = [];
    let activeMealsArr = [];
    let inactiveMealsArr = [];

    mealsArr = arr?.map((item) => {
      return {
        ...item,
        altered: false,
        meals: formatMeals(item.meals)
      };
    });

    allMealsArr = arr?.reduce((acc, curr) => {
      return [...acc, ...curr?.meals];
    }, []);

    activeMealsArr = allMealsArr?.filter((itm) => itm?.active);

    inactiveMealsArr = allMealsArr?.filter((itm) => !itm?.active);

    return { mealsArr, activeMealsArr, inactiveMealsArr, allMealsArr };
  };

  const handleMealUpdate = async (meal) => {
    setShopForm({
      ...meal
    });
    setShowUpdateModal(!showUpdateModal);
    setCurrentShopItem(meal);
  };

  // To update meals
  const updateMealItem = async ({
    amount,
    quantity,
    isInfinity,
    soldOutMinutes,
    isActive,
    reactivation_time,
    soldOut
  }) => {
    const { id: meal_id, isAddon } = currentShopItem;
    if (isAddon) {
      updateAddonItem({ amount, quantity, isInfinity, isActive });
      return;
    }
    const payload = {
      active: isActive,
      amount: amount?.toString(),
      meal_ids: [meal_id],
      ...(reactivation_time !== '' && { reactivation_time }),
      sold_out: quantity === 0,
      availability_soon_date:
        soldOutMinutes === 0 ? null : new Date(moment().add(soldOutMinutes, 'minutes')),
      cokitchen_id: COKITCHEN_ID,
      sold_out:soldOut
    };
    await updateMeal(alert, payload, false);
    setShowUpdateModal(false);
    onReloadData();
  };

  const updateAddonItem = async ({ amount, quantity, isInfinity, isActive: active }) => {
    const { id: meal_id } = currentShopItem;
    const meals = 
      {
        active: !active,
        addon_ids: [meal_id],
        cokitchen_id: COKITCHEN_ID
      }
    await updateMeal(alert, meals, true);
    setShowUpdateModal(false);
    fetchAddons();
  };
  return (
    <>
      {allMeals.map((meal) => (
        <InventoryCategory
          showFoodItems={true}
          showShopItems={true}
          category={meal}
          key={meal.id}
          handleMealUpdate={handleMealUpdate}
          brandName={brandName}
          workingHours={workingHours}
        />
      ))}

      {/* Addon Category */}
      <InventoryCategory
        showFoodItems={true}
        showShopItems={true}
        category={addonsObj}
        isAddon={true}
        onReloadAddon={fetchAddons}
        handleMealUpdate={(val) => handleMealUpdate({ ...val, isAddon: true })}
        brandName={brandName}
        workingHours={workingHours}
      />

      <UpdateItemModal
        show={showUpdateModal}
        loading={mealUpdateLoading}
        item={currentShopItem}
        shopForm={shopForm}
        onClose={() => setShowUpdateModal(false)}
        onConfirm={updateMealItem}
        brandName={brandName}
        workingHours={workingHours}
      />
    </>
  );
});

export default withAlert()(InventoryBrand);

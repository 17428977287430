import {
  adminRoutes,
  frontdeskRoutes,
  kitchenRoutes,
  packagingRoutes,
  ROUTE_TYPE,
  shopRoutes,
  superAdminRoutes
} from '../../router';
import { ROUTE_KEYS } from '../constants';
import { AllowedRoleType } from '../types';

export type ROLE_ATTR = {
  name: string;
  baseRoute: string;
  routes: ROUTE_TYPE[];
};

export const ROLES: Record<AllowedRoleType, ROLE_ATTR> = {
  KITCHEN_STAFF: {
    name: 'kitchen',
    baseRoute: ROUTE_KEYS.KITCHEN.PENDING,
    routes: kitchenRoutes
  },
  FCB_KITCHEN_STAFF: {
    name: 'kitchen',
    baseRoute: ROUTE_KEYS.KITCHEN.PENDING,
    routes: kitchenRoutes
  },
  FRONT_DESK: {
    name: 'front desk',
    baseRoute: ROUTE_KEYS.FRONT_DESK.HOME,
    routes: frontdeskRoutes
  },
  PACKAGING: {
    name: 'packaging',
    baseRoute: ROUTE_KEYS.PACKAGING.HOME,
    routes: packagingRoutes
  },
  FCB_PACKAGING_STAFF: {
    name: 'packaging',
    baseRoute: ROUTE_KEYS.PACKAGING.HOME,
    routes: packagingRoutes
  },
  SHOP_STAFF: {
    name: 'shop',
    baseRoute: ROUTE_KEYS.SHOP.HOME,
    routes: shopRoutes
  },
  KITCHEN_ADMIN: {
    name: 'kitchen admin',
    baseRoute: ROUTE_KEYS.ADMIN.PENDING,
    routes: adminRoutes
  },
  FCB_KITCHEN_SUPERVISOR: {
    name: 'kitchen admin',
    baseRoute: ROUTE_KEYS.ADMIN.PENDING,
    routes: adminRoutes
  },
  SUPER_ADMIN: {
    name: 'admin',
    baseRoute: ROUTE_KEYS.SUPER_ADMIN.HOME,
    routes: superAdminRoutes
  },
  DEVELOPER: {
    name: 'admin',
    baseRoute: ROUTE_KEYS.SUPER_ADMIN.HOME,
    routes: superAdminRoutes
  }
};

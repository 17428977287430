import React, { FC } from 'react';
import { ENUM_LAYOUT_STATE } from '../../helpers/enums';
import NoOrder from '../empty-states/noOrder';
import NoSearchResult from '../empty-states/noSearchResult';

const LayoutState: FC<{ state: ENUM_LAYOUT_STATE; goBack: () => void }> = ({ state, goBack }) => {
  return state === ENUM_LAYOUT_STATE.WAITING ? (
    <NoOrder />
  ) : state === ENUM_LAYOUT_STATE.EMPTY_SEARCH ? (
    <NoSearchResult goBack={goBack} />
  ) : state === ENUM_LAYOUT_STATE.LOADING ? (
    <div className="text-[3rem] text-white text-center flex grow items-center justify-center">
      Loading...
    </div>
  ) : (
    <div />
  );
};

export default LayoutState;

/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from 'react';
import { STORAGE_KEYS } from '../../helpers/constants';
import { BaseModal, Button, Input } from '@cokitchen/cokitchen-components';
import { IoMdConstruct } from 'react-icons/io';
import homeStore from '../../stores/homeStore';
import { CoKitchenType, SplitScreenType } from '../../helpers/types';
import dataService from '../../services/data.service';
import { withAlert } from 'react-alert';

type ScreenInputType = {
  name: string;
  value: string;
  label: string;
};

type ScreenSettingsFormType = Record<string, string>;
const ScreenSettingsModal = ({
  show,
  onClose,
  alert
}: {
  show: boolean;
  onClose: () => void;
  alert: any;
}) => {
  const cokitchen_id = localStorage.getItem(STORAGE_KEYS.COKITCHEN);
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [error, setError] = useState('');
  const { getCokitchens } = homeStore;
  const [screenList, setScreenList] = useState([] as ScreenInputType[]);
  // eslint-disable-next-line
  const [formData, setFormData] = useState({} as ScreenSettingsFormType);

  const fetchCokitchen = async () => {
    setLoading(true);
    const cokitchens: CoKitchenType[] = await getCokitchens();
    const filteredCokitchen = cokitchens?.find((cokitchen) => cokitchen.id === cokitchen_id);
    setFormData(filteredCokitchen?.split_screen_firestore_details as ScreenSettingsFormType);
    setScreenList(
      getScreenList(filteredCokitchen?.split_screen_firestore_details as ScreenInputType)
    );
    setLoading(false);
  };

  const getScreenList = (data: SplitScreenType) => {
    if (data === undefined) return [];
    const screenList = Object?.entries(data)?.map(([key, value], i) => {
      return {
        name: key,
        value,
        label: `Screen ${i + 1}`
      };
    });
    return screenList;
  };

  const handleChange = (e: any) => {
    setError('');
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (show) fetchCokitchen();
  }, [show]);

  const updateData = async () => {
    setError('');
    setUpdateLoading(true);
    try {
      const payload = {
        split_screen_firestore_details: { ...formData },
        cokitchen_id
      };
      const res = await dataService.putAuthData('marketing/cokitchen', payload);
      if (res.status === 200) {
        alert?.show('Settings Updated successfully!', {
          type: 'success'
        });
        onClose();
      }
    } catch (error: any) {
      alert?.show(error?.data?.message, {
        type: 'success'
      });
      setError('An Error Occured, Try Again');
    }
    setUpdateLoading(false);
  };
  return (
    <BaseModal
      isOpen={show}
      onRequestClose={onClose}
      title="Screen Settings"
      titleIcon={<IoMdConstruct />}
      titleIconColor="info"
      footer={
        loading ? undefined : (
          <Button
            small
            onClick={updateData}
            color="primary"
            variant="block"
            loading={updateLoading}>
            Update
          </Button>
        )
      }>
      <div>
        <div className="space-y-2">
          {loading ? (
            <div className="h-[45px] place-content-center ">Loading...</div>
          ) : (
            screenList?.map((screen, index) => (
              <Input
                key={index}
                inputClass="h-[45px] placeholder:text-sm text-sm"
                label={screen.label}
                onChange={handleChange}
                value={Object?.values(formData)[index]}
                name={screen?.name}
                placeholder={screen.label}
                disabled={loading}
                type="number"
              />
            ))
          )}
          {error !== '' ? <div className="text-sm text-red-5 font-medium">{error}</div> : null}
        </div>
      </div>
    </BaseModal>
  );
};

export default withAlert()(ScreenSettingsModal);

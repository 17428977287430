import React, { FC, useEffect, useState } from 'react';
import './styles.scss';
import {
  BaseModal,
  Multiselect,
  MultiselectItem,
  Button,
  Input
} from '@cokitchen/cokitchen-components';
import { ReactComponent as FolderIcon } from '../../assets/images/folder-download.svg';
import { InventoryService } from '../../services/inventory.service';

import { withAlert } from 'react-alert';

interface ExportInventoryModalProps {
  show: boolean;
  options: MultiselectItem[];
  onClose: () => void;
  alert: any;
}

const ExportInventoryModal: FC<ExportInventoryModalProps> = ({ show, options, onClose, alert }) => {
  const [values, setValues] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    if (show) {
      setLoading(false);
      setValues([]);
      setEmail('');
    }
  }, [show]);

  const handleSubmitForm = async () => {
    setLoading(true);
    const brand_ids = getRealValues();
    const alertData = { type: 'success', message: '' };
    try {
      const resp = await InventoryService.exportCSV({ brand_ids, email });
      alertData.message = resp.message;
    } catch (error: any) {
      alertData.message = error.message;
      alertData.type = 'error';
    } finally {
      onClose();
      const { message, type } = alertData;
      alert?.show(message, { type });
    }
  };

  const getRealValues = () => {
    let returnVal = [...values];
    if (values[0] === 'all') {
      returnVal = options.reduce((acc: string[], { value }) => {
        if (value !== 'all') acc.push(value);
        return acc;
      }, []);
    }
    return returnVal;
  };

  return (
    <BaseModal
      isOpen={show}
      title="Export Inventory Items"
      description="The csv file containing inventory count will be sent to your inputted email. Select the brands you want to export."
      titleIcon={<FolderIcon />}
      titleIconColor="info"
      onRequestClose={onClose}
      footer={
        <Button
          color="primary"
          variant="block"
          className="mx-auto"
          loading={loading}
          disabled={email === '' || values.length === 0}
          onClick={handleSubmitForm}>
          Confirm
        </Button>
      }>
      <div className="mb-[120px] mt-2">
        <Input
          type="email"
          label="Email"
          placeholder="Enter Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mt-3 mb-4"
        />
        <label className="font-medium mb-1">Select brand</label>
        <Multiselect
          options={options}
          values={values}
          onChange={setValues}
          placeholder="Select brand"
          dropdownPlaceholder="Select all or multiple brands"
        />
      </div>
    </BaseModal>
  );
};

export default withAlert()(ExportInventoryModal);

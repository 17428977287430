import React, { FC } from 'react';
import { MealType } from '../../helpers/types';

const StickerItem: FC<{ item: MealType }> = ({ item }) => (
  <div className="flex-col flex items-start justify-end pl-3">
    <span className="text-[9px] font-bold">{item?.name}</span>
    {item.addons.map((itm, ind) => (
      <span className="text-[8px] font-medium" key={String(ind) + 'itm'}>
        {itm.meal_data?.name ?? ''}
        <span className="text-[7px]"> x {itm.quantity}</span>
      </span>
    ))}
  </div>
);

export default StickerItem;

import React, { useState, useRef } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import CardHeaderTag from '../card-header/cardHeaderTag';
import { formatPlural } from '../../helpers/functions';

const CardAccordion = ({ children, accClass, item, index, isReceipt, isLastItem }) => {
  const [setActive, setActiveState] = useState('activeAccordion');
  const [setHeight, setHeightState] = useState('$content.current.scrollHeight}px');
  const [setRotate, setRotateState] = useState('accordion__icon rotate');

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'activeAccordion' : '');
    setHeightState(setActive === 'activeAccordion' ? '0px' : `${content.current.scrollHeight}px`);
    setRotateState(setActive === 'activeAccordion' ? 'accordion__icon' : 'accordion__icon rotate');
  }

  const getNumberOfBrands = () => {
    const meals = item.originalMeals ?? item.meals;
    return formatPlural(meals?.length, 'brand');
  };

  return (
    <div
      className={`accordion__section ${
        isReceipt ? 'mb-4' : !isLastItem ? 'border-b border-[#ededed]' : ''
      }`}>
      <div
        className={`${
          !isReceipt ? 'border-b border-[#ededed] py-4' : ''
        } flex items-center justify-between active-accordion-head`}>
        <div className="w-full grow flex justify-between items-center">
          <h6 className=" pointer font-medium text-[16px] leading-[20px] my-2">
            {`${index + 2}. ${item?.user?.first_name} ${item?.user?.last_name}`}
            {item?.firstorder && <CardHeaderTag type="new" />}
          </h6>
          <p className={`${isReceipt ? '' : 'text-[#595959] mb-0 leading-none'} pr-2`}>
            {getNumberOfBrands()}
          </p>
        </div>
        {!isReceipt ? (
          <button
            style={{
              background: 'white',
              borderRadius: '50%',
              width: 15,
              height: 15
            }}
            className={`flex justify-center items-center accordion ${setActive} ${setRotate} ${accClass}`}
            onClick={toggleAccordion}>
            <FiChevronDown size={20} />
          </button>
        ) : null}
      </div>

      <div ref={content} style={{ maxHeight: `${setHeight}` }} className="accordion__content">
        {children}
      </div>
    </div>
  );
};

export default CardAccordion;

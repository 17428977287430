import React from 'react';

const CardHeaderDelivery = ({ box_number }) => {
  return (
    <div className="text-white text-lg text-center mt-[-12px]">
      {box_number}
    </div>
  );
};

export default CardHeaderDelivery;

import React, { FC } from 'react';
import { LayoutProvider } from '../../contexts/layout.context';
import Dispatch from '../../pages/dispatch';

const DispatchOrdersLayout: FC = () => (
  <LayoutProvider>
    <Dispatch />
  </LayoutProvider>
);

export default DispatchOrdersLayout;

const getImageUrl = () =>
  `https://d3u7b9fq2opvwp.cloudfront.net/upload-service/9e564b74-6bfc-495e-a7be-366db5e51c81:The_Earth_seen_from_Apollo_17.jpg.jpg?n=${Math.random()}`;
const DOWNLOAD_SIZE = 130000; // 130kb

export const BYTES_IN_KILOBYTE = 1024;

export type NetworkResType = { value: string; units: string };

export enum ENUM_NETWORK_STRENGTH {
  EXCELLENT = 'excellent',
  GOOD = 'good',
  AVERAGE = 'average',
  BAD = 'bad',
  VERY_BAD = 'very-bad'
}

export const formatNetworkSpeed = (speed: number, roundedDecimals = 2): NetworkResType => {
  const bitsPerSecond = speed.toFixed(roundedDecimals);
  const KBps = (+bitsPerSecond / BYTES_IN_KILOBYTE).toFixed(roundedDecimals);

  if (+KBps <= 1) return { value: bitsPerSecond, units: 'Bps' };

  const MBps = (+KBps / BYTES_IN_KILOBYTE).toFixed(roundedDecimals);

  if (+MBps <= 1) return { value: KBps, units: 'KBps' };
  else return { value: MBps, units: 'MBps' };
};

export class NetworkClass {
  startTime = 0;
  endTime = 0;

  showResults() {
    const duration = (this.endTime - this.startTime) / 1000;
    const bitsLoaded = DOWNLOAD_SIZE * 8;
    return bitsLoaded / duration;
  }

  startTest(): Promise<number> {
    return new Promise((resolve) => {
      const download = new Image();

      download.onload = () => {
        this.endTime = new Date().getTime();
        const res = this.showResults();
        resolve(res);
      };

      download.onerror = () => {
        resolve(0);
      };

      this.startTime = new Date().getTime();
      this.endTime = 0;
      download.src = getImageUrl();
    });
  }
}

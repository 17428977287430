import React, { FC } from 'react';
import { LayoutProvider } from '../../contexts/layout.context';
import Recall from '../../pages/recall';

const RecallOrdersLayout: FC = () => (
  <LayoutProvider>
    <Recall />
  </LayoutProvider>
);

export default RecallOrdersLayout;

import { BaseModal, Button, Input, Multiselect, Switch } from '@cokitchen/cokitchen-components';
import React, { useEffect, useState } from 'react';
import { ReactComponent as PenIcon } from '../../../assets/icons/pen.svg';
import dataService from '../../../services/data.service';
import homeStore from '../../../stores/homeStore';
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete-icon.svg';
import DeleteScreenModal from './delete-screen-modal';
import { BrandType } from '../../../helpers/types';
import useNetworkContext from '../../../contexts/network.context';

export type FormDataType = {
  id?: string;
  name: string;
  active: boolean;
  brand_ids: string[];
  brands?: BrandType[];
};

type Props = {
  data: FormDataType;
  isOpen: boolean;
  onClose: () => void;
  type: 'edit' | 'create';
  refreshData?: () => void;
  onEditSuccess?: (arg0: FormDataType) => void;
  onDeleteSuccess?: (arg0: string) => void;
};

const CreateScreenModal = ({
  isOpen,
  onClose,
  type,
  data,
  refreshData,
  onEditSuccess,
  onDeleteSuccess
}: Props) => {
  const { headerBrands } = homeStore;
  const { disableNetworkActions } = useNetworkContext();
  const [loading, setLoading] = useState(false);
  const cokitchen_id = localStorage.getItem('cokitchen');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [brandOptions, setBrandOptions] = useState([] as any);
  const [values, setValues] = useState<string[]>([]);
  const [formData, setFormData] = useState<FormDataType>(data);

  useEffect(() => {
    const optionsArr: Array<{ value: string; text: string } | null> | null = [];
    headerBrands.forEach((brand) => {
      optionsArr?.push({
        value: brand.id,
        text: brand.name
      });
    });
    setBrandOptions(optionsArr);
  }, [headerBrands]);
  useEffect(() => {
    setFormData(data);
  }, [data]);
  useEffect(() => {
    setFormData({ ...formData, brand_ids: values });
  }, [values]);
  useEffect(() => {
    setValues(formData?.brand_ids);
  }, []);
  const createScreen = async () => {
    setLoading(true);
    const payload = { ...formData, cokitchen_id };
    try {
      await dataService.postAuthData('kitchen/screens', payload);
      refreshData?.();
      onClose();
    } catch (error: any) {
      console.log('error');
    }
    setLoading(false);
  };
  const editScreen = async () => {
    setLoading(true);
    const payload = {
      cokitchen_id,
      active: formData.active,
      brand_ids: formData.brand_ids,
      name: formData.name
    };
    try {
      await dataService.putAuthData(`kitchen/screens/${data?.id ?? ''}`, payload);

      const editedScreen: FormDataType = {
        ...payload,
        brands: (headerBrands as BrandType[]).filter((item) => payload.brand_ids.includes(item.id)),
        id: data.id
      };
      onEditSuccess?.(editedScreen);
      onClose();
    } catch (error: any) {
      console.log('error');
    }
    setLoading(false);
  };
  const onConfirm = async () => {
    type === 'create' ? await createScreen() : await editScreen();
    setValues([]);
  };
  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={type === 'create' ? 'Create Screen' : 'Edit Screen'}
      titleIcon={<PenIcon />}
      titleIconColor="info">
      <Input
        placeholder="1"
        className="min-w-full text-[#A3A3A3] mb-6"
        type="number"
        value={formData.name}
        label="Name of screen"
        onChange={(e: any) => setFormData({ ...formData, name: e.target.value })}
      />
      <div className="w-full mb-8 text-black">
        <p className="text-sm font-medium mb-[6px]">Brands selection</p>
        <Multiselect
          options={brandOptions}
          values={values}
          onChange={setValues}
          placeholder="Select brand"
          dropdownPlaceholder="Select all or multiple brands"
        />
      </div>
      <div className="flex bg-[#F9F6F6] rounded-md border-[0.5px] border-[#EEECEC] pl-5 py-4 mb-4">
        <Switch
          active={formData?.active}
          onChange={(e: boolean) => setFormData({ ...formData, active: e })}
          label="Active"
          small
          labelPosition="right"
        />
      </div>
      {type === 'edit' && (
        <div
          onClick={() => setShowDeleteModal(true)}
          className="text-[#E60A2B] flex gap-3 items-center font-medium cursor-pointer mb-4">
          <DeleteIcon /> Delete screen
        </div>
      )}
      <div className="border-t border-t-[#EBEBEB] w-[103%] ml-[-1rem] pl-2 pt-[24px] flex justify-between">
        <Button variant="outline" color="primary" className="mr-4 h-[49px]" onClick={onClose}>
          Cancel
        </Button>
        <Button
          loading={loading}
          onClick={onConfirm}
          variant="block"
          color="primary"
          disabled={formData.name === '' || values.length === 0 || disableNetworkActions}
          className="h-[49px]">
          {type === 'edit' ? 'Edit Screen ' : 'Create Screen'}
        </Button>
      </div>
      <DeleteScreenModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        data={data}
        onDelete={() => {
          onDeleteSuccess?.(data.id ?? '');
          onClose();
        }}
      />
    </BaseModal>
  );
};

export default CreateScreenModal;

import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import { ENUM_ITEM_TYPES, ENUM_STATUS } from '../enums';
import { OrderType } from '../types';

const getKitchenStatus = (order: OrderType, item_type: ENUM_ITEM_TYPES): ENUM_STATUS => {
  return !order.kitchen_accepted
    ? ENUM_STATUS.PENDING
    : order.kitchen_prepared
    ? ENUM_STATUS.READY
    : ENUM_STATUS.STARTED;
};

const getFrontDeskStatus = (order: OrderType, item_type: ENUM_ITEM_TYPES): ENUM_STATUS => {
  const kitchen_prepared =
    order.calculated_order.item_type === ENUM_ITEM_TYPES.FOOD && order.kitchen_prepared;
  const shop_prepared =
    order.calculated_order.item_type === ENUM_ITEM_TYPES.SHOP && order.shop_prepared;
  const all_prepared =
    order.calculated_order.item_type === ENUM_ITEM_TYPES.ALL &&
    order.kitchen_prepared &&
    order.shop_prepared;
  return !order.kitchen_accepted && !order.shop_accepted
    ? ENUM_STATUS.PENDING
    : kitchen_prepared || shop_prepared || all_prepared
    ? ENUM_STATUS.READY
    : ENUM_STATUS.STARTED;
};

const getShopStatus = (order: OrderType, item_type: ENUM_ITEM_TYPES): ENUM_STATUS => {
  return !order.shop_accepted
    ? ENUM_STATUS.PENDING
    : order.shop_prepared
    ? ENUM_STATUS.READY
    : ENUM_STATUS.STARTED;
};

const getAdminStatus = (order: OrderType, item_type: ENUM_ITEM_TYPES): ENUM_STATUS => {
  const fn = item_type === ENUM_ITEM_TYPES.SHOP ? getShopStatus : getKitchenStatus;
  const status = fn(order, item_type);
  return status === ENUM_STATUS.STARTED ? ENUM_STATUS.PACKAGING : status;
};

const getPackagingStatus = (order: OrderType, item_type: ENUM_ITEM_TYPES): ENUM_STATUS => {
  const status = getKitchenStatus(order, item_type);
  return status === ENUM_STATUS.STARTED ? ENUM_STATUS.PACKAGING : status;
};

const LiveStatusConfig: Record<
  ENUM_KITCHEN_ROLE,
  (order: OrderType, item_type: ENUM_ITEM_TYPES) => ENUM_STATUS
> = {
  KITCHEN_STAFF: getKitchenStatus,
  FCB_KITCHEN_STAFF: getKitchenStatus,
  FRONT_DESK: getFrontDeskStatus,
  PACKAGING: getPackagingStatus,
  FCB_PACKAGING_STAFF: getPackagingStatus,
  SHOP_STAFF: getShopStatus,
  KITCHEN_ADMIN: getAdminStatus,
  FCB_KITCHEN_SUPERVISOR: getAdminStatus,
  SUPER_ADMIN: getAdminStatus,
  DEVELOPER: getAdminStatus,
  ADMIN: getAdminStatus
};

export { LiveStatusConfig };

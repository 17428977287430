import React from 'react';
import { useHistory } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { observer } from 'mobx-react-lite';
import { ROLES } from '../../helpers/role-config';
import Logo from '../../assets/images/FoodCourt.png';
import { Login as NewLogin } from '@cokitchen/cokitchen-auth';
import { LoginResponse } from '@cokitchen/cokitchen-auth/dist/lib/login/types';
import { AllowedRoleType } from '../../helpers/types';

const Login = observer(function Login({ alert }: { alert: any }) {
  const history = useHistory();

  const handleLoginSuccess = (res: LoginResponse) => {
    const role = res.role as AllowedRoleType;
    if (Object.keys(ROLES).includes(role)) {
      const roleConfig = ROLES[role];
      history.push(roleConfig.baseRoute);
    } else {
      alert.show('Unrecognized login role!', {
        type: 'info'
      });
      localStorage.clear();
    }
  };
  return (
    <div className="auth-login__main min-h-screen bg-white px-0 md:px-16 pt-10">
      <div className="flex justify-center bg-white text-center">
        <img src={Logo} className="login__logo" alt="Food Court" />
      </div>

      <main className="auth-login__card">
        <NewLogin
          dashboard="kitchen"
          onLogin={handleLoginSuccess}
          API_URL={process.env.REACT_APP_BETA_API_URL}
        />
      </main>
    </div>
  );
});
export default withAlert()(Login);

import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import './styles.scss';
import { withAlert } from 'react-alert';
import db from '../../services/firebase.config';
import { observer } from 'mobx-react';
import commonStore from '../../stores/commonStore';
import InventoryBrand from '../../components/inventory-brand';
import Layout from '../../components/layout';
import ExportInventoryModal from '../../components/modals/exportInventoryModal';
import { useLocation } from 'react-router';
import { ROUTE_KEYS, STORAGE_KEYS } from '../../helpers/constants';
import Tooltip from '../../components/tooltip';
import { ReactComponent as InventoryIcon } from '../../assets/images/export-inventory.svg';
import { ENUM_ITEM_TYPES, ENUM_LAYOUT_STATE } from '../../helpers/enums';
import useLayoutContext from '../../contexts/layout.context';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
const FOOD_SHOWING_LOCATIONS = [ROUTE_KEYS.ADMIN.INVENTORY, ROUTE_KEYS.SUPER_ADMIN.INVENTORY];
const SHOP_SHOWING_LOCATIONS = [
  ROUTE_KEYS.ADMIN.INVENTORY,
  ROUTE_KEYS.SHOP.INVENTORY,
  ROUTE_KEYS.SUPER_ADMIN.INVENTORY
];

const COKITCHEN_ID = localStorage.getItem(STORAGE_KEYS.COKITCHEN);

const ShopMenuManagement = observer(function ShopMenuManagement() {
  const location = useLocation();
  const { deliveryType, searchValue, setSearchValue } = useLayoutContext();
  const { allBrandsArray, setAllBrandsArray, setMealFilterValue } = commonStore;
  const [brandsArray, setBrandsArray] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [workHours, setWorkHours] = useState([]);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);

  useEffect(async () => {
    setLoading(true);
    await loadData();
  }, []);

  useEffect(() => {
    const filtersArr = [];
    brandsArray
      .filter((brand) => brand?.meal_categories.length > 0)
      ?.forEach((brand, index) => {
        if (index === 0 && selectedBrand === '') onFilterClick({ key: brand.id });
        filtersArr.push({
          key: brand.id,
          title: brand.name,
          number: brand.meal_categories.length,
          active: brand.id === selectedBrand,
          deliveryType: brand.brand_type
        });
      });
    setFilters(filtersArr);
    setFilteredBrands(
      brandsArray?.filter((item) => item.id === selectedBrand && item.meal_categories.length > 0)
    );
  }, [selectedBrand, brandsArray, deliveryType]);
  useEffect(() => {
    const optionsArr = [{ text: 'All', value: 'all' }];
    allBrandsArray.forEach((brand) => {
      optionsArr.push({
        value: brand.id,
        text: brand.name
      });
    });
    setBrandOptions(optionsArr);
  }, [allBrandsArray]);

  const reduceMealCategories = (acc, { name, ...others }) => {
    const lowerName = name?.toLowerCase();
    if (lowerName.includes(searchValue.toLowerCase())) acc.push({ name, ...others });
    return acc;
  };

  const reduceBrandArray = (acc, { meal_categories: categories, ...brand }) => {
    const meal_categories = categories.reduce(reduceMealCategories, []);
    if (meal_categories.length > 0) acc.push({ ...brand, meal_categories });
    return acc;
  };

  useEffect(() => {
    if (searchValue === '') {
      setBrandsArray([...allBrandsArray]);
      return;
    }
    const matches = allBrandsArray.reduce(reduceBrandArray, []);
    setBrandsArray(matches);
  }, [searchValue]);

  const onFilterClick = (filter) => {
    setMealFilterValue(filter.key);
    setSelectedBrand(filter.key);
    setFilteredBrands([]);
  };

  const loadData = async () => {
    let allBrands = [];
    const showing_item_types = [];
    if (FOOD_SHOWING_LOCATIONS.includes(location.pathname)) {
      showing_item_types.push(ENUM_ITEM_TYPES.FOOD);
    }
    if (SHOP_SHOWING_LOCATIONS.includes(location.pathname)) {
      showing_item_types.push(ENUM_ITEM_TYPES.SHOP);
    }
    await db.collection('brands').onSnapshot(
      async (items) => {
        allBrands = [];
        await items.forEach((doc) => {
          if (showing_item_types.includes(doc.data().item_type)) {
            const data = doc.data();
            data.meal_categories = JSON.parse(data?.meal_categories || '[]');
            allBrands.push(data);
          }
        });
        const filtered = filterItemType(allBrands);

        const filtered_by_cokitchen = filtered?.filter(
          (item) => item.cokitchen_id === COKITCHEN_ID
        );
        setBrandsArray(filtered_by_cokitchen);
        setAllBrandsArray([...filtered_by_cokitchen]);
        setLoading(false);
      },
      (err) => {
        console.error(err);
        setLoading(false);
      }
    );
  };
  const filterItemType = (brands) => {
    if (role === ENUM_KITCHEN_ROLE.SHOP_STAFF) {
      return brands.filter(({ item_type }) => item_type === ENUM_ITEM_TYPES.SHOP);
    }
    return brands.filter(({ item_type }) => item_type === ENUM_ITEM_TYPES.FOOD);
  };

  const getLayoutState = () => {
    if (loading) return ENUM_LAYOUT_STATE.LOADING;
    return searchValue !== '' && filteredBrands.length === 0
      ? ENUM_LAYOUT_STATE.EMPTY_SEARCH
      : ENUM_LAYOUT_STATE.PAGE;
  };

  useEffect(() => {
    const workingHours = filteredBrands?.[0]?.brand_working_hours?.map((item) => {
      return {
        day: item.name,
        opening_time: item.opening_time
      };
    });
    setWorkHours(workingHours);
  }, [filteredBrands]);
  return (
    <Layout
      pendingOrders={'0'}
      trackingOrders={'0'}
      isInventory
      searchValue={searchValue}
      searchChange={(e) => {
        setSearchValue(e.target.value);
      }}
      filters={
        deliveryType ? filters.filter((brand) => brand.deliveryType === deliveryType) : filters
      }
      onFilterClick={onFilterClick}
      searchPlaceholder="Search meals through all brands"
      layoutState={getLayoutState()}>
      <Fragment>
        {filteredBrands?.map((brand) => (
          <InventoryBrand
            key={brand?.id}
            mealCategories={brand?.meal_categories}
            onReloadData={loadData}
            brandId={brand.id}
            brandName={brand.name}
            workingHours={workHours}
          />
        ))}

        <div>
          <div className="fixed bottom-[11rem] right-[3rem] ">
            <Tooltip show={showTooltip} title="Export Inventory">
              Download inventory to view items you have in stock. lorem ipssum dulom, dummy text to
              fill space later.
            </Tooltip>
          </div>
          <div
            onMouseOver={() => setShowTooltip(true)}
            onMouseOut={() => setShowTooltip(false)}
            onClick={() => setShowExportModal(true)}
            className="fixed bottom-[3rem] right-[3rem]">
            <InventoryIcon />
          </div>
        </div>
        <ExportInventoryModal
          show={showExportModal}
          onClose={() => setShowExportModal(false)}
          options={brandOptions}
          alert={alert}
        />
      </Fragment>
    </Layout>
  );
});
export default withRouter(withAlert()(ShopMenuManagement));

import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_ITEM_TYPES } from '../../helpers/enums';
import { OrderType } from '../../helpers/types';
import { OrderService } from '../../services/order.service';

export const apiLiveMapping: Partial<Record<ENUM_KITCHEN_ROLE, () => Promise<any>>> = {
  KITCHEN_STAFF: OrderService.getKitchenPreparedOrders,
  PACKAGING: OrderService.getRecallPackingOrders,
  SHOP_STAFF: OrderService.getShopPreparedOrders,
  KITCHEN_ADMIN: OrderService.getKitchenPreparedOrders,
  SUPER_ADMIN: OrderService.getPreparedOrders
};

/* eslint-disable */
export const checkOrderValidityForRole = (order: OrderType): boolean | undefined => {
  if (!order) return false;
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
  const conditionMap: Partial<Record<ENUM_KITCHEN_ROLE, (order: OrderType) => boolean>> = {
    SHOP_STAFF: getShopCondition,
    PACKAGING: getPackagingCondition,
    SUPER_ADMIN: getAdminCondition,
    KITCHEN_ADMIN: getAdminCondition,
    KITCHEN_STAFF: getKitchenCondition
  };

  const fn = conditionMap[role];
  if (fn) return fn(order);
};

// kitchen live order
const getKitchenCondition = (order: OrderType): boolean => {
  return (
    [ENUM_ITEM_TYPES.FOOD, ENUM_ITEM_TYPES.ALL].includes(order.calculated_order.item_type) &&
    order.kitchen_prepared &&
    !order.kitchen_dispatched
  );
};

// Shop orders
const getShopCondition = (order: OrderType): boolean => {
  return (
    [ENUM_ITEM_TYPES.SHOP, ENUM_ITEM_TYPES.ALL].includes(order.calculated_order.item_type) &&
    order.shop_prepared &&
    !order.kitchen_dispatched
  );
};

const getPackagingCondition = (order: OrderType): boolean => {
  const staffNumber = localStorage.getItem(STORAGE_KEYS.STAFF_NUMBER);
  const cokitchenId = localStorage.getItem(STORAGE_KEYS.COKITCHEN);
  const packagingGroupKey = `${cokitchenId}_screen_${staffNumber}`;
  if (
    staffNumber !== 'null' &&
    order.split_screen_firestore_details_packaging_doc_id != packagingGroupKey
  ) {
    return false;
  }
  return getKitchenCondition(order);
};

const getAdminCondition = (order: OrderType): boolean => {
  const kitcenOrders = getKitchenCondition(order);
  const shopOrders = getShopCondition(order);

  return kitcenOrders || shopOrders;
};
/* eslint-enable */

import dataService from './data.service';

export const InventoryService = {
  exportCSV: async (payload: {
    brand_ids: string[];
    email: string;
  }): Promise<{ status: string; message: string }> => {
    const data = await dataService.postInternalData('inventory/order-stats-csv', payload);
    // eslint-disable-next-line
    return data.data;
  },

  getBrandAddons: async (brandId: string): Promise<{ status: string; message: string }> => {
    const data = await dataService.getInternalData(`brand/addons/${brandId}`);
    // eslint-disable-next-line
    return data.data;
  }
};

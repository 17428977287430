import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Dropdown, DropdownItem, DropdownList } from '@cokitchen/cokitchen-components';
import { FilterAltIcon, SearchIcon } from 'cokitchen-icons';

type Props = {
  query: {
    filter: string;
    search: string;
    onFilterChange: (filter: string) => void;
    onSearchChange: (search: string) => void;
  };
};

const PackagingScreenSearchFilter = (props: Props) => {
  const { filter, onFilterChange, onSearchChange } = props.query;
  const [searchValue, setSearchValue] = useState('');
  const options = [
    { value: '', text: 'All' },
    { value: 'true', text: 'Active' },
    { value: 'false', text: 'Deactivated' }
  ];

  useEffect(() => {
    const timeoutId = setTimeout(() => onSearchChange(searchValue), 500);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  return (
    <div className='packaging_screen__search_w_filter'>
      <SearchIcon className='[&_path]:stroke-[#96999C]' />
      <input
        placeholder='Search'
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        className='border-none outline-none'
      />
      <Dropdown
        content={
          <span className='w-8 h-8 flex items-center justify-center cursor-pointer'>
            <FilterAltIcon className='[&_path]:fill-[#96999C]' />
          </span>
        }
        className='ml-auto'>
        <DropdownList className='flex flex-col gap-1'>
          {options.map((option) => (
            <DropdownItem
              key={option.value}
              buttonClassName={filter === option.value ? 'bg-[#f5f5f5]' : ''}
              onClick={() => onFilterChange(option.value)}>
              {option.text}
            </DropdownItem>
          ))}
        </DropdownList>
      </Dropdown>
    </div>
  );
};

export default PackagingScreenSearchFilter;

import { STORAGE_KEYS } from '../helpers/constants';
import { DISPATCH_ORDER_URL, RECALL_ORDER_URL, SET_PAID_ORDER_URL } from '../helpers/apiUrls';
import dataService from './data.service';

export const OrderService = {
  getKitchenOrders: async (query, token = '') => {
    const data = await dataService.getInternalData('orders/kitchen/active', query, token);
    return data.data;
  },
  getFrontdeskCurrentOrders: async () => {
    const data = await dataService.getInternalData('orders/frondesk/current');
    return data.data;
  },
  getFrontdeskCurrentUnpaidOrders: async (query) => {
    const data = await dataService.getInternalData('orders/frondesk/unpaid', query);
    return data.data;
  },
  getFrontdeskDispatchOrders: async (query) => {
    const data = await dataService.getInternalData('orders/frondesk/dispatch', query);
    return data.data;
  },
  getKitchenSummary: async () => {
    const data = await dataService.getInternalData('orders/kitchen/summary');
    return data.data;
  },
  getRecallPackingOrders: async (query) => {
    const staffNumber = localStorage.getItem(STORAGE_KEYS.STAFF_NUMBER);
    let data;
    if (staffNumber !== 'null') {
      data = await dataService.getInternalData('orders/packaging/split/recall', query);
    } else {
      data = await dataService.getInternalData('orders/kitchen/prepared', query);
    }
    return data.data;
  },
  getKitchenPreparedOrders: async (query) => {
    const data = await dataService.getInternalData('orders/kitchen/prepared', query);
    return data.data;
  },
  getShopPreparedOrders: async (query) => {
    const data = await dataService.getInternalData('orders/shop/prepared', query);
    return data.data;
  },
  getPreparedOrders: async (query) => {
    const data = await dataService.getInternalData('orders/admin/prepared', query);
    return data.data;
  },
  getCurrentPackingOrders: async (query) => {
    const staffNumber = localStorage.getItem(STORAGE_KEYS.STAFF_NUMBER);
    const packagingScreenDetails = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.PACKAGING_SCREEN_DETAILS)
    );
    const splitScreenDetails = JSON.parse(localStorage.getItem(STORAGE_KEYS.SCREEN_DETAILS));
    const isBrandPackagingScreen = localStorage.getItem(STORAGE_KEYS.IS_BRAND_PACKAGING_SCREEN);
    const isBrandSplitScreen = localStorage.getItem(STORAGE_KEYS.IS_BRAND_SPLIT_SCREEN);
    if (packagingScreenDetails && isBrandPackagingScreen === 'true' && staffNumber !== 'null') {
      const data = await dataService.getInternalData(
        `orders/packaging-screen/current/${packagingScreenDetails?.id}?test=${query.test}`
      );
      const orders = data.data.data.orders;
      return { data: orders };
    }
    let data;
    if (splitScreenDetails && isBrandSplitScreen === 'true' && staffNumber !== 'null') {
      data = await dataService.getInternalData('orders/packaging/split/current', query);
    } else {
      data = await dataService.getInternalData('orders/packaging/current', query);
    }
    return data.data;
  },
  getShopLiveOrders: async () => {
    const data = await dataService.getInternalData('orders/shop/active');
    return data.data;
  },
  getAdminLiveOrders: async (query, token = '') => {
    const data = await dataService.getInternalData('orders/admin/active', query, token);
    return data.data;
  },
  startKitchenOrder: async (order_id) => {
    const data = await dataService.postInternalData(`kitchen/order/start/${order_id}`, {});
    return data.data;
  },
  endKitchenOrder: async (order_id) => {
    const data = await dataService.postInternalData(`kitchen/order/finish/${order_id}`, {});
    return data.data;
  },
  startShopOrder: async (order_id) => {
    const data = await dataService.postInternalData(`shop/order/start/${order_id}`, {});
    return data.data;
  },
  endShopOrder: async (order_id) => {
    const data = await dataService.postInternalData(`shop/order/finish/${order_id}`, {});
    return data.data;
  },
  confirmUnpaidOrder: async (order_id) => {
    const data = await dataService.postInternalData(`${SET_PAID_ORDER_URL}${order_id}`, {});
    return data.data;
  },
  recallShopOrder: async (order_id) => {
    const data = await dataService.postInternalData(`shop/order/recall/${order_id}`, {});
    return data.data;
  },
  recallKitchenOrder: async (order_id) => {
    const data = await dataService.postInternalData(`${RECALL_ORDER_URL}${order_id}`, {});
    return data.data;
  },
  dispatchOrder: async (order_id) => {
    const data = await dataService.postInternalData(`${DISPATCH_ORDER_URL}${order_id}`, {});
    return data.data;
  },
  frontDeskCompleteOrder: async (order_id) => {
    const data = await dataService.postInternalData('logistics/order/complete', { order_id });
    return data.data;
  },
  getKitchenBrands: async () => {
    const data = await dataService.getInternalData('brands/kitchen');
    return data.data;
  },
  getScheduledOrders: async (query) => {
    const data = await dataService.getInternalData('scheduled/order', query);
    return data.data;
  },
  getPreorderedOrders: async (query) => {
    const data = await dataService.getInternalData('preorders', query, undefined);
    return data.data;
  }
};

import { FILTER_KEYS, ROUTE_KEYS } from '../../helpers/constants';
import { OrderType } from '../../helpers/types';

export type FilterType = {
  active: boolean;
  key: string;
  title: string;
  number?: number;
  condition?: (args: OrderType, filter: string) => boolean;
};

const orderTypeFilter = (order: OrderType, filterText: string): boolean => {
  const correctItemType = ['ALL', filterText].includes(order.calculated_order?.item_type);
  return correctItemType;
};

const filterFoodActiveOrders = (order: OrderType, filterText: string): boolean => {
  const correctItemType = ['ALL', filterText].includes(order.calculated_order?.item_type);
  const correctStatus = filterText === 'SHOP' ? !order.shop_prepared : !order.kitchen_prepared;
  return correctItemType && correctStatus;
};

const filterPreparedOrders = (order: OrderType, filterText: string): boolean => {
  const correctItemType = ['ALL', filterText].includes(order.calculated_order?.item_type);
  const correctStatus = filterText === 'SHOP' ? order.shop_prepared : order.kitchen_prepared;
  return correctItemType && correctStatus;
};

const filterPreOrderedOrders = (order: OrderType, filterText: string): boolean => {
  return order.preorder;
};

const filterNonPreOrderedOrders = (order: OrderType, filterText: string): boolean => {
  return !order.preorder;
};

const allFilter = (order: OrderType, filter: string): boolean => true;
// eslint-disable-next-line
const pickupFilter = (order: OrderType, filterText: string): boolean =>
  order.calculated_order.pickup;
const deliveryFilter = (order: OrderType, filterText: string): boolean =>
  !order.calculated_order.pickup;

export const FILTERS_BY_ROUTE: Partial<Record<string, FilterType[]>> = {
  [ROUTE_KEYS.ADMIN.HOME]: [
    {
      active: false,
      key: FILTER_KEYS.FOOD,
      title: 'Kitchen',
      number: 0,
      condition: filterFoodActiveOrders
    },
    {
      active: false,
      key: FILTER_KEYS.SHOP,
      title: 'Shop',
      number: 0,
      condition: filterFoodActiveOrders
    }
  ],
  [ROUTE_KEYS.ADMIN.RECALL]: [
    {
      active: false,
      key: FILTER_KEYS.FOOD,
      title: 'Kitchen',
      number: 0,
      condition: filterPreparedOrders
    },
    {
      active: false,
      key: FILTER_KEYS.SHOP,
      title: 'Shop',
      number: 0,
      condition: filterPreparedOrders
    }
  ],
  [ROUTE_KEYS.ADMIN.DISPATCH]: [
    { active: false, key: FILTER_KEYS.ALL, title: 'All', number: 0, condition: allFilter },
    { active: false, key: FILTER_KEYS.PICKUP, title: 'Pickup', number: 0, condition: pickupFilter },
    {
      active: false,
      key: FILTER_KEYS.DELIVERY,
      title: 'Delivery',
      number: 0,
      condition: deliveryFilter
    }
  ],
  [ROUTE_KEYS.SUPER_ADMIN.HOME]: [
    {
      active: false,
      key: FILTER_KEYS.FOOD,
      title: 'Kitchen',
      number: 0,
      condition: filterFoodActiveOrders
    },
    {
      active: false,
      key: FILTER_KEYS.SHOP,
      title: 'Shop',
      number: 0,
      condition: filterFoodActiveOrders
    }
  ],
  [ROUTE_KEYS.SUPER_ADMIN.RECALL]: [
    {
      active: false,
      key: FILTER_KEYS.FOOD,
      title: 'Kitchen',
      number: 0,
      condition: filterPreparedOrders
    },
    {
      active: false,
      key: FILTER_KEYS.SHOP,
      title: 'Shop',
      number: 0,
      condition: filterPreparedOrders
    }
  ],
  [ROUTE_KEYS.SUPER_ADMIN.DISPATCH]: [
    { active: false, key: FILTER_KEYS.ALL, title: 'All', number: 0, condition: allFilter },
    { active: false, key: FILTER_KEYS.PICKUP, title: 'Pickup', number: 0, condition: pickupFilter },
    {
      active: false,
      key: FILTER_KEYS.DELIVERY,
      title: 'Delivery',
      number: 0,
      condition: deliveryFilter
    }
  ],
  [ROUTE_KEYS.FRONT_DESK.HOME]: [
    { active: false, key: FILTER_KEYS.PICKUP, title: 'Pickup', number: 0, condition: pickupFilter },
    {
      active: false,
      key: FILTER_KEYS.DELIVERY,
      title: 'Delivery',
      number: 0,
      condition: deliveryFilter
    }
  ],
  [ROUTE_KEYS.FRONT_DESK.DISPATCH]: [
    { active: false, key: FILTER_KEYS.ALL, title: 'All', number: 0, condition: allFilter },
    { active: false, key: FILTER_KEYS.PICKUP, title: 'Pickup', number: 0, condition: pickupFilter },
    {
      active: false,
      key: FILTER_KEYS.DELIVERY,
      title: 'Delivery',
      number: 0,
      condition: deliveryFilter
    }
  ],
  [ROUTE_KEYS.FRONT_DESK.UNPAID]: [
    { active: false, key: FILTER_KEYS.ALL, title: 'All', number: 0, condition: allFilter },
    { active: false, key: FILTER_KEYS.FOOD, title: 'Food', number: 0, condition: orderTypeFilter },
    { active: false, key: FILTER_KEYS.SHOP, title: 'Shop', number: 0, condition: orderTypeFilter }
  ],
  [ROUTE_KEYS.KITCHEN.HOME]: [
    { active: false, key: FILTER_KEYS.ALL, title: 'All', number: 0, condition: allFilter },
    { active: false, key: FILTER_KEYS.PINNED, title: 'Pinned', number: 0, condition: allFilter }
  ],
  [ROUTE_KEYS.PACKAGING.HOME]: [
    { active: false, key: FILTER_KEYS.ALL, title: 'All', number: 0, condition: allFilter },
    {
      active: false,
      key: FILTER_KEYS.PREORDER,
      title: 'Pre-Order',
      number: 0,
      condition: filterPreOrderedOrders
    },
    {
      active: false,
      key: FILTER_KEYS.REGULAR,
      title: 'Regular',
      number: 0,
      condition: filterNonPreOrderedOrders
    }
  ]
};

import React, { useRef } from 'react';
import { Button } from '@cokitchen/cokitchen-components';
import { ReactComponent as ClockIcon } from '../../assets/images/clock-icon.svg';
import { ReactComponent as EmptyClockIcon } from '../../assets/images/empty-clock.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_STATUS } from '../../helpers/enums';
import Timer from '../timer';
import DocketButton from './print-docket';
import ReactToPrint from 'react-to-print';
import OrderSticker from '../card-header/order-sticker';
import { isOrderReady } from '../../helpers/functions';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import useNetworkContext from '../../contexts/network.context';
const CardFooter = ({
  status,
  setShowRejectModal,
  order,
  time,
  reason,
  riderAssigned,
  receiptRef,
  printStickerMeals
}) => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const { disableNetworkActions } = useNetworkContext();
  const showRejectButton =
    status === ENUM_STATUS.PENDING &&
    [
      ENUM_KITCHEN_ROLE.KITCHEN_ADMIN,
      ENUM_KITCHEN_ROLE.KITCHEN_STAFF,
      ENUM_KITCHEN_ROLE.SUPER_ADMIN,
      ENUM_KITCHEN_ROLE.FRONT_DESK
    ].includes(role);
  const stickerRef = useRef(null);
  const showRiderInfo = status === ENUM_STATUS.READY || status === ENUM_STATUS.DISPATCH;
  const isReady = () => {
    return role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF && isOrderReady(order);
  };

  return (
    status !== ENUM_STATUS.DISPATCH && (
      <div className="mt-6">
        {showRejectButton ? (
          <div>
            <Button
              onClick={() => setShowRejectModal(true)}
              className="reject-btn"
              small
              color="primary"
              variant="outline"
              disabled={disableNetworkActions}>
              Reject Order
            </Button>
          </div>
        ) : null}

        {!(role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF && status === ENUM_STATUS.PENDING) && (
          <div
            className={`flex ${status === ENUM_STATUS.READY ||
              status === ENUM_STATUS.DISPATCH ||
              status === ENUM_STATUS.PENDING
              ? 'items-end'
              : 'items-start'
              } ${showRejectButton ? 'mt-3' : ''} gap-6 justify-between text-white`}>
            <div className="flex flex-col gap-2 relative">
              {[
                ENUM_KITCHEN_ROLE.FRONT_DESK,
                ENUM_KITCHEN_ROLE.SUPER_ADMIN,
                ENUM_KITCHEN_ROLE.KITCHEN_ADMIN
              ].includes(role) &&
                !order?.calculated_order?.pickup && (
                  <div className="flex justify-start gap-3 items-center ">
                    <LocationIcon />
                    <p className="font-medium ">
                      {order?.calculated_order?.address_details?.address_line}
                    </p>
                  </div>
                )}
              {status !== ENUM_STATUS.REJECTED ? (
                <div className="flex justify-start gap-3 items-center">
                  <EmptyClockIcon />
                  <p className="text-[12px] opacity-70">
                    {status === ENUM_STATUS.COMPLETED ? 'Completed: ' : 'Time since order: '}
                    {time && (
                      <Timer time={time} format={status === ENUM_STATUS.COMPLETED ? 'LLLL' : ''} />
                    )}
                  </p>
                </div>
              ) : null}

              <div className="flex flex-col gap-3 relative">
                {order?.kitchen_verified_time && (
                  <>
                    <div className="flex justify-start gap-3 items-center">
                      <ClockIcon />
                      <p className="text-[12px] opacity-70">
                        Received by kitchen:&nbsp;
                        {order?.kitchen_verified_time && (
                          <Timer time={order.kitchen_verified_time} />
                        )}
                      </p>
                    </div>
                    <div className="absolute  left-[8px] h-[24px] w-[1px] bg-white opacity-[0.12] bottom-[8px]"></div>
                  </>
                )}
                {order?.kitchen_completed_time ? (
                  <div className="flex justify-start gap-3 items-center">
                    <ClockIcon />
                    <p className="text-[12px] opacity-70">
                      Received by frontdesk:&nbsp;
                      <Timer time={order.kitchen_completed_time} />
                    </p>
                  </div>
                ) : null}
              </div>

              {status === ENUM_STATUS.REJECTED ? (
                <div className=" flex flex-col gap-3">
                  <p className="text-[12px] opacity-70 mb-1 ">
                    REJECTED : {time && <Timer time={time} format="LLLL" />}
                  </p>
                  <p className="text-[12px] opacity-70 mb-0 bold mr-auto">Reason: {reason}</p>
                </div>
              ) : null}
            </div>

            <div className=" flex flex-col gap-3 ">
              <div className="flex flex-col gap-3">
                {riderAssigned === 'no' && !order?.calculated_order?.pickup ? (
                  <p className="text-[12px] opacity-70 mb-1"> RIDER NOT YET ASSIGNED</p>
                ) : null}
                {showRiderInfo && (
                  <p>
                    Rider:
                    <span className="font-medium">
                      {order?.rider?.first_name
                        ? `${order?.rider?.first_name} ${order?.rider?.last_name}`
                        : 'Rider not assigned'}
                    </span>
                  </p>
                )}
                {[
                  ENUM_KITCHEN_ROLE.FRONT_DESK,
                  ENUM_KITCHEN_ROLE.SUPER_ADMIN,
                  ENUM_KITCHEN_ROLE.KITCHEN_ADMIN
                ].includes(role) &&
                  order?.order_details?.rider_note &&
                  (order?.calculated_order?.pickup ? (
                    <p className="">
                      Pickup Note:
                      <span> {order?.order_details?.rider_note}</span>
                    </p>
                  ) : (
                    <p className="">
                      Rider Note:
                      <span> {order?.order_details?.rider_note}</span>
                    </p>
                  ))}
              </div>
            </div>
            <div className="flex flex-col items-end gap-3">
              {isReady() && printStickerMeals?.length > 0 && (
                <>
                  <ReactToPrint
                    trigger={() => (
                      <p className="text-xs cursor-pointer underline-offset-1 text-white font-thin underline">
                        Print Sticker
                      </p>
                    )}
                    content={() => stickerRef.current}
                  />
                  <div className="no-print print-sticker" ref={stickerRef}>
                    <OrderSticker order={order} orders={printStickerMeals} />
                  </div>
                </>
              )}
              {ENUM_KITCHEN_ROLE.PACKAGING === role && (
                <DocketButton receiptRef={receiptRef} status={status} />
              )}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default CardFooter;

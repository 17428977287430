import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ROUTE_KEYS, STORAGE_KEYS } from '../helpers/constants';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

const ProtectedRoute = observer(function ProtectedRoute({
  component: Component,
  authenticated,
  allowAdmin,
  panelRole,
  ...rest
}: any) {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
  const token: any = localStorage.getItem(STORAGE_KEYS.TOKEN);

  return (
    <Route
      {...rest}
      render={(props: JSX.IntrinsicAttributes) =>
        Boolean(token) &&
        (role === panelRole || (Array.isArray(panelRole) && panelRole.includes(role))) ? (
          <Route render={() => <Component {...props} />} />
        ) : (
          <Redirect to={ROUTE_KEYS.LOGIN} />
        )
      }
    />
  );
});
export default ProtectedRoute;

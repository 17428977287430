import React from 'react';
import { STORAGE_KEYS } from '../../helpers/constants';
import Brand from './brand';
import { formatPlural, getItemsTotal, getNameFromOrder } from '../../helpers/functions';
import CardHeaderTag from '../card-header/cardHeaderTag';
import { FiChevronDown } from 'react-icons/fi';
import FinishOrder from './finishOrder';
import SubOrders from './subOrders';
import Cutlery from './cutlery';
import { ENUM_STATUS } from '../../helpers/enums';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/arrow-down-icon.svg';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

const CardBody = ({
  status,
  orders,
  content,
  setHeight,
  isGroupOrder,
  currentFilter,
  // new props
  numberOfBrandAndMeal,
  cutleryChecked,
  isCutlery,
  calculatedOrder,
  order,
  onCheckCutlery,
  onToggleAccordion,
  onFinishOrder,
  isFinished,
  setActive,
  setRotate,
  subOrders,
  selectedMeals,
  selectedAddons,
  onAddonSelect,
  onBulkAddonSelect,
  onMealSelect,
  setViewDetails,
  printStickerMeals,
  setPrintStickerMeals
}) => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const canFinishOrder = role.includes('ADMIN') && status === ENUM_STATUS.PACKAGING;
  const hasSubOrders = subOrders.length > 0;
  const activeBrands = order?.kitchen_ready_brand_ids ?? [];

  const getNumberOfBrands = () => {
    const calcOrders = numberOfBrandAndMeal || orders;
    return formatPlural(calcOrders?.length, 'brand');
  };
  const getNumberOfMeals = () => {
    const calcOrders = numberOfBrandAndMeal || orders;
    return formatPlural(getItemsTotal(calcOrders), 'meal');
  };

  return (
    <div
      className={`w-full rounded-lg p-[16px] grow basis-[394px] min-h-[394px] card-body relative ${
        status === ENUM_STATUS.REJECTED ? 'bg-dark-50' : 'bg-white'
      }`}>
      {status === ENUM_STATUS.DISPATCH && (
        <p
          className="flex gap-1 justify-center items-center cursor-pointer"
          onClick={() => setViewDetails(true)}>
          Close
          <span>
            <ArrowDownIcon />
          </span>
        </p>
      )}
      {canFinishOrder && <FinishOrder onFinish={onFinishOrder} isFinished={isFinished} />}
      {!isGroupOrder ? (
        <div className="border-b border-[#ededed] mb-[10px] pb-3 flex justify-between items-end">
          <div>
            <p className="text-[#595959] leading-2 mb-0">
              {getNumberOfBrands()},&nbsp;
              <span className="pb-1">{getNumberOfMeals()}</span>
            </p>
          </div>
          <Cutlery
            isChecked={cutleryChecked}
            onChange={onCheckCutlery}
            hasCutlery={isCutlery}
            status={status}
          />
        </div>
      ) : (
        <div>
          <div className="flex flex-col justify-start">
            <div className="flex justify-between border-b border-[#ededed] pb-[6px] mb-7">
              <h6 className="mb-1 font-medium text-[16px] leading-none">Group Order</h6>
              <p className="text-[#595959] mb-0 leading-2">
                {formatPlural(calculatedOrder?.sub_calculated_order_ids?.length + 1, 'participant')}
              </p>
            </div>
            <div className="border-b border-[#ededed] mb-[10px] pb-3 flex justify-between items-end active-accordion-head">
              <div className="w-full grow flex-nowrap">
                <h6 className=" pointer font-medium text-[16px] leading-[20px] w-full my-0">
                  1. {getNameFromOrder(order)}
                  {order?.first_order && <CardHeaderTag type="new" />}
                </h6>
                <p className="text-[#595959] mb-0">{getNumberOfBrands()}</p>
              </div>
              <div className="flex">
                <Cutlery
                  isChecked={cutleryChecked}
                  onChange={onCheckCutlery}
                  hasCutlery={isCutlery}
                  status={status}
                />
                <button
                  style={{
                    background: 'white',
                    borderRadius: '50%',
                    width: 15,
                    height: 15
                  }}
                  className={`flex justify-center items-center accordion mt-2 ml-1 ${setActive} ${setRotate}`}
                  onClick={onToggleAccordion}>
                  <FiChevronDown size={20} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className={`accordion__content ${
          isGroupOrder && hasSubOrders && setHeight !== '0px' ? 'border-b border-[#ededed]' : ''
        }`}>
        {orders
          ?.sort((a, b) =>
            role === ENUM_KITCHEN_ROLE.SHOP_STAFF
              ? b?.brand?.item_type?.localeCompare(a?.brand?.item_type)
              : a?.brand?.item_type?.localeCompare(b?.brand?.item_type)
          )
          ?.map((item, index) => (
            <Brand
              printStickerMeals={printStickerMeals}
              setPrintStickerMeals={setPrintStickerMeals}
              onAddonSelect={onAddonSelect}
              onBulkAddonSelect={onBulkAddonSelect}
              onMealSelect={onMealSelect}
              selectedMeals={selectedMeals}
              selectedAddons={selectedAddons}
              status={status}
              order={item}
              mainOrder={order}
              key={index}
              isLastBrand={index === orders.length - 1}
              isGroupOrder={isGroupOrder}
              currentFilter={currentFilter}
              brandIsReady={activeBrands.includes(item.brand?.id)}
            />
          ))}
      </div>

      <SubOrders
        status={status}
        subOrders={subOrders}
        selectedMeals={selectedMeals}
        onMealSelect={onMealSelect}
        onBulkAddonSelect={onBulkAddonSelect}
        onAddonSelect={onAddonSelect}
        selectedAddons={selectedAddons}
        activeBrands={activeBrands}
        mainOrder={order}
        printStickerMeals={printStickerMeals}
        setPrintStickerMeals={setPrintStickerMeals}
      />
    </div>
  );
};

export default CardBody;

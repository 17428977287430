import React, { FC } from 'react';
import { LayoutProvider } from '../../contexts/layout.context';
import Unpaid from '../../pages/unpaid';

const UnpaidOrdersLayout: FC = () => (
  <LayoutProvider>
    <Unpaid />
  </LayoutProvider>
);

export default UnpaidOrdersLayout;

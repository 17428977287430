import moment from 'moment';
import React, { forwardRef } from 'react';
import { FoodCourtLogo } from '../../assets/icons';
import { ENUM_ITEM_TYPES, ENUM_STATUS } from '../../helpers/enums';
import { formatPlural, getItemsTotal, mealTotal, numberWithCommas } from '../../helpers/functions';
import ReceiptOrderMeal from './orderMeal';
import CardAccordion from '../orderCard/cardAccordion';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

const OrderReceipt = forwardRef(
  (
    {
      status,
      calculatedOrder,
      isGroupOrder,
      orders,
      time,
      order,
      subOrders,
      isCutlery,
      discountValue,
      calculatedGroupTotal,
      subTotal,
      deliveryFee,
      serviceTotal,
      extraCharges,
      calculatedTotal,
      paid,
      amountDueGroup,
      amountDue,
      orderType,
      boxNumber
    },
    ref
  ) => {
    const isShopRole = localStorage.getItem(STORAGE_KEYS.ROLE) === ENUM_KITCHEN_ROLE.SHOP_STAFF;
    const altItemType = isShopRole ? ENUM_ITEM_TYPES.FOOD : ENUM_ITEM_TYPES.SHOP;
    const itemType = isShopRole ? ENUM_ITEM_TYPES.SHOP : ENUM_ITEM_TYPES.FOOD;
    const calcOrder = order?.calculated_order;
    const groupOrder = calcOrder?.sub_calculated_order_ids;
    let totalAltItemTotal = 0;

    orders?.forEach((meal) =>
      meal.meals.forEach((item) => {
        if (!isGroupOrder && item?.item_type !== itemType) {
          const eachPrice = mealTotal(item);
          totalAltItemTotal += eachPrice;
        }
      })
    );

    groupOrder?.forEach((itm) =>
      itm.meals.forEach((meal) =>
        meal?.meals?.forEach((item) => {
          if (item?.item_type !== itemType) {
            const eachPrice = mealTotal(item);
            totalAltItemTotal += eachPrice;
          }
        })
      )
    );

    return (
      <div
        ref={ref}
        className={`no-print mb-3 px-3 py-4 card-body ${
          status === ENUM_STATUS.REJECTED ? 'bg-dark-50' : 'bg-white'
        }`}>
        <div>
          <div className="flex flex-col items-center justify-start mt-2 mb-2 ">
            <FoodCourtLogo />
            <div className="receipt-text">
              <p>
                <strong>#{order.order_code}</strong>
              </p>
              <p>
                <strong>
                  {order?.calculated_order?.item_type === ENUM_ITEM_TYPES.ALL && `+ ${altItemType}`}
                </strong>
              </p>
            </div>
            {!isGroupOrder && calculatedOrder?.pickup && (
              <p className="mb-3 ">
                <strong>PICKUP</strong>
              </p>
            )}

            {isGroupOrder ? (
              <>
                <p className="mb-3  ">
                  <strong>Group Order {calculatedOrder?.pickup ? ' - PICKUP' : ''}</strong>
                </p>
                <p className={' mb-3 text-black'}>
                  {calculatedOrder?.sub_calculated_order_ids?.length + 1}&nbsp;
                  {calculatedOrder?.sub_calculated_order_ids?.length + 1 === 1
                    ? 'person'
                    : 'people'}
                </p>
              </>
            ) : (
              <p className={'mb-3 text-black'}>
                {formatPlural(orders?.length, 'brand')},&nbsp;
                {formatPlural(getItemsTotal(orders), 'item')}
              </p>
            )}

            <p>
              {time && moment(time).format('DD/MM/YYYY')} at
              {time && moment(time).format(' LT')}
            </p>
          </div>

          {totalAltItemTotal > 0 && (
            <div className="flex justify-between mb-2 ">
              <p>
                <strong>{isShopRole ? 'Food items' : 'Shop items'}</strong>
              </p>
              <p>{`₦${numberWithCommas(totalAltItemTotal)}.00`}</p>
            </div>
          )}
          {boxNumber ? (
            <div className="flex justify-center gap-x-1">
              <p>Box</p>
              <p className="font-bold">{boxNumber}</p>
            </div>
          ) : null}

          {isGroupOrder ? (
            <div className="flex justify-between mb-2 items-center mt-3 mb-1">
              <p className="font-medium text-[16px]">
                {`1. ${order?.calculated_order?.user?.first_name} ${order?.calculated_order?.user?.last_name}`}
              </p>

              <p>
                {formatPlural(orders?.length, 'brand')},&nbsp;
                {formatPlural(getItemsTotal(orders), 'item')}
              </p>
            </div>
          ) : null}
          <div className="mt-4 mb-3">
            {orders?.map((itms, ind) => {
              return (
                itms?.brand?.item_type === itemType && (
                  <div className="flex flex-col mb-2" key={ind}>
                    <p className="text-center mb-2">
                      <strong>{itms?.brand?.name}</strong>
                    </p>

                    <div className="px-1">
                      {itms?.meals?.map((order, i) => {
                        return <ReceiptOrderMeal order={order} key={i} />;
                      })}
                    </div>
                  </div>
                )
              );
            })}
          </div>
          {/* receipt Sub orders */}
          {subOrders?.map((item, i) => {
            const meals = item.originalMeals ?? item.meals;
            return (
              <CardAccordion
                item={item}
                key={i}
                index={i}
                isReceipt
                getItemsTotal={() => getItemsTotal(meals)}>
                {meals &&
                  meals?.map((itms, ind) => {
                    return (
                      itms?.brand?.item_type === itemType && (
                        <div className="flex-column_left mb-2 " key={ind}>
                          <p className="text-center">
                            <strong>{itms?.brand?.name}</strong>
                          </p>

                          <div className="px-1 ">
                            {itms &&
                              itms.meals.map((order, i) => {
                                return <ReceiptOrderMeal order={order} key={i} />;
                              })}
                          </div>
                        </div>
                      )
                    );
                  })}
              </CardAccordion>
            );
          })}
          {/* receipt sub orders end */}
          {/* Receipt footer start */}
          <div className="flex font-medium justify-between mb-2  px-1 mb-3">
            <p>
              <strong>Cutlery</strong>
            </p>
            <p>
              <strong>{isCutlery ? 'YES' : 'NO'}</strong>
            </p>
          </div>
          <div className="px-1">
            <div className="flex justify-between mb-2  ">
              <p className="">Discount</p>
              <p className="">{`₦${numberWithCommas(discountValue)}.00`}</p>
            </div>

            <div className="flex justify-between mb-2  ">
              <p>Subtotal</p>

              {isGroupOrder ? (
                <p>{`₦${numberWithCommas(calculatedGroupTotal)}.00`}</p>
              ) : (
                <p>{`₦${numberWithCommas(subTotal)}.00`}</p>
              )}
            </div>
            <div className="flex justify-between mb-2  ">
              <p>Delivery fee </p>
              <p>{`₦${numberWithCommas(deliveryFee)}.00`}</p>
            </div>
            <div className="flex justify-between mb-2">
              <p>Service fee</p>
              <p>{`₦${numberWithCommas(serviceTotal)}.00`}</p>
            </div>
            <div className="flex justify-between mb-3">
              <p>Order total</p>
              {isGroupOrder ? (
                <p>{`₦${numberWithCommas(calculatedGroupTotal + extraCharges)}.00`}</p>
              ) : (
                <p>{`₦${numberWithCommas(calculatedTotal)}.00`}</p>
              )}
            </div>
            {!paid ? (
              <div className="flex justify-between mb-2">
                <p>Amount due</p>
                {isGroupOrder ? (
                  <p>
                    {`₦${numberWithCommas(
                      (amountDueGroup && amountDueGroup + extraCharges) || 0
                    )}.00`}
                  </p>
                ) : (
                  <p>{`₦${numberWithCommas((amountDue && amountDue) || 0)}.00`}</p>
                )}
              </div>
            ) : null}
            <div className="flex justify-between mb-3">
              <p>Payment</p>
              <p>{orderType?.name === 'CASH' ? 'TRANSFER' : orderType?.name}</p>
            </div>
            <p className="mb-4 text-center">
              complaints? email us <br />
              hello@getfoodcourt.com
            </p>
            <p className="mb-4 text-center">www.getfoodcourt.com</p>
            <p className="mb-2 text-center">Thanks, order again soon!</p>
          </div>
          {/* Receipt footer end */}
        </div>
      </div>
    );
  }
);

OrderReceipt.displayName = 'OrderReceipt';

export default OrderReceipt;

import React from 'react';
import { FilterType } from '../../pages/live/filters';
import FilterListItem from '../filter-list-item';
import styles from './styles.module.css';

type Props = {
  filters: FilterType[];
  onFilterClick: (_: FilterType) => void;
};

const FilterList: React.FC<Props> = ({ filters, onFilterClick }: Props) => {
  return (
    <div className={styles.main}>
      <div className="flex px-3 py-4">
        {filters.map((filter) => (
          <FilterListItem
            title={filter.title}
            number={filter.number}
            active={filter.active}
            onButtonClick={() => onFilterClick(filter)}
            key={filter.key}
          />
        ))}
      </div>
    </div>
  );
};

export default FilterList;

import React, { Fragment, useEffect, useState } from 'react';
import { BiChevronDown, BiBookOpen, BiTestTube } from 'react-icons/bi';
import { ReactComponent as LogoutIcon } from './icons/logout.svg';
import { ReactComponent as AllBrandsIcon } from '../../assets/images/all-brands-icon.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/arrow-up-icon.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/images/chevron-right-icon.svg';
import { useHistory } from 'react-router-dom';
import { STORAGE_KEYS } from '../../helpers/constants';
import { Input, Switch } from '@cokitchen/cokitchen-components';
import homeStore from '../../stores/homeStore';
import { observer } from 'mobx-react-lite';
import { OrderService } from '../../services/order.service';
import { FiSettings } from 'react-icons/fi';
import ScreenSettingsModal from './screen-settings-modal';
import SplitScreen from './split-screen';
import PackagingScreen from './packaging-screen';
import useLayoutContext from '../../contexts/layout.context';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

const User = observer(({ alert }) => {
  const { testMode, setTestMode } = useLayoutContext();
  const [logoutModal, setLogoutModal] = useState(false);
  const [showBrands, setShowBrands] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPackaginScreen, setShowPackaginScreen] = useState(false);
  const [showSplitScreen, setShowSplitScreen] = useState(false);
  const firstName = localStorage.getItem(STORAGE_KEYS.FIRST_NAME);
  const lastName = localStorage.getItem(STORAGE_KEYS.LAST_NAME);
  const email = localStorage.getItem(STORAGE_KEYS.EMAIL);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const SETTINGS_ROLE = [
    ENUM_KITCHEN_ROLE.KITCHEN_ADMIN,
    ENUM_KITCHEN_ROLE.SUPER_ADMIN,
    ENUM_KITCHEN_ROLE.DEVELOPER
  ];
  const SPLIT_SCREEN_ROLES = [
    ENUM_KITCHEN_ROLE.KITCHEN_ADMIN,
    ENUM_KITCHEN_ROLE.DEVELOPER,
    ENUM_KITCHEN_ROLE.KITCHEN_STAFF
  ];
  const history = useHistory();
  const {
    headerBrands,
    activeHeaderBrands,
    updateActiveHeaderBrands,
    updateHeaderBrands,
    updateIsBrandSplitScreen
  } = homeStore;

  const getHeaderBrands = async () => {
    const brands = await OrderService.getKitchenBrands();
    const allBrands = brands.brands
      ?.filter((item) => item.cokitchen_id === localStorage.getItem('cokitchen') && !item.archived)
      ?.map(({ name, id, cokitchen_id, logo }) => ({ name, id, cokitchen_id, logo }));
    updateHeaderBrands(allBrands);
  };

  useEffect(() => {
    getHeaderBrands();
  }, []);

  const toggleDropdown = () => {
    setLogoutModal(!logoutModal);
    setShowBrands(false);
  };

  const onBlurDropdown = () => {
    setLogoutModal(false);
    setShowBrands(false);
  };

  const setBrand = (brand) => {
    updateIsBrandSplitScreen(false);
    if (brand === 'ALL') {
      setLogoutModal(false);
      setShowBrands(false);
      updateActiveHeaderBrands(['ALL']);
      return;
    }
    let updated = [...activeHeaderBrands, brand].filter((brandId) => brandId !== 'ALL');
    if (activeHeaderBrands.includes(brand)) {
      updated = updated.filter((brandId) => brand !== brandId);
    }

    if (updated.length === 0) updated = ['ALL'];
    updateActiveHeaderBrands([...new Set(updated)]);
  };

  const toggleSettingsModal = () => {
    setShowModal(!showModal);
  };

  const screenDetails = JSON.parse(localStorage.getItem(STORAGE_KEYS.SCREEN_DETAILS));

  // clear localStorage and Logout
  const logout = () => {
    localStorage.clear();
    history.push('/login');
  };
  return (
    <div className=" relative" tabIndex="-1" onBlur={onBlurDropdown}>
      <div className=" flex gap-[.6rem] items-center ">
        <div className="border border-red-2 rounded-full  w-[28px] md:w-[40px] h-[28px] md:h-[40px] p-[2px] flex justify-center items-center ">
          <div className="bg-red-1 text-red-5 text-xs xs:text-sm md:text-base rounded-full  w-full h-full flex justify-center items-center ">
            {firstName.charAt(0).toUpperCase()}
            {lastName.charAt(0).toUpperCase()}
          </div>
        </div>
        <div className=" flex items-center gap-1" role="button" onClick={toggleDropdown}>
          <div className="hidden sm:flex flex-col gap-[1px]">
            <h4 className="text-base font-medium uppercase mb-0">
              {firstName} {lastName}
            </h4>
            <span className="text-[12px] text-gray-5">{email} </span>
          </div>
          <BiChevronDown
            size={20}
            className="rounded-full text-gray-5 cursor-pointer hover:bg-gray-1 transition-all"
          />
        </div>
      </div>
      {logoutModal && (
        <div className="z-50 relative shadow-logout">
          <span className="arrow-up">
            <ArrowUpIcon />
          </span>
          <div
            role="menu"
            className="bg-white z-50 absolute top-2 right-0 rounded-[4px] min-w-[205px]">
            {(role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF ||
              role === ENUM_KITCHEN_ROLE.KITCHEN_ADMIN) && (
              <div className="relative">
                <div
                  className="cursor-pointer flex items-center font-euclid gap-1 py-[12px] px-[24px] transition-all hover:bg-[#fafafa]"
                  role="button"
                  onClick={() => setShowBrands(!showBrands)}>
                  <AllBrandsIcon />
                  <span className="ml-3">All brands</span>
                  <span className="absolute right-2">
                    <ChevronRightIcon />
                  </span>
                </div>
                {showBrands && (
                  <div
                    role="menu"
                    style={{ boxShadow: '0px 5px 30px rgba(102, 102, 102, 0.3)' }}
                    className="bg-white z-50 absolute w-[150px] top-0 right-[102%] rounded-[6px] min-w-[210px] py-[10px]">
                    {headerBrands.length > 0 ? (
                      <ul>
                        <li
                          role="button"
                          onClick={() => setBrand('ALL')}
                          className="relative p-3 pl-[3.5rem]">
                          <Input
                            type="checkbox"
                            checked={activeHeaderBrands[0] === 'ALL'}
                            onChange={() => {}}
                            className="absolute z-[-1] left-[1.5rem]"
                          />
                          <span>All brands</span>
                        </li>
                        {headerBrands.map(({ name, id }) => (
                          <li
                            role="button"
                            onClick={() => {
                              setBrand(id);
                              if (screenDetails?.brands.length > 0) {
                                localStorage.removeItem(STORAGE_KEYS.SCREEN_DETAILS);
                              }
                            }}
                            key={id}
                            className="relative p-3 pl-[3.5rem]">
                            <Input
                              type="checkbox"
                              checked={activeHeaderBrands.includes(id)}
                              onChange={() => {}}
                              className="absolute z-[-1] left-[1.5rem]"
                            />
                            <span>{name}</span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="p-4 text-gray-5">No available brands</p>
                    )}
                  </div>
                )}
              </div>
            )}
            <div
              className="cursor-pointer text-[#A3A3A3] flex items-center text-sm font-euclid gap-1 py-[10px] px-[24px] pr-4 transition-all hover:bg-opacity-70 hover:bg-[#fafafa]"
              onClick={() => setTestMode(!testMode)}>
              <BiTestTube size={18} />
              <span className="ml-[6px] text-[#1A1A1A] select-none">Test Mode</span>
              <div className="relative ml-auto flex items-center">
                <Switch small active={testMode} />
                <span className="absolute top-0 right-0 bottom-0 left-0" />
              </div>
            </div>
            {SPLIT_SCREEN_ROLES.includes(role) && (
              <div
                className="cursor-pointer text-[#A3A3A3] flex items-center text-sm font-euclid gap-1 py-[10px] px-[24px] transition-all hover:bg-opacity-70 hover:bg-[#fafafa]"
                role="button"
                onClick={() => {
                  setShowSplitScreen(!showSplitScreen);
                  toggleDropdown();
                }}>
                <BiBookOpen size={18} />
                <span className="ml-[6px] text-[#1A1A1A]">Split Screen</span>
              </div>
            )}
            {role === ENUM_KITCHEN_ROLE.PACKAGING && (
              <div
                className="cursor-pointer text-[#A3A3A3] flex items-center text-sm font-euclid gap-1 py-[10px] px-[24px] transition-all hover:bg-opacity-70 hover:bg-[#fafafa]"
                role="button"
                onClick={() => setShowPackaginScreen(!showPackaginScreen)}>
                <FiSettings size={18} />
                <span className="ml-[6px] text-[#1A1A1A]">Packaging screens</span>
              </div>
            )}
            {SETTINGS_ROLE.includes(role) && (
              <Fragment>
                <div
                  className="cursor-pointer text-[#A3A3A3] flex items-center text-sm font-euclid gap-1 py-3 px-7 transition-all hover:bg-opacity-70"
                  role="button"
                  onClick={toggleSettingsModal}>
                  <FiSettings />
                  <span className="ml-[6px] text-[#1A1A1A]">Screen Settings</span>
                </div>
              </Fragment>
            )}

            <div
              className="cursor-pointer flex items-center font-euclid gap-1 py-3 px-7 bg-red-1 text-red-8 transition-all hover:bg-opacity-70   "
              role="button"
              onClick={logout}>
              <LogoutIcon />
              <span className="ml-3">Logout</span>
            </div>
          </div>
        </div>
      )}
      <ScreenSettingsModal show={showModal} onClose={toggleSettingsModal} />
      {showSplitScreen && <SplitScreen isOpen onClose={() => setShowSplitScreen(false)} />}
      {showPackaginScreen && (
        <PackagingScreen isOpen={showPackaginScreen} onClose={() => setShowPackaginScreen(false)} />
      )}
    </div>
  );
});
User.displayName = 'User';
export default User;

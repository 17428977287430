import React, { FC } from 'react';
import { LayoutProvider } from '../../contexts/layout.context';
import Inventory from '../../pages/inventory';

const InventoryLayout: FC = () => (
  <LayoutProvider>
    <Inventory />
  </LayoutProvider>
);

export default InventoryLayout;

import React, { useEffect, useMemo, useState } from 'react';
import './styles.scss';
import { withRouter, Link } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { observer } from 'mobx-react';
import { ReactComponent as FoodCourtLogo } from '../../assets/icons/FoodCourtLogo.svg';
import { ReactComponent as ShopLogo } from '../../assets/icons/shopLogo.svg';
import { ReactComponent as SurgeIcon } from '../../assets/images/flash-small.svg';
import { useLocation } from 'react-router';
import db from '../../services/firebase.config';
import homeStore from '../../stores/homeStore';
import { Input, Button } from '@cokitchen/cokitchen-components';
import SurgeModal from '../modals/surgeModal';
import { surgeReasons, STORAGE_KEYS } from '../../helpers/constants';
import HeaderOrderInfo from '../card-header/orderInfo';
import { SocketIOConnection } from '../../helpers/client_library';
import { ROLES } from '../../helpers/role-config';
import User from './user';
import DeliveryType from './delivery-type';
import { OrderService } from '../../services/order.service';
import useLayoutContext from '../../contexts/layout.context';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import { formatNetworkSpeed, ENUM_NETWORK_STRENGTH } from '../../services/network.service';
import OfflineStatus from './offline-status';
import useNetworkContext from '../../contexts/network.context';

const DISPATCH_COUNT_SHOWING_ROLES = [
  ENUM_KITCHEN_ROLE.SUPER_ADMIN,
  ENUM_KITCHEN_ROLE.KITCHEN_ADMIN
];

const Header = observer(function Header ({
  searchChange,
  alert,
  trackingOrders,
  pendingOrders,
  searchValue
}) {
  const { testMode, deliveryType, setDeliveryType } = useLayoutContext();
  const { networkSpeedInMbps, disableNetworkActions, networkSpeed } = useNetworkContext();
  const [unpaidFoodOrdersCount, setUnpaidFoodOrdersCount] = useState(0);
  const [unpaidShopOrdersCount, setUnpaidShopOrdersCount] = useState(0);
  const [scheduledOrdersCount, setScheduledOrdersCount] = useState(0);
  const [preOrdersCount, setPreOrdersCount] = useState(0);
  const [unpaidAllOrdersCount, setUnpaidAllOrdersCount] = useState(0);
  const [showSurge, setShowSurge] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [deliveryValues, setDeliveryValues] = useState(surgeReasons[0]);
  const [showDispatchCount, setShowDispatchCount] = useState(false);
  const [readyForDispatchOrderCount, setReadyForDispatchOrderCount] = useState(0);
  const location = useLocation();
  const [searchPlaceholder, setSearchPlaceholder] = useState('Search by order code');

  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const cokitchenId = localStorage.getItem(STORAGE_KEYS.COKITCHEN);

  const canManageSurge = [
    ENUM_KITCHEN_ROLE.KITCHEN_ADMIN,
    ENUM_KITCHEN_ROLE.SUPER_ADMIN,
    ENUM_KITCHEN_ROLE.DEVELOPER
  ].includes(role);

  useEffect(() => {
    getDeliveryValue();
    setShowDispatchCount(DISPATCH_COUNT_SHOWING_ROLES.includes(role));
  }, []);

  const streamStatusData = async (eventName, setter) => {
    const conn = await SocketIOConnection.getConnection();
    const roomName = eventName;
    conn.addEventListener(roomName, 'updated', (data) => {
      setter(Number(data));
    });
    const data = await conn.joinRoom(roomName, process.env.REACT_APP_WEBOCKETAPP_KEY);
    setter(Number(data));
  };

  useEffect(() => {
    if (showDispatchCount) {
      streamStatusData('status:readyForDispatch', setReadyForDispatchOrderCount);
    }
    streamStatusData('status:unpaidFood', setUnpaidFoodOrdersCount);

    streamStatusData('status:unpaidShop', setUnpaidShopOrdersCount);
    streamStatusData('status:unpaidAll', setUnpaidAllOrdersCount);
  }, [showDispatchCount]);

  useEffect(() => {
    if (location.pathname.includes('inventory')) {
      setSearchPlaceholder('Search by meal category name');
    } else setSearchPlaceholder('Search by order code');
  }, [location]);

  const handleRoutes = () => {
    const roleRouteConfig = ROLES[role];
    return roleRouteConfig ? roleRouteConfig.routes : [];
  };

  const networkStrength = useMemo(() => {
    if (networkSpeedInMbps >= 10) return ENUM_NETWORK_STRENGTH.EXCELLENT;
    if (networkSpeedInMbps >= 5) return ENUM_NETWORK_STRENGTH.GOOD;
    if (networkSpeedInMbps >= 3) return ENUM_NETWORK_STRENGTH.AVERAGE;
    if (networkSpeedInMbps >= 1) return ENUM_NETWORK_STRENGTH.BAD;
    return ENUM_NETWORK_STRENGTH.VERY_BAD;
  }, [networkSpeedInMbps]);

  const getDeliveryValue = async () => {
    await db
      .collection('delivery_values')
      .doc('delivery_values')
      .onSnapshot(
        (items) => {
          let activeSurge = surgeReasons.find(
            (itm) =>
              String(itm.alt) === String(items?.data()?.kitchen_surge) &&
              String(itm.value) === String(items?.data()?.kitchen_surge_estimated_time)
          );
          activeSurge = activeSurge || surgeReasons[0];
          setDeliveryValues({ ...activeSurge, ...items?.data() });
        },
        (err) => {
          console.error(err);
        }
      );
  };

  // Update delivery factors
  const updateDeliveryFactors = async (surge) => {
    setSubmitting(true);
    const body = {
      kitchen_surge: surge === 0 ? '0.0' : '1.0',
      kitchen_surge_estimated_time: `${surge}`
    };
    const message = 'Kitchen surge updated successfully!';
    delete body.surge;
    !body?.logistics_surge_type && delete body.logistics_surge_type;
    await homeStore.postDeliveryValues(alert, body, message);
    setSubmitting(false);
    setShowSurge(false);
    await getDeliveryValue();
  };
  const loadScheduledOrders = async () => {
    const res = await OrderService.getScheduledOrders({
      cokitchen_id: cokitchenId,
      test: testMode
    });

    const scheduled = res?.data?.total;
    setScheduledOrdersCount(scheduled);
  };
  const loadPreorderedOrders = async () => {
    const res = await OrderService.getPreorderedOrders({
      cokitchen_id: cokitchenId,
      test: testMode,
      company: false
    });

    const preordered = res?.data?.length;
    setPreOrdersCount(preordered);
  };

  const loadOrders = () => {
    loadScheduledOrders();
    loadPreorderedOrders();
  };

  useEffect(() => {
    if (role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF) {
      setScheduledOrdersCount(0);
      setPreOrdersCount(0);
      loadOrders();
      const interval = setInterval(() => {
        loadOrders();
      }, 300000);

      return () => clearInterval(interval);
    }
  }, [testMode]);

  const routes = handleRoutes();

  const formattedNetworkSpeed = formatNetworkSpeed(networkSpeed);

  return (
    <header className=" bg-white text-gray-6">
      <div className="py-[12px] px-6 border-b border-gray-1 flex justify-between gap-4 items-start lg:items-center ">
        <div className=" flex items-center w-full md:w-[60%] lg:w-[70%] gap-4 flex-wrap">
          <div>{role === 'SHOP_STAFF' ? <ShopLogo /> : <FoodCourtLogo />}</div>
          <HeaderOrderInfo
            pendingOrders={pendingOrders}
            trackingOrders={trackingOrders}
            showDispatch={false}
            dispatchOrderCount={readyForDispatchOrderCount}
          />
        </div>
        <div className="flex items-center gap-4">
          <span className={`network-pill ${networkStrength}`}>{formattedNetworkSpeed.value}&nbsp;{formattedNetworkSpeed.units}</span>
          <DeliveryType deliveryType={deliveryType} setDeliveryType={setDeliveryType} />
          <User alert={alert} />
        </div>
      </div>
      <div
        className='px-6 py-[3px] flex justify-between items-center flex-wrap gap-x-6 gap-y-3'>
        <div className="w-[20%] max-w-[350px] min-w-[200px]">
          <Input
            placeholder={searchPlaceholder}
            type="search"
            small
            value={searchValue}
            onChange={searchChange}
            inputClass='!py-2'
          />
        </div>
        <div className=" flex gap-4 flex-wrap">
          {routes.map(({ name, route, Icon }, i) => (
            <Link
              key={i}
              to={route}
              className={`inline-flex items-center cursor-pointer rounded-[10px] border py-[10px] px-[14px] ${route === location.pathname
                ? 'text-red-5 active:text-red-5 border-red-5'
                : 'text-gray-4 active:text-gray-4 border-gray-2'
                }`}>
              {Icon && <Icon className="w-5 h-5" />}
              <span className="pl-1">{name}</span>
              {name === 'UNPAID' && (
                <span
                  className="font-euclid ml-[6px] mt-[2px] bg-red-5 w-[16px]
				 h-[16px] flex justify-center items-center text-[8px] text-white rounded-full">
                  {role === 'SHOP_STAFF'
                    ? unpaidShopOrdersCount + unpaidAllOrdersCount
                    : unpaidAllOrdersCount + unpaidFoodOrdersCount + unpaidShopOrdersCount}
                </span>
              )}
              {name === 'SCHEDULED' && (
                <span
                  className=" font-euclid ml-[6px] mt-[2px] bg-red-5 w-[16px] h-[16px] flex
				justify-center items-center text-[8px] text-white rounded-full">
                  {scheduledOrdersCount}
                </span>
              )}
              {name === 'PRE ORDER' && (
                <span
                  className=" font-euclid ml-[6px] mt-[2px] bg-red-5 w-[16px] h-[16px] flex
				justify-center items-center text-[8px] text-white rounded-full">
                  {preOrdersCount}
                </span>
              )}
              {name === 'PENDING ORDERS' && role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF && (
                <span
                  className=" font-euclid ml-[6px] mt-[2px] bg-red-5 w-[16px] h-[16px] flex
				justify-center items-center text-[8px] text-white rounded-full">
                  {pendingOrders}
                </span>
              )}
            </Link>
          ))}
        </div>

        {canManageSurge && (
          <div className="w-[150px]">
            <Button
              color={+deliveryValues.value === 0 ? 'gray' : 'primary'}
              disabled={disableNetworkActions}
              variant="block"
              small
              onClick={() => setShowSurge(true)}
              className='!p-2'>
              <SurgeIcon />
              <span className="ml-1 text-lg">Manage Surge</span>
              {+deliveryValues.value > 0 && (
                <span className="rounded-3xl bg-white w-[1rem] h-[1rem] flex justify-center items-center text-[10px] ml-1 text-[#E60A2B]">
                  {deliveryValues.value}
                </span>
              )}
            </Button>
          </div>
        )}
      </div>

      <OfflineStatus />

      {showSurge && (
        <SurgeModal
          show={showSurge}
          loading={submitting}
          value={deliveryValues?.value}
          onClose={() => setShowSurge(false)}
          onConfirm={updateDeliveryFactors}
        />
      )}
    </header>
  );
});
export default withRouter(withAlert()(Header));

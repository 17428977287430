// Kitchen
export const START_ORDER_URL = 'kitchen/order/start/';
export const START_SHOP_ORDER_URL = 'shop/order/start/';
export const END_ORDER_URL = 'kitchen/order/finish/';
export const END_SHOP_ORDER_URL = 'shop/order/finish/';
export const REJECT_ORDER_URL = 'kitchen/order/reject/';
export const RECALL_ORDER_URL = 'kitchen/order/recall/';
export const TURN_OFF_MULTIPLE_ITEMS = 'kitchen/supervisor/turn-off-multiple-meals';
export const UPDATE_MEALS = 'kitchen/supervisor/turn-off-multiple-meals';
export const GET_COMPLETED_ORDER_URL = 'kitchen/order/completed';
export const GET_REJECTED_ORDER_URL = 'kitchen/order/rejected';
export const SEARCH_ORDER_URL = 'logistics/super-admin/order/search/';
export const SEARCH_MEAL_URL = 'meals/search';
export const GET_BRANDS_URL = 'internal/brand';
export const GET_COKITCHENS_URL = 'internal/cokitchens';
export const SPECIFIC_COKITCHENS_URL = 'internal/cokitchen/specific';
export const BRAND_IS_READY = 'kitchen/order/brand/is-ready';

// Front Desk
export const DISPATCH_ORDER_URL = 'kitchen/order/dispatch/';
export const SET_PAID_ORDER_URL = 'kitchen/order/paid/';
// Suge
export const COKITCHEN_DELIVERY_SURGE_URL = 'marketing/surge';
export const KITCHEN_MEAL = 'kitchen/meal';
export const MEAL_INFO_URL = 'marketing/meal';
export const BULK_MEAL_URL = 'kitchen/meal/bulk';
export const GET_ADDONS_URL = 'internal/meal-addon';

// UPDATE SCREEN SIZE
export const NO_OF_SCREENS = 'kitchen/settings';

// get ADDONS
export const GET_ADDONS = 'brand/addons/';

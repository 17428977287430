const errorHandler = (error, alert) => {
  const errMsg = error && (error.response || error.message || error.toString() || error);
  const errorMsg = (errMsg && errMsg.data && errMsg.data.message) || errMsg;
  if (typeof errorMsg !== 'string') {
    alert.show('An error has occured!!', {
      type: 'error'
    });
  } else {
    alert?.show(errorMsg, {
      type: 'error'
    });
  }
};
export default errorHandler;

import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import OrderCard from '../../components/orderCard';
import { withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { ENUM_LAYOUT_STATE } from '../../helpers/enums';
import useLayoutContext from '../../contexts/layout.context';

const Scheduled = () => {
  const { orders, loading } = useLayoutContext();
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const search = searchValue?.toUpperCase();
    const filtered = orders.filter((order) => {
      const searchFilter =
        order?.order_code?.toUpperCase().includes(search) ||
        (order?.calculated_order?.user?.first_name + ' ' + order?.calculated_order?.user?.last_name)
          ?.toUpperCase()
          .includes(search) ||
        order?.calculated_order?.user?.phone_number?.toUpperCase().includes(search) ||
        order?.calculated_order?.user?.email?.toUpperCase().includes(search);

      const groupOrderFilter = order.calculated_order?.group_sub_order;
      return searchFilter && !groupOrderFilter;
    });
    setFilteredOrders(filtered);
  }, [orders, searchValue]);

  const getLayoutState = () => {
    if (filteredOrders.length === 0 && searchValue) return ENUM_LAYOUT_STATE.EMPTY_SEARCH;
    if (loading) return ENUM_LAYOUT_STATE.LOADING;
    return ENUM_LAYOUT_STATE.PAGE;
  };
  return (
    <Layout
      pendingOrders={0}
      trackingOrders={orders?.length}
      searchValue={searchValue}
      searchChange={(e) => setSearchValue(e.target.value?.toUpperCase())}
      layoutState={getLayoutState()}>
      {filteredOrders.map((item, i) => (
        <OrderCard
          key={item.id + item.updated_at}
          order={item}
          // onActionClick={loadOrders}
          hideCardButton
        />
      ))}
      {orders.length === 0 && (
        <div className="text-[3rem] text-white text-center flex grow items-center justify-center">
          There are no scheduled orders
        </div>
      )}
    </Layout>
  );
};
export default withRouter(withAlert()(Scheduled));

import React, { FC } from 'react';
import { Input } from '@cokitchen/cokitchen-components';

type BrandMealAddonType = {
  isChecked: boolean;
  addon: any;
  showCheckbox: boolean;
  onAddonChange: () => void;
};

const BrandMealAddon: FC<BrandMealAddonType> = ({
  addon,
  showCheckbox,
  isChecked,
  onAddonChange
}) => {
  return (
    <div
      className={`flex justify-start mb-[6px] ${showCheckbox ? 'pointer' : ''}`}
      role="checkbox"
      onClick={() => {
        if (showCheckbox) {
          onAddonChange();
        }
      }}>
      <p className={`mb-0 mr-1 ${showCheckbox ? 'pointer' : ''}`}>
        <span className="text-red-4 mr-2 font-bold">{+addon.quantity}x</span>
        {addon?.name ?? addon?.meal_data?.name ?? ''}
      </p>
      {showCheckbox && (
        <div className="ml-3">
          <Input type="checkbox" checkColor="green" checked={isChecked} onChange={onAddonChange} />
        </div>
      )}
    </div>
  );
};

export default BrandMealAddon;

import React, { FC } from 'react';
import { LayoutProvider } from '../../contexts/layout.context';
import Scheduled from '../../pages/scheduled';

const ScheduledOrdersLayout: FC = () => (
  <LayoutProvider>
    <Scheduled />
  </LayoutProvider>
);

export default ScheduledOrdersLayout;

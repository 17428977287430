import {
  apiLiveMapping as liveApiLiveMapping,
  checkOrderValidityForRole as liveCheckOrderValidityForRole
} from '../pages/live/roleMapping.service';
import {
  apiLiveMapping as dispatchApiLiveMapping,
  checkOrderValidityForRole as dispatchCheckOrderValidityForRole
} from '../pages/dispatch/roleMapping.service';
import {
  apiLiveMapping as recallApiLiveMapping,
  checkOrderValidityForRole as recallCheckOrderValidityForRole
} from '../pages/recall/roleMapping.service';
import {
  apiLiveMapping as scheduledApiLiveMapping,
  checkOrderValidityForRole as scheduledCheckOrderValidityForRole
} from '../pages/scheduled/roleMapping.service';
import {
  apiLiveMapping as unpaidApiLiveMapping,
  checkOrderValidityForRole as unpaidCheckOrderValidityForRole
} from '../pages/unpaid/roleMapping.service';

import { ENUM_PAGES } from '../helpers/enums';
import { OrderType } from '../helpers/types';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
  /* eslint-disable */

const routeMappings: Record<
  ENUM_PAGES,
  {
    liveMapping: Partial<Record<ENUM_KITCHEN_ROLE, (arg0: any) => Promise<any>>>;
    checkForValidity: (
      order: OrderType,
      cokitchenId: string,
      test: boolean,
      screen_id?: string | undefined,
      packaging_screen_id?: string | undefined
    ) => boolean | undefined;
  }
> = {
  [ENUM_PAGES.DISPATCH]: {
    liveMapping: dispatchApiLiveMapping,
    checkForValidity: dispatchCheckOrderValidityForRole
  },
  [ENUM_PAGES.RECALLED]: {
    liveMapping: recallApiLiveMapping,
    checkForValidity: recallCheckOrderValidityForRole
  },
  [ENUM_PAGES.SCHEDULED]: {
    liveMapping: scheduledApiLiveMapping,
    checkForValidity: scheduledCheckOrderValidityForRole
  },
  [ENUM_PAGES.LIVE]: {
    liveMapping: liveApiLiveMapping,
    checkForValidity: liveCheckOrderValidityForRole
  },
  [ENUM_PAGES.UNPAID]: {
    liveMapping: unpaidApiLiveMapping,
    checkForValidity: unpaidCheckOrderValidityForRole
  }
};

export const validityMappings = {
  [ENUM_PAGES.DISPATCH]: dispatchApiLiveMapping,
  [ENUM_PAGES.RECALLED]: recallApiLiveMapping,
  [ENUM_PAGES.SCHEDULED]: scheduledApiLiveMapping,
  [ENUM_PAGES.LIVE]: liveApiLiveMapping
};

export const activeRoleMapping = (page: ENUM_PAGES) => {
  return routeMappings[page].liveMapping;
};
export const activeRoleOrderValidity = (page: ENUM_PAGES | null) => {
  if (!page) return;
  return routeMappings[page].checkForValidity;
};

import React from 'react';

type Props = {
  pendingOrders?: number;
  trackingOrders?: number;
  showDispatch?: boolean;
  dispatchOrderCount?: number;
};

const HeaderOrderInfo: React.FC<Props> = ({
  pendingOrders,
  trackingOrders,
  showDispatch,
  dispatchOrderCount
}: Props) => {
  return (
    <div className=" flex items-center gap-x-4 flex-wrap">
      <p className="text-[16px] mb-0 font-medium">
        Pending Orders: <span className=" text-red-5">{pendingOrders ?? 0}</span>
      </p>
      <p className="text-[16px] mb-0 font-medium">
        Orders in Progress: <span className=" text-red-5">{trackingOrders ?? 0}</span>
      </p>
      {(showDispatch ?? false) && (
        <p className="text-[16px] mb-0 font-medium">
          Ready for Dispatch: <span className=" text-red-5">{dispatchOrderCount ?? 0}</span>
        </p>
      )}
    </div>
  );
};
export default HeaderOrderInfo;

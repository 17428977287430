import { BaseModal, Input } from '@cokitchen/cokitchen-components';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SplitScreenIcon } from '../../../assets/icons/split_screen.svg';
import NoScreens from './no-screens';
import ScreenCard from './screen-card';
import dataService from '../../../services/data.service';
import { STORAGE_KEYS } from '../../../helpers/constants';
import CreateScreenModal, { FormDataType } from './create-screen-modal';
import './style.scss';
import { BrandType } from '../../../helpers/types';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export type ScreenType = {
  cokitchen_id: string | null;
  active: boolean;
  active_order_count: number;
  brand_ids: string[];
  id: string;
  name: string;
  created_at: string;
  brands: BrandType[];
};
const SplitScreen = ({ isOpen, onClose }: Props) => {
  const [screens, setScreens] = useState<ScreenType[]>([]);
  const [filteredScreens, setFilteredScreens] = useState<ScreenType[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [showCreateScreenModal, setShowCreateScreenModal] = useState(false);
  const cokitchen_id = localStorage.getItem(STORAGE_KEYS.COKITCHEN);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;

  const fetchData = async () => {
    setLoading(true);
    try {
      const res: { data: ScreenType[] } = await dataService.getAuthData(
        `kitchen/screens?cokitchen_id=${cokitchen_id ?? ''}`,
        {}
      );
      setScreens(res?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleEditSuccess = (value: FormDataType) => {
    const screenIndex = screens.findIndex((item) => item.id === value.id);
    if (screenIndex !== -1) {
      const editedScreen = {
        ...screens[screenIndex],
        ...value
      };
      const newScreens = [...screens];
      newScreens.splice(screenIndex, 1, editedScreen);
      setScreens(newScreens);
    }
  };

  const handleDeleteSuccess = (id: string) => {
    const unDeletedScreen = screens.filter((item) => item.id !== id);
    setScreens(unDeletedScreen);
  };

  const handleSearch = () => {
    const filterScreens =
      search === '' ? screens : screens.filter((screen) => screen.name.includes(search));
    setFilteredScreens(filterScreens);
  };

  useEffect(() => {
    handleSearch();
  }, [search, screens]);

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title="Split Screens"
      titleCta={
        screens.length > 0 && (role as string).includes('ADMIN') ? (
          <p
            className="text-red-5 cursor-pointer text-sm font-medium"
            onClick={() => setShowCreateScreenModal(true)}>
            + Add new screen
          </p>
        ) : undefined
      }
      id="split_screen_modal"
      titleIcon={<SplitScreenIcon />}
      titleIconColor="success">
      {!loading && (
        <Input
          type="search"
          value={search}
          placeholder="Search by screen number"
          small
          onChange={(e) => setSearch(e.target.value)}
          className="mb-7 max-w-[439px]"
        />
      )}
      <div>
        {loading ? (
          <div className='w-full h-[30vh] flex flex-col gap-2 justify-center text-center items-center"'>
            Loading...
          </div>
        ) : filteredScreens.length === 0 ? (
          <NoScreens setShow={setShowCreateScreenModal} onClose={onClose} />
        ) : (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredScreens
              .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
              .map((item, index) => (
                <ScreenCard
                  data={item}
                  key={index}
                  onEditSuccess={handleEditSuccess}
                  onDeleteSuccess={handleDeleteSuccess}
                />
              ))}
          </div>
        )}
      </div>

      <CreateScreenModal
        data={{
          name: '',
          active: false,
          brand_ids: []
        }}
        type="create"
        isOpen={showCreateScreenModal}
        onClose={() => setShowCreateScreenModal(false)}
        refreshData={fetchData}
      />
    </BaseModal>
  );
};

export default SplitScreen;

import React from 'react';
import { STORAGE_KEYS, splitScreenColors } from '../../helpers/constants';
import { ENUM_ORDER_PROVIDER, ENUM_STATUS } from '../../helpers/enums';
import './styles.scss';
import useLayoutContext from '../../contexts/layout.context';
import { orderIsPinned } from '../../helpers/functions';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

const CardLayout = ({ status, order = {}, children }) => {
  const firstName = localStorage.getItem(STORAGE_KEYS.FIRST_NAME);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const staffNumber = localStorage.getItem(STORAGE_KEYS.STAFF_NUMBER);

  const activeBrands = order.kitchen_ready_brand_ids ?? [];
  const QUICK_EAT = 'quick eat';

  const isQuickEats = () => {
    const brand_ids = order.calculated_order?.brand_ids ?? [];
    const brand_names = brand_ids.map((brand) => brand.name.toLowerCase());
    return brand_names.some((name) => name.includes(QUICK_EAT));
  };

  const isPendingColor = () => {
    const adminPackaging = role.includes('ADMIN') && status === ENUM_STATUS.PACKAGING;
    const started = status === ENUM_STATUS.STARTED;
    const isPackaging = status === ENUM_STATUS.PACKAGING && firstName === 'Packaging';

    return adminPackaging || started || isPackaging;
  };

  const isCompleteColor = () => {
    return (
      status === ENUM_STATUS.COMPLETED ||
      status === ENUM_STATUS.DISPATCH ||
      status === ENUM_STATUS.SEARCH
    );
  };

  const isRejectedColor = () => {
    return status === ENUM_STATUS.REJECTED;
  };

  const isFrontdeskChowdeck = () => {
    return role === ENUM_KITCHEN_ROLE.FRONT_DESK && order.delivery === ENUM_ORDER_PROVIDER.CHOWDECK;
  };
   const isFrontdeskGlovo = () => {
     return role === ENUM_KITCHEN_ROLE.FRONT_DESK && order.delivery === ENUM_ORDER_PROVIDER.GLOVO;
   };

  const isBrandReady = () => {
    const meals = order.calculated_order?.meals ?? [];
    const allActiveBrands = meals.map(({ brand }) => brand.id);
    const allBrandsAreActive = allActiveBrands.every((brand) => activeBrands.includes(brand));
    return (
      role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF &&
      status === ENUM_STATUS.STARTED &&
      allBrandsAreActive
    );
  };

  const isFrontDeskUnpaidPage = () => {
    const path = location.pathname;
    return path === '/front-desk/unpaid';
  };

  const isScheduled = () => {
    return (
      order.isSchedulePage ||
      (order.scheduled &&
        ([ENUM_KITCHEN_ROLE.KITCHEN_STAFF, ENUM_KITCHEN_ROLE.PACKAGING].includes(role) ||
          isFrontDeskUnpaidPage()))
    );
  };

  const isPreorder = () => {
    return (
      order.preorder &&
      ([ENUM_KITCHEN_ROLE.KITCHEN_STAFF, ENUM_KITCHEN_ROLE.PACKAGING].includes(role) ||
        isFrontDeskUnpaidPage())
    );
  };

  const isLoyalCustomer = () => {
    return order?.calculated_order?.user?.loyal_customer;
  };

  const isPackagingColor = () => {
    return (
      !isScheduled() &&
      !isLoyalCustomer() &&
      role === ENUM_KITCHEN_ROLE.PACKAGING &&
      status === ENUM_STATUS.PACKAGING &&
      staffNumber
    );
  };

  const getLayoutColor = () => {
    return isQuickEats()
      ? 'bg-[#FC8EAC]'
      : isScheduled()
      ? 'bg-[#016FB1]'
      : isPreorder()
      ? 'bg-[#511EE1]'
      : isLoyalCustomer()
      ? 'bg-[#aa4993]'
      : status === ENUM_STATUS.READY
      ? 'bg-[#D50000]'
      : isBrandReady()
      ? 'bg-[#C1BEB9]'
      : isPendingColor()
      ? 'bg-[#D58300]'
      : isFrontdeskChowdeck()
      ? 'bg-[#D58300]'
      : isFrontdeskGlovo()
      ? 'bg-[#FFC600]'
      : isCompleteColor()
      ? 'bg-[#707160]'
      : isRejectedColor()
      ? 'bg-white'
      : 'bg-[#46AE31]';
  };

  const { pinnedOrders } = useLayoutContext();

  const packagingStyle = isPackagingColor()
    ? { backgroundColor: splitScreenColors[staffNumber - 1] }
    : {};

  return (
    <div
      className={`order-card rounded-lg mx-[12px] p-6 relative ${getLayoutColor()} ${orderIsPinned(pinnedOrders, order) && 'order-card-pinned'
        } `}
      style={packagingStyle}>
      {children}
    </div>
  );
};

export default CardLayout;

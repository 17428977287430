import React, { FC } from 'react';

const OrderNote: FC<{ note: string }> = ({ note }) => {
  return (
    <div className="bg-[#F5F5F5] font-semibold flex rounded mb-3 mt-4 p-2">
      <p className="text-[12px] leading-[19px]">
        <strong>NOTE: </strong>
        {note}
      </p>
    </div>
  );
};

export default OrderNote;

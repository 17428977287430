import React from 'react';
import { ReactComponent as NoSearchIcon } from './icons/no-search.svg';
import { Button } from '@cokitchen/cokitchen-components';
const NoSearchResult = ({ goBack }) => {
  return (
    <div className="flex grow flex-col items-center justify-center h-full gap-3 py-[10vh] w-[90%] mx-auto text-center">
      <NoSearchIcon className=" w-[100px] md:w-auto" />
      <h5 className=" text-gray-2 text-base md:text-2xl font-semibold">No search result found</h5>
      <p className=" text-gray-5 text-sm md:text-base">
        You probably omitted a number, check again
      </p>
      <p className=" text-[#ffffff33] text-xs md:text-sm"> or </p>
      <Button color="primary" variant="block" className="mt-8" onClick={goBack}>
        Back to Live Orders
      </Button>
    </div>
  );
};

export default NoSearchResult;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { STORAGE_KEYS } from '../helpers/constants';
import { ROLES } from '../helpers/role-config';
import { AllowedRoleType } from '../helpers/types';

const UnauthRoute = observer(function UnauthRoute({
  component: Component,
  authenticated,
  ...rest
}: any) {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as AllowedRoleType;
  // eslint-disable-next-line
  const roleConfig = role ? ROLES[role as AllowedRoleType] : null;
  // eslint-disable-next-line
  if (!Object.keys(ROLES).find((r) => r === role)) localStorage.clear();

  return (
    <Route
      {...rest}
      render={(props: JSX.IntrinsicAttributes) =>
        // eslint-disable-next-line
        !role || !localStorage.getItem(STORAGE_KEYS.TOKEN) ? (
          <Route render={() => <Component {...props} />} />
        ) : (
          <Redirect to={roleConfig?.baseRoute ?? '/'} />
        )
      }
    />
  );
});
export default UnauthRoute;

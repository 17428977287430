/* eslint-disable */

import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import OrderCard from '../../components/orderCard';
import { withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { checkOrderValidityForRole } from './roleMapping.service';
import { ENUM_PAGES, ENUM_STATUS } from '../../helpers/enums';
import { observer } from 'mobx-react-lite';
import { getLiveLayoutState } from './fns.service';
import NoOrder from '../../components/empty-states/noOrder';
import moment from 'moment';
import useLayoutContext from '../../contexts/layout.context';
import { STORAGE_KEYS } from '../../helpers/constants';
import { useLocation } from 'react-router';

const LiveOrders = observer(({ alert }) => {
  const { pathname } = useLocation();
  const showPendingOrder = pathname.includes('pending');

  const {
    setSocketConfig,
    prefilteredOrders,
    loading,
    onFilterClick,
    filters,
    activeFilter,
    searchValue,
    setSearchValue
  } = useLayoutContext();

  const filteredOrders = prefilteredOrders.filter((order) =>
    showPendingOrder ? !order.kitchen_accepted : order
  );
  const [orderSummary, setOrderSummary] = useState({
    pending: [],
    tracking: []
  });

  const cokitchenId = localStorage.getItem(STORAGE_KEYS.COKITCHEN);
  const [scheduledOrders, setScheduledOrders] = useState([]);

  useEffect(async () => {
    // scheduled order alert box
    const timer = setInterval(() => {
      showScheduledAlerts();
    }, 300000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    setOrderSummary({
      pending: filteredOrders.filter((order) => !order.kitchen_accepted),
      tracking: filteredOrders.filter((order) => order.kitchen_accepted)
    });

    // scheduled orders
    setScheduledOrders(prefilteredOrders.filter((o) => o.scheduled));
  }, [prefilteredOrders]);

  useEffect(() => {
    setSocketConfig({ page: ENUM_PAGES.LIVE, roomName: `order-update-cokitchen:${cokitchenId}` });
  }, []);

  const isScheduledOrderLate = (order) => {
    const now = moment();
    const then = moment(order.scheduled_delivery_datetime);
    const diff = then.diff(now, 'minutes');
    return { isLate: diff <= 30, diff };
  };

  const showScheduledAlerts = () => {
    scheduledOrders.forEach((o) => {
      const { isLate, diff } = isScheduledOrderLate(o);
      if (isLate) {
        alert.show(`Schedule order #${o.order_code.toUpperCase()} is due in ${diff} mins`);
      }
    });
  };

  const getLayoutState = () => {
    return getLiveLayoutState(filteredOrders, searchValue, loading, {
      pending: orderSummary.pending.length,
      tracking: orderSummary.tracking.length
    });
  };

  return (
    <Layout
      pendingOrders={orderSummary.pending.length}
      trackingOrders={orderSummary.tracking.length}
      searchValue={searchValue}
      searchChange={(e) => setSearchValue(e.target.value?.toUpperCase())}
      layoutState={getLayoutState()}
      filters={filters}
      onFilterClick={onFilterClick}>
      {filteredOrders.length === 0 ? (
        <NoOrder />
      ) : (
        filteredOrders.map(({ ...item }, i) => (
          <OrderCard
            key={item.id}
            actionDisabled={item.status === ENUM_STATUS.STARTED}
            order={item}
            currentFilter={activeFilter}
            checkOrderValidityForRole={checkOrderValidityForRole}
          />
        ))
      )}
    </Layout>
  );
});
LiveOrders.displayName = 'LiveOrders';
export default withRouter(withAlert()(LiveOrders));

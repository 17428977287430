import React, { FC, Fragment } from 'react';
import { MealType, OrderType } from '../../helpers/types';
import { getPackagingStation } from '../../helpers/functions';
import StickerItem from '../sticker-item';

const OrderSticker: FC<{ orders: MealType[]; order: OrderType }> = ({ orders, order }) => {
  const flatOrders =
    orders?.reduce((acc: MealType[], o) => {
      Array.from({ length: +(o.quantity ?? 1) }).forEach(() => {
        acc.push(o);
      });
      return acc;
    }, []) ?? [];

  return (
    <div className="space-y-1">
      <Fragment>
        <div>
          {flatOrders.map((item, index) => (
            <Fragment key={item.id.toString() + String(index)}>
              <p className="text-center pt-[2px] font-bold text-[10px]">
                #{order?.order_code?.toUpperCase()}
                <span className="mx-[6px]">-</span>
                {getPackagingStation(order?.split_screen_firestore_details_packaging_doc_id)}
              </p>
              <StickerItem item={item} />
              {flatOrders.length > index + 1 && <div className="pagebreak" />}
            </Fragment>
          ))}
        </div>
      </Fragment>
    </div>
  );
};

export default OrderSticker;

import React, { FC } from 'react';
import { LayoutProvider } from '../../contexts/layout.context';
import LiveOrders from '../../pages/live';
const LiveOrderLayout: FC = () => (
  <LayoutProvider>
    <LiveOrders />
  </LayoutProvider>
);

export default LiveOrderLayout;

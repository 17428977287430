/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { FC, useEffect, useState } from 'react';
import TimeSelector from '../../time-selector';
import { TimeSelectorType } from '../../time-selector/types';
import { Input } from '@cokitchen/cokitchen-components';

type Props = {
  item: string;
  brandName: string;
  date: string;
  setDate: (arg0: string) => void;
  startTime: TimeSelectorType;
  setStartTime: (arg0: TimeSelectorType) => void;
  isChecked: boolean;
  setIsChecked: (arg0: boolean) => void;
};

const TimeSelectorInput: FC<Props> = ({
  item,
  brandName,
  date,
  setDate,
  startTime,
  setStartTime,
  isChecked,
  setIsChecked
}: Props) => {
  const [showTime, setShowTime] = useState<boolean>(false);
  const [hasEditedTime, setHasEditedTime] = useState(false);
  const formatTimeToAmPm = (time: TimeSelectorType) => {
    const { hours, minutes, amPm } = time;
    return `${hours}:${minutes} ${amPm?.toLowerCase()}`;
  };
  useEffect(() => {
    if (startTime.minutes !== '') setHasEditedTime(true);
  }, [startTime]);
  const getTimeValue = () => {
    if (!hasEditedTime) return '';
    return `${formatTimeToAmPm(startTime)}`;
  };
  const handleChecked = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className="border-b-[1px] border-gray-2">
      <p className="text-[#595959] text-sm font-normal mb-4">When will {item} be back on?</p>
      <div className="grid grid-cols-2 gap-x-4 relative mb-4">
        <input
          type="text"
          placeholder="Enter date"
          className="border border-[#EAE6E7] rounded-lg px-4 py-3"
          value={date}
          onFocus={(e) => (e.target.type = 'date')}
          onChange={(e) => setDate(e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter time"
          className="border border-[#EAE6E7] rounded-lg px-4 py-3"
          onFocus={() => setShowTime(true)}
          value={startTime.minutes !== undefined ? getTimeValue() : ''}
          readOnly
        />
        {showTime && (
          <div className="absolute left-[100%] ml-[2px] bottom-[-1.5rem] rounded-[6px] bg-white shadow-md">
            <div className="rounded p-3 pb-4">
              <p className="text-gray-5 text-xs leading-[19px] mb-1">Time:</p>
              <TimeSelector value={startTime} onChange={setStartTime} />
            </div>
          </div>
        )}
      </div>

      <div className="flex gap-2 mb-8 items-center">
        <Input type="checkbox" checked={isChecked} onChange={handleChecked} />
        <p className="text-sm font-normal">Turn on when {brandName} opens tomorrow</p>
      </div>
    </div>
  );
};

export default TimeSelectorInput;

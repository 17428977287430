import React, { FC } from 'react';
import { CARD_HEADER_TAG } from '../../helpers/constants';

export type CardHeaderTagType = { type: keyof typeof CARD_HEADER_TAG; className?: string };

const CardHeaderTag: FC<CardHeaderTagType> = ({ type, className = '' }) => {
  const { text, color: backgroundColor } = CARD_HEADER_TAG[type];
  return (
    <span
      className={`inline-block text-[12px] text-white leading-[19px] rounded-[20px] px-[8px] ml-[4px] ${className}`}
      style={{ backgroundColor }}>
      {text}
    </span>
  );
};

export default CardHeaderTag;

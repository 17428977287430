/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React, { FC, useState } from 'react';
import { Button, Input, BaseModal, Textarea } from '@cokitchen/cokitchen-components';
import { ReactComponent as ReasonIcon } from '../../../assets/images/reason.svg';

import { TURN_OFF_REASON } from '../../../helpers/constants';
import { TimeSelectorType } from '../../time-selector/types';
import { ITurnOffBulkMeal } from '../types';
import moment from 'moment';
import { getOpeningTime } from '../../../helpers/functions';
import TimeSelectorInput from './timeSelector';

interface ReasonModalProps {
  show: boolean;
  orderCode?: string;
  loading: boolean;
  item: string;
  onClose: () => void;
  onConfirm: (arg0: ITurnOffBulkMeal) => void;
  brandName: string;
  workingHours: Array<{
    day: string;
    opening_time: string;
  }>;
}

interface ConfirmTurnoffFooterProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmTurnoffFooter: FC<ConfirmTurnoffFooterProps> = ({ onCancel, onConfirm, loading }) => (
  <div className="grid grid-cols-2 gap-x-4">
    <Button small color="primary" variant="outline" className="mx-auto" onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color="primary"
      variant="block"
      className="mx-auto"
      loading={loading}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const ReasonModal: FC<ReasonModalProps> = ({
  show,
  loading,
  item,
  orderCode = '',
  onClose,
  onConfirm,
  brandName,
  workingHours
}) => {
  const [value, setValue] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [startTime, setStartTime] = useState({} as TimeSelectorType);
  const [isChecked, setIsChecked] = useState(false);

  const sendValues = (): void => {
    const time = `${startTime.hours}:${startTime.minutes} ${startTime.amPm}`;
    const formattedTime = moment(time, 'hh:mm A').format('HH:mm:ss');
    let selectedReactivatedDate = '';

    if (date !== '') {
      const activationDate = moment(date).format();
      selectedReactivatedDate = activationDate.replace('00:00:00', formattedTime);
    }

    const deducedReactivatedDate = getOpeningTime(workingHours);
    const reactivation_time = isChecked ? deducedReactivatedDate : selectedReactivatedDate;
    onConfirm({
      val: {
        value,
        comment
      },
      reactivation_time
    });
  };

  return (
    <BaseModal
      isOpen={show}
      title={`Turn Off ${item}`}
      titleIcon={<ReasonIcon />}
      titleIconColor="danger"
      onRequestClose={onClose}
      footer={<ConfirmTurnoffFooter loading={loading} onCancel={onClose} onConfirm={sendValues} />}>
      <TimeSelectorInput
        date={date}
        setDate={setDate}
        item={item}
        startTime={startTime}
        setStartTime={setStartTime}
        brandName={brandName}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
      />
      <div className="mr-16">
        <p className="mt-4 text-[#595959] text-sm font-normal">
          Any reason why you’re making this category unavailable
        </p>
        <div
          id="reason-group "
          className=" grid gap-x-11 gap-y-3 grid-cols-2 grid-rows-2 my-4 mr-12 ">
          {TURN_OFF_REASON.map((reason, i) => (
            <Input
              key={i}
              value={value}
              label={reason}
              inputClass=""
              type="radio"
              onChange={() => setValue(reason)}
              name="turn-off-reasons"
            />
          ))}
        </div>
      </div>
      {value === 'Others' && (
        <div className="mt-2">
          <Textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Add a comment..."
            className="w-full"
          />
        </div>
      )}
    </BaseModal>
  );
};

export default ReasonModal;

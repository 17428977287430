import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import { FILTER_KEYS } from '../../helpers/constants';
import { getOrderLatestTime } from '../../helpers/functions';
import { OrderType } from '../../helpers/types';
import { FilterType } from '../../pages/live/filters';
import { getFilteredBrandOrders } from '../../pages/live/fns.service';

export const getFilterCount = ({
  filter,
  orders,
  pinnedOrders
}: {
  filter: FilterType;
  orders: OrderType[];
  pinnedOrders: OrderType[];
}) => {
  return orders.filter((order) => {
    if (filter.key === FILTER_KEYS.PINNED) {
      return pinnedOrders.some((p) => p.id === order.id);
    }
    return filter?.condition?.(order, filter.key);
  }).length;
};

export const filterAndSortOrders = (orders: OrderType[], searchValue: string) => {
  const search = searchValue?.toUpperCase();

  const matchesSearch = (order: OrderType) => {
    const { order_code, calculated_order } = order;
    const { user } = calculated_order ?? {};
    const fullName = `${user?.first_name ?? ''} ${user?.last_name ?? ''}`.toUpperCase();

    return (
      order_code?.toUpperCase().includes(search) ||
      fullName.includes(search) ||
      user?.phone_number?.toUpperCase().includes(search) ||
      user?.email?.toUpperCase().includes(search)
    );
  };

  const filteredOrders = orders.filter((order) => {
    return matchesSearch(order) && !order.calculated_order?.group_sub_order;
  });

  return filteredOrders.sort(
    (a, b) =>
      (getOrderLatestTime(b) as unknown as number) - (getOrderLatestTime(a) as unknown as number)
  );
};

export const getActiveFilters = (filters: FilterType[]) => {
  return filters.filter((filter) => filter.active);
};

export const isPinnedOrder = (order: OrderType, pinnedOrders: OrderType[]) => {
  return pinnedOrders.some((p) => p.id === order.id);
};

export const applyFilterCondition = (order: OrderType, filter: FilterType) => {
  return filter?.condition?.(order, filter.key);
};

export const filterOrdersByActiveFilters = (
  orders: OrderType[],
  activeFilters: FilterType[],
  pinnedOrders: OrderType[]
) => {
  if (activeFilters?.length < 1) return [...orders];

  return orders.filter((order) =>
    activeFilters.some((filter) => {
      if (filter.key === FILTER_KEYS.PINNED) {
        return isPinnedOrder(order, pinnedOrders);
      }
      return applyFilterCondition(order, filter);
    })
  );
};
export const sortOrdersByLatestTime = (orders: OrderType[]) => {
  return orders.sort((a, b) => Number(getOrderLatestTime(b)) - Number(getOrderLatestTime(a)));
};

export const filterByDeliveryType = (
  orders: OrderType[],
  deliveryType: string,
  getMealBrands: (orders: OrderType[]) => void
) => {
  if (deliveryType?.length > 0) {
    getMealBrands(orders);
  }
};

export const filterMealBrands = (orders: OrderType[], brands: string[], deliveryType: string) => {
  const filtered = getFilteredBrandOrders(orders, brands);
  return deliveryType?.length > 0
    ? filtered.filter((order) => order?.delivery === deliveryType)
    : filtered;
};

export const getMealBrands = (
  orders: OrderType[],
  brands: string[],
  deliveryType: string,
  role: ENUM_KITCHEN_ROLE
) => {
  let mealBrandOrders = [];
  if (role !== ENUM_KITCHEN_ROLE.KITCHEN_STAFF && role !== ENUM_KITCHEN_ROLE.KITCHEN_ADMIN) {
    const allBrands = orders.map((order) => ({
      ...order,
      orderKey: order.id
    }));
    mealBrandOrders =
      deliveryType?.length > 0
        ? allBrands.filter((order) => order?.delivery === deliveryType)
        : allBrands;
  }

  mealBrandOrders = filterMealBrands(orders, brands, deliveryType);
  return mealBrandOrders;
};

import React, { FC } from 'react';
import { mealTotal, numberWithCommas } from '../../helpers/functions';
import { OrderType } from '../../helpers/types';

const ReceiptOrderMeal: FC<{ order: OrderType }> = ({ order }) => {
  return (
    <div className="py-1 mb-3">
      {Boolean(order?.order_note) && (
        <div className="flex gap-4 mb-2">
          <h6 className="font-medium basis-[35px] max-w-[35px]">Note: </h6>
          <p>{order.order_note}</p>
        </div>
      )}
      <div className="flex gap-4">
        <h6 className="font-medium basis-[35px] max-w-[35px]">{order?.quantity + '×'}</h6>
        <div className="grow">
          <p className="mb-2">
            <strong>{order?.name}</strong>
          </p>
          {order?.addons?.map((addon, ind) => {
            return (
              <p className="text-black mt-1" key={`${ind}-addon`}>
                {addon?.name ?? addon?.meal_data?.name}&nbsp;
                <span>x{addon?.quantity}</span>
              </p>
            );
          })}
        </div>
        <p>{`₦${numberWithCommas(mealTotal(order))}.00`}</p>
      </div>
    </div>
  );
};

export default ReceiptOrderMeal;

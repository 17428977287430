import React from 'react';
import { useParams } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { observer } from 'mobx-react-lite';
import Logo from '../../assets/images/FoodCourt.png';
import { useHistory } from 'react-router';
import { ResetPassword as NewResetPassword } from '@cokitchen/cokitchen-auth';

const ResetPassword = observer(function ResetPassword({ alert }: { alert: any }) {
  const { token } = useParams();
  const history = useHistory();
  const login = () => {
    history.push('/login');
  };
  return (
    <div className="auth-login__main min-h-screen bg-white px-0  md:px-16 pt-10">
      <div className="flex justify-center">
        <div className="">
          <img src={Logo} className="login__logo" alt="Food court" />
        </div>
      </div>

      <main className="auth-login__card mt-[5rem]">
        <NewResetPassword token={token} onRouteToLogin={login} />
      </main>
    </div>
  );
});
export default withAlert()(ResetPassword);

import React from 'react';
import { ReactComponent as CancelIcon } from '../../assets/images/cancel-small.svg';

const Tooltip = ({ title, children, show }) => {
  return (
    <div className="">
      {show && (
        <div className="pr-[28px] pl-[16px]  py-[18px] pl-[14px] bg-white transition-all w-[291px] rounded-[4px] drop-shadow-lg ">
          <div className=" text-[12px] flex justify-between">
            <h6 className="pb-[4px]">{title}</h6>
            <CancelIcon />
          </div>
          <div className="text-[#595959]">{children}</div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;

import React, { FC, useEffect, useState } from 'react';
import './styles.scss';
import { BaseModal, Button, Input } from '@cokitchen/cokitchen-components';
import { ReactComponent as SurgeIcon } from '../../assets/images/manage-surge.svg';
import { SURGE_MINUTES } from '../../helpers/constants';
import useNetworkContext from '../../contexts/network.context';

interface SurgeModalProps {
  show: boolean;
  orderCode?: string;
  loading: boolean;
  value: number;
  onClose: () => void;
  onConfirm: (arg0: number) => void;
}

const SurgeModal: FC<SurgeModalProps> = ({
  show,
  loading,
  value,
  orderCode = '',
  onClose,
  onConfirm
}) => {
  const { disableNetworkActions } = useNetworkContext();
  const [title, setTitle] = useState('Manage General surge');
  const [activeSurge, setActiveSurge] = useState<number>(0);
  const [removeSurge, setRemoveSurge] = useState<boolean>(false);
  const [disableCheckbox, setDisableCheckbox] = useState<boolean>(false);

  useEffect(() => {
    if (orderCode !== '') setTitle(`Add Surge to #${orderCode.toUpperCase()}`);
    if (+value === 0) {
      setDisableCheckbox(true);
    } else {
      setDisableCheckbox(false);
      setActiveSurge(+value);
    }
  }, [show]);

  const emitValue = (): void => {
    const val = removeSurge ? 0 : activeSurge;
    onConfirm(val);
  };

  const selectMinute = (minute: number): void => {
    if (disableNetworkActions) return;

    setRemoveSurge(false);
    setActiveSurge(minute);
  };

  return (
    <BaseModal
      isOpen={show}
      title={title}
      description="Increase estimated time for orders in kitchen"
      titleIcon={<SurgeIcon />}
      titleIconColor="info"
      onRequestClose={onClose}
      footer={
        <Button
          color="primary"
          variant="block"
          className="mx-auto"
          loading={loading}
          onClick={emitValue}
          disabled={disableNetworkActions}>
          Confirm
        </Button>
      }>
      <div className="grid grid-cols-3 gap-8 max-w-[420px]">
        {SURGE_MINUTES.map((minute) => (
          <span
            key={minute}
            className={`surge-modal__button ${
              activeSurge === minute && !removeSurge ? 'is-active' : ''
            }`}
            role="button"
            onClick={() => selectMinute(minute)}>
            {minute === 0 ? 'None' : `+ ${minute} mins`}
          </span>
        ))}
      </div>
      <div className="my-3">
        <Input
          type="checkbox"
          label={`Remove${orderCode === '' ? ' general' : ''} surge`}
          onChange={() => setRemoveSurge(!removeSurge)}
          checked={removeSurge}
          disabled={disableCheckbox}
          inline
        />
      </div>
    </BaseModal>
  );
};

export default SurgeModal;

import { BaseModal } from '@cokitchen/cokitchen-components';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SplitScreenIcon } from '../../../assets/icons/split_screen.svg';
import dataService from '../../../services/data.service';
import { STORAGE_KEYS } from '../../../helpers/constants';
import PackagingScreenSearchFilter from '../../packaging-screen-search-filter';
import './styles.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import PackagingScreenCard from '../../packaging-screen-card';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export type PackagingScreenType = {
  id: string;
  name: string;
  cokitchen_id: string;
  active: boolean;
  active_order_count: number;
  created_at: string;
  updated_at: string;
};
const PackagingScreen = ({ isOpen, onClose }: Props) => {
  const [screens, setScreens] = useState<PackagingScreenType[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalScreens, setTotalScreens] = useState(0);
  const cokitchen_id = localStorage.getItem(STORAGE_KEYS.COKITCHEN);

  const [query, setQuery] = useState({ active: '', search: '', page: 1, size: 10 });

  const loadScreens = async () => {
    if (cokitchen_id === null) return;
    try {
      if (query.page === 1) setLoading(true);
      const params: Record<string, unknown> = {
        page: query.page,
        size: query.size,
        cokitchen_id
      };
      if (query.search !== '') params.search = query.search;
      if (query.active !== '') params.active = query.active;
      const res = await dataService.getAuthData('kitchen/packaging-screens', params);
      const results: PackagingScreenType[] = res.data.data.results;
      const total: number = res.data.data.total;
      if (query.page > 1) setScreens(screens.concat(results));
      else setScreens(results);
      setTotalScreens(total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadScreens();
  }, [query.page, query.active, query.search]);

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title="Packaging Screens"
      id="split_screen_modal"
      titleIcon={<SplitScreenIcon />}
      titleIconColor="success">
      <div className="w-full flex items-center justify-between mb-8">
        <PackagingScreenSearchFilter
          query={{
            search: query.search,
            filter: query.active,
            onSearchChange: (search: string) => setQuery((q) => ({ ...q, search, page: 1 })),
            onFilterChange: (active: string) => setQuery((q) => ({ ...q, active, page: 1 }))
          }}
        />
      </div>
      {loading ? (
        <div className="h-40 flex items-center justify-center">
          <p className="flex justify-center">Loading...</p>
        </div>
      ) : screens.length > 0 ? (
        <InfiniteScroll
          className="packaging__screen_modal__infinite__scroll"
          hasMore={totalScreens > screens?.length}
          dataLength={screens?.length}
          height={250}
          loader={<p className="text-center mt-4">Loading...</p>}
          next={() => setQuery((q) => ({ ...q, page: ++q.page }))}>
          <div className="grid gap-6 grid-cols-[repeat(auto-fill,minmax(224px,1fr))]">
            {screens.map((screen) => (
              <PackagingScreenCard key={screen.id} screen={screen} />
            ))}
          </div>
        </InfiniteScroll>
      ) : (
        <div className="h-40 flex items-center justify-center">
          <p className="flex justify-center">No screens found</p>
        </div>
      )}
    </BaseModal>
  );
};

export default PackagingScreen;

import React from 'react';
import { ReactComponent as DotIcon } from '../../assets/images/dot-icon.svg';

const ActiveTag = ({ active }: { active: boolean }) => {
  return (
    <div>
      <div
        className={`border w-fit min-h-fit ${
          active
            ? 'text-[#46AE31] bg-[#EAF8E7] border-[#A8E39C]'
            : 'text-[#858585] bg-[#F0F0F0] border-[#DEDEDE]'
        }  py-[2px] px-3 text-sm rounded-full  flex justify-center gap-2 items-center`}>
        <DotIcon color="currentColor" />
        <p className="text-sm font-medium">{active ? 'Active' : 'Deativated'}</p>
      </div>
    </div>
  );
};

export default ActiveTag;

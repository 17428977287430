import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { STORAGE_KEYS } from '../../helpers/constants';
import { FilterType } from '../../pages/live/filters';
import homeStore from '../../stores/homeStore';
import FilterList from '../filter-list';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

type Props = {
  children?: ReactNode;
  filters: FilterType[];
  onFilterClick: (value: FilterType) => void;
  searchValue: string;
};

type BrandNameType = { name: string; id: string };

const BreadCrumbs: FC<Props> = observer(({ filters, onFilterClick, searchValue }) => {
  const [activeBrands, setActiveBrands] = useState<BrandNameType[]>([]);
  const { activeHeaderBrands, headerBrands, updateActiveHeaderBrands } = homeStore;
  const [isAllBrands, setIsAllBrands] = useState(false);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);

  useEffect(() => {
    setIsAllBrands(activeHeaderBrands[0] === 'ALL');
    const names = activeHeaderBrands.reduce((acc: BrandNameType[], brandId) => {
      const brand = (headerBrands as BrandNameType[]).find(({ id }) => id === brandId);
      // active brands will contain all brand name except 'ALL'
      if (brand !== undefined) acc.push({ name: brand.name, id: brand.id });
      return acc;
    }, []);
    setActiveBrands(names);
  }, [activeHeaderBrands, headerBrands]);

  const removeBrand = (brandId: string) => {
    let filtered = activeBrands.filter(({ id }) => id !== brandId).map(({ id }) => id);
    if (filtered.length === 0) filtered = ['ALL'];
    updateActiveHeaderBrands(filtered);
  };

  return (
    <div className="my-3">
      {filters != null && filters.length > 0 && (
        <FilterList filters={filters} onFilterClick={onFilterClick} />
      )}
      <div className="pl-[20px] py-2 text-[16px] leading-[25px]">
        {searchValue !== '' && (
          <p className="text-gray-4 mb-3">
            Showing search results for
            <span className="text-white"> "{searchValue.toUpperCase()}"</span>
          </p>
        )}
        {(role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF || role === ENUM_KITCHEN_ROLE.KITCHEN_ADMIN) && (
          <div className="flex items-center">
            <span className="text-gray-4">Showing:</span>
            {isAllBrands && (
              <span className="cursor-pointer ml-[12px] rounded-[20px] text-base bg-white px-4 py-1">
                All brands
              </span>
            )}
            {activeBrands.map(({ name, id }) => (
              <div key={id}>
                <span
                  role="button"
                  onClick={() => removeBrand(id)}
                  className="cursor-pointer ml-[12px] rounded-[20px] text-base bg-white px-4 py-1"
                  key={id}>
                  {name}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

BreadCrumbs.displayName = 'BreadCrumbs';

export default BreadCrumbs;

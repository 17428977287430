import React, { FC, useEffect, useState } from 'react';
import './styles.scss';
import { BaseModal } from '@cokitchen/cokitchen-components';
import { ReactComponent as UserIcon } from '../../assets/images/user-icon.svg';
import { convertTo12hrs, numberWithCommas } from '../../helpers/functions';
import { OrderType, User } from '../../helpers/types';
import moment from 'moment';

interface UserInformationModalProps {
  show: boolean;
  onClose: () => void;
  order: OrderType;
  isPreOrderPage: boolean;
}

const UserInformationModal: FC<UserInformationModalProps> = ({ show, onClose, order, isPreOrderPage }) => {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (show) {
      setUser(order?.calculated_order?.user ?? order.user);
    }
  }, [show]);
   const formatTime = (time: string) => {
     const timeArray = time.split('-');
     const formattedTime = timeArray.map((item) => convertTo12hrs(item));
     return `${formattedTime[0]} - ${formattedTime[1]}`;
   };

  return (
    <BaseModal
      isOpen={show}
      title={`Order #${order.order_code?.toUpperCase()}`}
      description="User Information"
      titleIcon={<UserIcon />}
      titleIconColor="info"
      onRequestClose={onClose}>
      <div className="grid gap-4 md:grid-cols-2 md:grid-rows-2 ml-2">
        <div>
          <p className="text-[#595959]  mb-1 ">Username</p>
          <p className="text-lg">{user?.username}</p>
        </div>
        <div>
          <p className="text-[#595959] mb-1 ">Email</p>
          <p className=" text-lg">{user?.email}</p>
        </div>
        <div>
          <p className="text-[#595959]  mb-1 ">Order Amount</p>
          <p className="text-lg">₦{numberWithCommas(+order?.calculated_order?.total_amount)}.00</p>
        </div>
        <div>
          <p className="text-[#595959]  mb-1 ">Payment Mode</p>
          <p className="text-lg">
            {order.order_type?.name === 'CASH' ? 'TRANSFER' : order.order_type?.name}
          </p>
        </div>
        <div>
          <p className="text-[#595959] mb-1 ">Phone Number</p>
          <p className=" text-lg">{user?.phone_number}</p>
        </div>
        <div>
          <p className="text-[#595959]  mb-1 ">User since</p>
          <p className="text-lg">{new Date(user?.created_at ?? '').toDateString()}</p>
        </div>
        {order?.preorder ? (
          isPreOrderPage ? (
            <>
              <div>
                <p className="text-[#595959]  mb-1 ">Preorder delivery date</p>
                <p className="text-lg">
                  {new Date(order?.preorder_delivery_time ?? '').toDateString()}
                </p>
              </div>
              <div>
                <p className="text-[#595959]  mb-1 ">Preorder delivery time</p>
                <p className="text-lg">
                  {moment(order.preorder_delivery_time ?? '').format('hh:mm a')} -{' '}
                  {moment(order.preorder_delivery_time ?? '')
                    .add(1, 'hours')
                    .format('hh:mm a')}
                </p>
              </div>
            </>
          ) : (
            <>
              <div>
                <p className="text-[#595959]  mb-1 ">Preorder delivery date</p>
                <p className="text-lg">
                  {new Date(order?.preorder_delivery_date ?? null).toDateString()}
                </p>
              </div>
              <div>
                <p className="text-[#595959]  mb-1 ">Preorder delivery time</p>
                <p className="text-lg">{formatTime(order.preorder_delivery_time) ?? ''}</p>
              </div>
            </>
          )
        ) : null}
      </div>

      <div className="mt-[20px] ml-2">
        <p className="text-[#595959]  mb-1 ">Address</p>
        <p className="text-lg">{order?.calculated_order?.address_details?.address_line}</p>
      </div>
    </BaseModal>
  );
};

export default UserInformationModal;

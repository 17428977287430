import React from 'react';
import './styles.scss';
import { ReactComponent as ClockIcon } from '../../assets/images/clock-icon.svg';
import { ReactComponent as EmptyClockIcon } from '../../assets/images/empty-clock.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_STATUS } from '../../helpers/enums';
import Timer from '../timer';
import { ReactComponent as RiderIcon } from '../../assets/icons/rider-alt-icon.svg';
import { getTextClass } from '../card-button/functions';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

const BoxNumberSection = ({
  status,
  order,
  calculatedOrder,
  time,
  riderAssigned,
  setViewDetails,
  viewDetails,
  reason
}) => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);

  return (
    <div
      className={`${viewDetails ? 'h-[394px]' : 'h-[210px]'} mb-[13px] text-white overflow-scroll`}>
      <div>
        <hr className="opacity-50 text-white" />
        <p className="text-[32px] font-semibold text-white">{order.box_number}</p>
        <p className="flex gap-2 my-3 text-white">
          <RiderIcon />
          <span className="font-medium">
            {order?.rider?.first_name
              ? `${order?.rider?.first_name} ${order?.rider?.last_name}`
              : 'Rider not assigned'}
          </span>
        </p>
        <hr className="mb-4 opacity-40" />
      </div>
      {ENUM_KITCHEN_ROLE.FRONT_DESK && (
        <div className="transaprent w-full">
          <div className="flex flex-col gap-2 relative">
            {[
              ENUM_KITCHEN_ROLE.FRONT_DESK,
              ENUM_KITCHEN_ROLE.SUPER_ADMIN,
              ENUM_KITCHEN_ROLE.KITCHEN_ADMIN
            ].includes(role) &&
              !order?.calculated_order?.pickup && (
                <div className="flex justify-start gap-3 items-center ">
                  <LocationIcon />
                  <p className="font-medium ">
                    {order?.calculated_order?.address_details?.address_line}
                  </p>
                </div>
              )}
            {status !== ENUM_STATUS.REJECTED ? (
              <div className="flex justify-start gap-3 items-center">
                <EmptyClockIcon />
                <p className="text-[12px] opacity-70">
                  {status === ENUM_STATUS.COMPLETED ? 'Completed: ' : 'Time since order: '}
                  {time && (
                    <Timer time={time} format={status === ENUM_STATUS.COMPLETED ? 'LLLL' : ''} />
                  )}
                </p>
              </div>
            ) : null}

            <div className="flex flex-col gap-3 relative">
              {order?.kitchen_verified_time && (
                <>
                  <div className="flex justify-start gap-3 items-center">
                    <ClockIcon />
                    <p className="text-[12px] opacity-70">
                      Received by kitchen:&nbsp;
                      {order?.kitchen_verified_time && <Timer time={order.kitchen_verified_time} />}
                    </p>
                  </div>
                  <div className="absolute  left-[8px] h-[24px] w-[1px] bg-white opacity-[0.12] bottom-[8px]"></div>
                </>
              )}
              {order?.kitchen_completed_time ? (
                <div className="flex justify-start gap-3 items-center">
                  <ClockIcon />
                  <p className="text-[12px] opacity-70">
                    Received by frontdesk:&nbsp;
                    <Timer time={order.kitchen_completed_time} />
                  </p>
                </div>
              ) : null}
            </div>

            {status === ENUM_STATUS.REJECTED ? (
              <div className=" flex flex-col gap-3">
                <p className="text-[12px] opacity-70 mb-1 ">
                  REJECTED : {time && <Timer time={time} format="LLLL" />}
                </p>
                <p className="text-[12px] opacity-70 mb-0 bold mr-auto">Reason: {reason}</p>
              </div>
            ) : null}
          </div>

          <div className=" flex flex-col gap-3 ">
            <div className="flex flex-col gap-3">
              {riderAssigned === 'no' && !order?.calculated_order?.pickup ? (
                <p className="text-[12px] opacity-70 mb-1"> RIDER NOT YET ASSIGNED</p>
              ) : null}
              {order?.order_details?.rider_note &&
                (order?.calculated_order?.pickup ? (
                  <p className="">
                    Pickup Note:
                    <span> {order?.order_details?.rider_note}</span>
                  </p>
                ) : (
                  <p className="">
                    Rider Note:
                    <span> {order?.order_details?.rider_note}</span>
                  </p>
                ))}
            </div>
          </div>
        </div>
      )}
      {viewDetails && (
        <button
          className="absolute bg-white bottom-8 right-0 left-0 rounded-full font-semibold  px-[29.5px] mx-28 py-[10px]"
          onClick={() => setViewDetails(false)}>
          <p className={`${getTextClass({ status })} normal-case `}>View Order details</p>
        </button>
      )}
    </div>
  );
};

export default BoxNumberSection;

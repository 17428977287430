import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_ITEM_TYPES } from '../../helpers/enums';
import { OrderType } from '../../helpers/types';
import { OrderService } from '../../services/order.service';

const cokitchenId = localStorage.getItem(STORAGE_KEYS.COKITCHEN);

export const apiLiveMapping: Partial<Record<ENUM_KITCHEN_ROLE, () => Promise<any>>> = {
  KITCHEN_STAFF: OrderService.getKitchenOrders,
  FRONT_DESK: OrderService.getAdminLiveOrders,
  PACKAGING: OrderService.getCurrentPackingOrders,
  SHOP_STAFF: OrderService.getShopLiveOrders,
  KITCHEN_ADMIN: OrderService.getAdminLiveOrders,
  SUPER_ADMIN: OrderService.getAdminLiveOrders
};

/* eslint-disable */
export const checkOrderValidityForRole = (
  order: OrderType,
  cokitchenId: string,
  test: boolean,
  screen_id: string | undefined = undefined,
  packaging_screen_id: string | undefined = undefined
): boolean | undefined => {
  if (!order) return false;
  if (
    (test && !order.order_code.startsWith('test')) ||
    (!test && order.order_code.startsWith('test'))
  )
    return false;
  if (order.cancelled || order.completed_time) return false;
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
  const conditionMap: Partial<Record<ENUM_KITCHEN_ROLE, (order: OrderType) => boolean>> = {
    KITCHEN_STAFF: getKitchenCondition,
    SHOP_STAFF: getShopCondition,
    FRONT_DESK: getFrontDeskCondition,
    PACKAGING: getPackagingCondition,
    SUPER_ADMIN: getAdminCondition,
    KITCHEN_ADMIN: getAdminCondition
  };
  const fn = conditionMap[role];
  if (fn) {
    return (
      fn(order) &&
      (!screen_id || order.kitchen_split_screen_ids.includes(screen_id)) &&
      (!cokitchenId || order?.calculated_order?.cokitchen_id === cokitchenId) &&
      (!packaging_screen_id || order?.packaging_split_screen_id === packaging_screen_id)
    );
  }
  return false;
};

// kitchen live order
const getKitchenCondition = (order: OrderType): boolean => {
  const { paid, kitchen_prepared, kitchen_dispatched, calculated_order } = order;
  const isKitchenItem = calculated_order?.item_type !== 'SHOP';
  return (
    paid &&
    isKitchenItem &&
    !kitchen_prepared &&
    !kitchen_dispatched &&
    order?.calculated_order?.cokitchen?.id === cokitchenId
  );
};

// Shop orders
const getShopCondition = (order: OrderType): boolean => {
  const { paid, shop_prepared, kitchen_dispatched, calculated_order } = order;
  const isShopItem = calculated_order?.item_type !== 'FOOD';
  return (
    isShopItem &&
    paid &&
    !shop_prepared &&
    !kitchen_dispatched &&
    order?.calculated_order?.cokitchen?.id === cokitchenId
  );
};

const getConditionForAllOrderTypes = (order: OrderType): boolean => {
  const shop_condition =
    order.calculated_order.item_type == ENUM_ITEM_TYPES.FOOD && !order.kitchen_prepared;
  const kitchen_condtion =
    order.calculated_order.item_type == ENUM_ITEM_TYPES.SHOP && !order.shop_prepared;
  const all_condition =
    order.calculated_order.item_type == ENUM_ITEM_TYPES.ALL &&
    !(order.shop_prepared && order.kitchen_prepared);
  return (
    order.paid &&
    (shop_condition || kitchen_condtion || all_condition) &&
    !order.kitchen_dispatched &&
    order?.calculated_order?.cokitchen?.id === cokitchenId
  );
};
// front desk contains both paid and unpaid
const getFrontDeskCondition = (order: OrderType): boolean => {
  return getConditionForAllOrderTypes(order);
};

const getPackagingCondition = (order: OrderType): boolean => {
  return getKitchenCondition(order);
};

const getAdminCondition = (order: OrderType): boolean => {
  return getConditionForAllOrderTypes(order);
};
/* eslint-enable */

import React, { FC, Fragment } from 'react';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_ITEM_TYPES } from '../../helpers/enums';
import { OrderType } from '../../helpers/types';
import CardHeaderTag from './cardHeaderTag';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

const FrontDeskTags = ({ order }: { order: OrderType }) => {
  const type = order?.calculated_order?.item_type;
  return (
    <Fragment>
      {type === 'FOOD' && <CardHeaderTag type="food" />}
      {type === 'SHOP' && <CardHeaderTag type="shop" />}
      {type === 'ALL' && (
        <Fragment>
          <CardHeaderTag type="food" />
          <CardHeaderTag type="shop" />
        </Fragment>
      )}
    </Fragment>
  );
};

const OtherRoleTags = ({ order }: { order: OrderType }) => {
  const isAll = order?.calculated_order?.item_type === ENUM_ITEM_TYPES.ALL;
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
  const shopType = order?.kitchen_prepared ? 'foodReady' : 'food';
  const kitchenType = order?.shop_prepared ? 'shopReady' : 'shop';

  return isAll ? (
    <Fragment>
      {role === ENUM_KITCHEN_ROLE.SHOP_STAFF && <CardHeaderTag type={shopType} />}
      {role === ENUM_KITCHEN_ROLE.KITCHEN_STAFF && <CardHeaderTag type={kitchenType} />}
      {role === ENUM_KITCHEN_ROLE.PACKAGING && <CardHeaderTag type={kitchenType} />}
      {(role as string).includes('ADMIN') && (
        <Fragment>
          <CardHeaderTag type="food" />
          <CardHeaderTag type="shop" />
        </Fragment>
      )}
    </Fragment>
  ) : (
    <span />
  );
};

const ItemTypeTags: FC<{ order: OrderType }> = ({ order }) => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE) as ENUM_KITCHEN_ROLE;
  return role === ENUM_KITCHEN_ROLE.FRONT_DESK ? (
    <FrontDeskTags order={order} />
  ) : (
    <OtherRoleTags order={order} />
  );
};

export default ItemTypeTags;

import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { withAlert } from 'react-alert';
import homeStore from '../../stores/homeStore';
import InventoryItem from '../inventory-item';
import { IInventoryItem } from '../inventory-item/types';
import styles from './styles.module.css';
import { IMealItemCategory, ITurnOffBulkMeal } from './types';
import ReasonModal from './modals/turnOffReasonModal';
import { Input } from '@cokitchen/cokitchen-components';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import useNetworkContext from '../../contexts/network.context';

const attachKeyToMeal = (meal: IInventoryItem) => {
  meal.key = meal.id;
  return meal;
};

const attachKeyToMeals = (meals: IInventoryItem[]) => meals.map((meal) => attachKeyToMeal(meal));

type Props = {
  category: IMealItemCategory;
  handleTurnOffAll: (category: IMealItemCategory) => Promise<any>;
  handleMealUpdate: (meal: IInventoryItem) => void;
  isAddon?: boolean;
  onReloadAddon?: () => void;
  alert: any;
  brandName: string;
  workingHours: Array<{
    day: string;
    opening_time: string;
  }>;
};

const InventoryCategory: FC<Props> = ({
  category,
  handleMealUpdate,
  alert,
  onReloadAddon,
  isAddon = false,
  brandName,
  workingHours
}) => {
  const { turnOffAllItems, updateMeal } = homeStore;
  const { disableNetworkActions } = useNetworkContext();
  const [unavailableMealsCount, setUnavailableMealsCount] = useState(0);
  const [showReason, setShowReason] = useState(false);
  const [allMealItems, setAllMealItems] = useState<IInventoryItem[]>([]);
  const [mealItems, setMealItems] = useState<IInventoryItem[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setAllMealItems(attachKeyToMeals(category.meals));
  }, [category]);

  useEffect(() => {
    setUnavailableMealsCount(allMealItems.filter((item) => !item.active).length);
    filterMealItems();
  }, [allMealItems]);

  useEffect(() => {
    filterMealItems();
  }, [search]);

  const turnOffAllMealItems = async ({ val, reactivation_time }: ITurnOffBulkMeal) => {
    if (isAddon) {
      updateAllAddonItems({ val, reactivation_time });
      return;
    }
    setSubmitting(true);
    const comments = val.value === 'Others' ? val.comment : val.value;
    const data = {
      meal_category_id: category.id,
      active: false,
      comment: comments,
      reactivation_time
    };
    await turnOffAllItems(true, data);

    setShowReason(false);
    setSubmitting(false);
  };

  const updateAllAddonItems = async ({ val, reactivation_time }: ITurnOffBulkMeal) => {
    setSubmitting(true);
    const meals = category.meals.map(({ amount, id: meal_id, available_no }) => ({
      amount,
      meal_id,
      available_no,
      active: false,
      reactivation_time
    }));
    await updateMeal(alert, { meals });

    setShowReason(false);
    setSubmitting(false);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setSearch(value.toUpperCase());
    filterMealItems();
  };

  const filterMealItems = () => {
    const filtered = allMealItems?.filter(({ name }) => name?.toUpperCase().includes(search));
    setMealItems(attachKeyToMeals(filtered));
  };

  const handleSearchBlur = () => {
    if (search === '') setShowSearch(false);
  };
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div className={styles.mainTitle}>
          <div>{category.name}</div>
          <div>{category.meals.length} total items</div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.stickyTop}>
          <div>
            <div className={styles.title}>{category.name}</div>
            <div className={styles.detail}>
              {unavailableMealsCount} of {category.meals.length} unavailable
            </div>
          </div>
          <div className={styles.actions}>
            <span className="mr-4" role="button" onClick={() => setShowSearch(!showSearch)}>
              <SearchIcon />
            </span>

            <button
              disabled={disableNetworkActions}
              className={styles.roundedButton}
              onClick={() => setShowReason(true)}>
              Sold out all
            </button>
          </div>
        </div>
        {showSearch && (
          <div>
            <Input
              autoFocus
              value={search}
              placeholder="Search for meal items"
              type="search"
              small
              onChange={handleSearch}
              onBlur={handleSearchBlur}
            />
          </div>
        )}
        <div>
          {mealItems.map((mealItem) => (
            <InventoryItem
              key={mealItem.key}
              meal={mealItem}
              handleMealUpdate={handleMealUpdate}
              brandName={brandName}
              workingHours={workingHours}
            />
          ))}
          {mealItems.length === 0 && search !== '' && (
            <p className="mt-[50px] text-center text-xl  text-gray-5">
              <span>
                <SearchIcon className="mx-auto w-[30px] h-[30px]" />
              </span>
              There are no meal items for <span className="text-gray-6">"{search}"</span>. You may
              want to check your spelling
            </p>
          )}
        </div>
      </div>
      <ReasonModal
        show={showReason}
        loading={submitting}
        item={category.name}
        onClose={() => setShowReason(false)}
        onConfirm={turnOffAllMealItems}
        brandName={brandName}
        workingHours={workingHours}
      />
    </div>
  );
};

export default withAlert()(InventoryCategory);

import React, { FC, memo, useEffect, useState } from 'react';
import moment from 'moment';

type TimerProps = {
  time: number;
  format?: string;
};

const MINUTE_MS = 60000;

const Timer: FC<TimerProps> = ({ time, format = '' }) => {
  const [formattedTime, setFormattedTime] = useState('');
  useEffect(() => {
    setFormattedTime(format !== '' ? moment(time).format(format) : moment(time).fromNow());
    const interval = setInterval(() => {
      const momentTime = format !== '' ? moment(time).format(format) : moment(time).fromNow();
      setFormattedTime(momentTime);
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  return <span>{formattedTime}</span>;
};

export default memo(Timer);

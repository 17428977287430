/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { AmPmType } from '../../helpers/types';
import { TimeSelectorType } from './types';

type Props = {
  value: TimeSelectorType;
  // Leaving it as this until we figure out what format to use
  onChange: (value: TimeSelectorType) => void;
};

const formatWithZero = (number: number) => {
  return number < 10 ? `0${number}` : `${number}`;
};

const TimeSelector: FC<Props> = ({ value, onChange }) => {
  const [hourFocus, setHourFocus] = useState(false);
  const [minuteFocus, setMinuteFocus] = useState(false);
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [amPm, setAmPm] = useState<AmPmType>('AM');

  useEffect(() => {
    const { hours, minutes = '00', amPm, totalHours = '00' } = value;
    if (hours && amPm) {
      setHours(hours);
      setAmPm(amPm);
    } else {
      const isAmOrPm = +totalHours < 12 ? 'AM' : 'PM';
      const newHours =
        +totalHours === 0 || +totalHours === 12
          ? '12'
          : +totalHours < 12
          ? totalHours
          : `${+totalHours - 12}`;
      setHours(newHours);
      setAmPm(isAmOrPm);
    }
    setMinutes(minutes);
  }, []);

  useEffect(() => {
    let totalHours = hours;
    if (amPm === 'PM') {
      totalHours = `${+hours + 12}`;
    }
    const timeWithColon = `${totalHours}:${minutes}`;
    onChange({ hours, minutes, amPm, totalHours, timeWithColon });
  }, [hours, minutes, amPm]);

  const handleHourChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let newVal = +value;
    if (isNaN(+value) || +value > 12) newVal = 12;
    setHours(formatWithZero(newVal));
  };

  const handleMinuteChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let newVal = +value;
    if (isNaN(+value) || +value > 59) newVal = 59;
    setMinutes(formatWithZero(newVal));
  };
  return (
    <div className="flex">
      <input
        type="number"
        value={hours}
        className={`text-sm w-[30px] text-center py-[2px] border rounded-sm focus:outline-none ${
          hourFocus ? 'bg-transparent border-red-5' : 'bg-[#F5F5F5] border-[#f5f5f5]'
        }`}
        onFocus={() => setHourFocus(true)}
        onBlur={() => setHourFocus(false)}
        onChange={handleHourChange}
      />
      <span className="px-2">:</span>
      <input
        type="number"
        value={minutes}
        className={`text-sm w-[30px] text-center py-[2px] border rounded-sm focus:outline-none ${
          minuteFocus ? 'bg-transparent border-red-5' : 'bg-[#F5F5F5] border-[#f5f5f5]'
        }`}
        onFocus={() => setMinuteFocus(true)}
        onBlur={() => setMinuteFocus(false)}
        onChange={handleMinuteChange}
      />
      <div className="flex rounded-sm overflow-hidden ml-2">
        {(['AM', 'PM'] as AmPmType[]).map((option) => (
          <span
            key={option}
            role="button"
            onClick={() => setAmPm(option)}
            className={`text-xs px-[6.5px] leading-[25px] transition ${
              amPm === option ? 'bg-red-5 text-white' : 'bg-gray-1/50'
            }`}>
            {option}
          </span>
        ))}
      </div>
    </div>
  );
};

export default TimeSelector;

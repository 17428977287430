import React, { FC } from 'react';
import { Input } from '@cokitchen/cokitchen-components';
import { STORAGE_KEYS } from '../../helpers/constants';
import { ENUM_STATUS } from '../../helpers/enums';
import { ENUM_KITCHEN_ROLE } from '@cokitchen/cokitchen-auth';

type CutleryType = {
  onChange: () => void;
  status: ENUM_STATUS;
  isChecked: boolean;
  hasCutlery: boolean;
};

const Cutlery: FC<CutleryType> = ({ onChange, hasCutlery, isChecked, status }) => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const showCheckbox =
    (role === ENUM_KITCHEN_ROLE.PACKAGING && status === ENUM_STATUS.PACKAGING) ||
    (role === ENUM_KITCHEN_ROLE.SHOP_STAFF && status === ENUM_STATUS.STARTED);
  return (
    <div
      className={`flex justify-start items-center ${
        status === ENUM_STATUS.PACKAGING ? 'cursor-pointer' : ''
      }`}
      onClick={onChange}>
      <p className="text-[#595959] py-1 mb-0 bg-[#F5F5F5] px-3 rounded-full">
        Cutlery:
        <span className={`ml-[3px] mr-0 ${hasCutlery ? 'text-[#016FB1]' : 'text-red-4'}`}>
          {hasCutlery ? 'Yes' : 'No'}
        </span>
      </p>
      {showCheckbox && hasCutlery && (
        <span className="ml-3">
          <Input type="checkbox" checkColor="green" checked={isChecked} onChange={onChange} />
        </span>
      )}
    </div>
  );
};

export default Cutlery;

import React, { FC } from 'react';
import { Input } from '@cokitchen/cokitchen-components';

type FinishOrderType = {
  isFinished: boolean;
  onFinish: () => void;
};

const FinishOrder: FC<FinishOrderType> = ({ onFinish, isFinished }) => {
  return (
    <div className="py-1 flex mb-4 justify-start items-center pointer" onClick={onFinish}>
      <p className="bold mr-2 mb-0">Finish Order</p>
      <span className="ml-3">
        <Input type="checkbox" checked={isFinished} onChange={onFinish} />
      </span>
    </div>
  );
};

export default FinishOrder;
